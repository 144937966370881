import { Button, Card, Select, Table } from '@equitymultiple/react-eui';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { toast } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
  namedOperations,
  PaymentTransaction,
  TransactionEventTypeCode,
  useCancelTransactionsMutation,
  useEditTransactionMutation,
  useGetPaymentTransactionsQuery,
  useRunTransactionsMutation
} from '../../../__generated__';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import formatCurrency from '../../../utils/formatCurrency';
import * as styles from './RunTransactions.module.scss';
import TransactionsTable from './TransactionsTable';

type Date = null | string;

interface StatusCount {
  all: number;
  cancelled: number;
  draft: number;
  failed: number;
  pending: number;
  posted: number;
}

const getStatusCount = (transactions: PaymentTransaction[]) => {
  const statusCount = {
    all: 0,
    cancelled: 0,
    draft: 0,
    failed: 0,
    pending: 0,
    posted: 0
  };

  transactions.forEach(transaction => {
    const date = transaction.effectiveDate;
    const status = transaction.status;

    if (status && date) {
      statusCount[status] += 1;
      statusCount.all += 1;
    }
  });

  return statusCount;
};

const getStatusOptions = (statusCount: StatusCount) => {
  return [
    {
      label: `All (${statusCount?.all})`,
      value: 'all'
    },
    {
      label: `Draft (${statusCount?.draft})`,
      value: 'draft'
    },
    {
      label: `Pending (${statusCount?.pending})`,
      value: 'pending'
    },
    {
      label: `Posted (${statusCount?.posted})`,
      value: 'posted'
    },
    {
      label: `Failed (${statusCount?.failed})`,
      value: 'failed'
    },
    {
      label: `Cancelled (${statusCount?.cancelled})`,
      value: 'cancelled'
    }
  ];
};

const filterTransactions = (
  transactions: PaymentTransaction[],
  status: string,
  dirtyTransaction: PaymentTransaction
) => {
  const filteredTransactions = transactions.filter(
    transaction => status === 'all' || transaction.status === status
  );

  if (dirtyTransaction) {
    return filteredTransactions.map(transaction => {
      return transaction.id === dirtyTransaction.id
        ? dirtyTransaction
        : transaction;
    });
  }

  return filteredTransactions;
};

const getDraftTransactionStats = (transactions: PaymentTransaction[]) => {
  const draftTransactions = transactions?.filter(
    transaction =>
      transaction?.status === 'draft' &&
      transaction?.eventType !== TransactionEventTypeCode.Contribution
  );

  const draftTransactionsAmount = formatCurrency(
    (draftTransactions &&
      draftTransactions.reduce((currentAmount, transaction) => {
        return (currentAmount +
          (transaction as { amount: number }).amount) as number;
      }, 0)) ||
      0
  );

  return (
    <div className="margin30">
      <strong>
        Number of draft transactions (excluding Contributions and Fees):{' '}
      </strong>
      {draftTransactions ? (
        draftTransactions.length
      ) : (
        <Skeleton className={styles.skeleton} />
      )}
      {transactions && <br />}
      <strong>
        Value of draft transactions (excluding Contributions and Fees):{' '}
      </strong>
      {draftTransactions ? (
        draftTransactionsAmount
      ) : (
        <Skeleton className={styles.skeleton} />
      )}
    </div>
  );
};

const getTransactionEditModalContent = (
  transaction: PaymentTransaction,
  dirtyTransaction: PaymentTransaction
) => {
  const { amount, effectiveDate, id, paymentMethod, user } = transaction;
  const {
    amount: newAmount,
    effectiveDate: newEffectiveDate,
    paymentMethod: newPaymentMethod
  } = dirtyTransaction;

  const columnHeaders = ['Field', 'From', 'To'];
  const rows = [];

  if (effectiveDate !== newEffectiveDate) {
    rows.push({
      cells: [
        'Effective Date',
        moment(new Date(effectiveDate)).format('MM/DD/YYYY'),
        moment(new Date(newEffectiveDate)).format('MM/DD/YYYY')
      ]
    });
  }

  if (paymentMethod !== newPaymentMethod) {
    rows.push({
      cells: [
        'Payment Method',
        paymentMethod.toUpperCase(),
        newPaymentMethod.toUpperCase()
      ]
    });
  }

  if (amount !== newAmount) {
    rows.push({
      cells: [
        'Amount',
        formatCurrency(amount as number),
        formatCurrency(newAmount as number)
      ]
    });
  }

  return (
    <>
      <p>
        <strong>User: </strong>
        {`${user.firstName} ${user.lastName}`}
        <br />
        <strong>ID: </strong>
        {id}
      </p>
      <Table allowSorting={false} columnHeaders={columnHeaders} rows={rows} />
    </>
  );
};

const getEffectiveDateOptions = (dates: string[]) => {
  return dates.map(effectiveDate => ({
    label: moment.utc(new Date(effectiveDate)).format('M/D/YYYY'),
    value: effectiveDate
  }));
};

const runAllMessages = {
  error: 'An error occurred while running transactions',
  loading: 'Running transactions',
  success: 'Transactions enqueued and will run in few seconds'
};

const cancelAllMessages = {
  error: 'An error occurred while cancelling pending transactions',
  loading: 'Cancelling pending transactions',
  success: 'Transactions enqueued and will cancel in few seconds'
};

const editTransactionMessages = {
  error: 'An error occurred while updating the transaction',
  loading: 'Updating transaction',
  success: 'Transaction updated'
};

const transactionEditFields = [
  'amount',
  'id',
  'effectiveDate',
  'investmentId',
  'paymentMethod'
];

const defaultStatus = 'all';
const defaultDate = '';

const RunTransactions: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const statusParam = searchParams.get('status') ?? defaultStatus;
  const dateParam = searchParams.get('effectiveDate') ?? defaultDate;
  const [status, setStatus] = useState(statusParam);
  const [effectiveDate, setEffectiveDate] = useState<Date>(dateParam);
  const [showRunAllModal, setShowRunAllModal] = useState(false);
  const [showCancelAllModal, setShowCancelAllModal] = useState(false);
  const [statusCount, setStatusCount] = useState<StatusCount>();
  const [dirtyTransaction, setDirtyTransaction] =
    useState<PaymentTransaction>(null);
  const [showEditTransactionModal, setShowEditTransactionModal] =
    useState(false);
  const [editTransaction, editTransactionState] = useEditTransactionMutation();

  const urlParams = useParams();
  const navigate = useNavigate();

  const offeringId = urlParams.offeringId as string;
  const { data, error, loading } = useGetPaymentTransactionsQuery({
    onCompleted: res => {
      const count = getStatusCount(
        res.paymentTransactions.transactions as PaymentTransaction[]
      );
      setStatusCount(count);
    },
    variables: {
      effectiveDate: effectiveDate || null,
      offeringId: offeringId
    }
  });

  const transactions = data?.paymentTransactions?.transactions;
  const hasTransactions = transactions && transactions.length > 0;
  const effectiveDates = data?.paymentTransactions?.effectiveDates;
  const selectedEffectiveDate =
    effectiveDate || (effectiveDates && effectiveDates[0]);

  const [runTransactions, runTransactionsState] = useRunTransactionsMutation({
    // Transactions are ran in the background and we can't immediately update the cache
    // This applies to cancel as well
    refetchQueries: [namedOperations.Query.getPaymentTransactions],
    variables: {
      effectiveDate: selectedEffectiveDate,
      offeringId: offeringId
    }
  });

  const [cancelTransactions, cancelTransactionsState] =
    useCancelTransactionsMutation({
      refetchQueries: [namedOperations.Query.getPaymentTransactions],
      variables: {
        effectiveDate: selectedEffectiveDate,
        offeringId: offeringId
      }
    });

  const hasError = error || data?.paymentTransactions.error;

  useEffect(() => {
    if (hasError) {
      toast.error('An error occurred while loading transactions');
      navigate('/payments');
    }
  }, [hasError, navigate]);

  const handleCancelAll = () => {
    callMutationWithToastMessages(cancelTransactions, cancelAllMessages).then(
      () => {
        setShowCancelAllModal(false);
      }
    );
  };

  const handleRunAll = () => {
    callMutationWithToastMessages(runTransactions, runAllMessages).then(() => {
      setShowRunAllModal(false);
    });
  };

  const filteredTransactions =
    !loading && transactions
      ? filterTransactions(
          transactions as PaymentTransaction[],
          status,
          dirtyTransaction
        )
      : [];

  const nonContributions = transactions?.filter(
    transaction =>
      transaction?.eventType !== TransactionEventTypeCode.Contribution
  );

  const hasDraftTransactions = nonContributions?.some(
    transaction => transaction?.status === 'draft'
  );

  const hasPendingTransactions = nonContributions?.some(
    transaction => transaction?.status === 'pending'
  );

  const hasEditableTransactions = transactions?.some(
    transaction => transaction?.status === 'draft'
  );

  const totalPendingTransactions = statusCount?.pending;

  const showActions = hasDraftTransactions || hasPendingTransactions;

  const draftTransactionStats = getDraftTransactionStats(
    transactions as PaymentTransaction[]
  );

  const offeringTitle = data?.offering?.offering?.title;

  const getCurrentTransaction = (id: string) =>
    transactions.find(transaction => transaction.id === id);

  const transactionIsDirty = (transaction: PaymentTransaction) => {
    const currentTransaction = getCurrentTransaction(transaction.id);
    return (
      currentTransaction &&
      transactionEditFields.some(
        field => transaction[field] !== currentTransaction[field]
      )
    );
  };

  const handleTransactionEdit = (transaction: PaymentTransaction) => {
    if (
      dirtyTransaction === null ||
      (dirtyTransaction.id === transaction.id &&
        transactionIsDirty(transaction))
    ) {
      setDirtyTransaction(transaction);
    } else {
      setDirtyTransaction(null);
    }
  };

  const handleResetTransactionEdits = () => setDirtyTransaction(null);

  const handleSaveTransactionEdits = () => {
    const values = { ...dirtyTransaction };
    Object.keys(values)
      .filter(key => !transactionEditFields.includes(key))
      .map(key => delete values[key]);

    const mutationOptions = {
      refetchQueries: [namedOperations.Query.getPaymentTransactions],
      variables: {
        transaction: values
      }
    };

    callMutationWithToastMessages(
      editTransaction,
      editTransactionMessages,
      mutationOptions
    ).then(() => {
      setShowEditTransactionModal(false);
      setDirtyTransaction(null);
    });
  };

  const handleSetStatus = (newStatus: string) => {
    if (newStatus === defaultStatus) {
      searchParams.delete('status');
    } else {
      searchParams.set('status', newStatus);
    }

    setStatus(newStatus);
    setSearchParams(searchParams);
  };

  const handleSetEffectiveDate = (newDate: string) => {
    if (newDate === effectiveDates[0]) {
      searchParams.delete('effectiveDate');
    } else {
      searchParams.set('effectiveDate', newDate);
    }

    setEffectiveDate(newDate);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (statusParam !== status) {
      setStatus(statusParam);
    }
  }, [statusParam, status]);

  useEffect(() => {
    if (effectiveDates) {
      const newDate = dateParam === defaultDate ? effectiveDates[0] : dateParam;
      if (effectiveDate !== dateParam && newDate !== effectiveDate) {
        setEffectiveDate(newDate);
      }
    }
  }, [dateParam, effectiveDate, effectiveDates]);

  return (
    <>
      {showRunAllModal && (
        <ConfirmationModal
          buttonText="Yes, run all"
          content={draftTransactionStats}
          handleCloseModal={() => setShowRunAllModal(false)}
          loading={runTransactionsState.loading}
          onSubmit={handleRunAll}
          title="Are you sure you want to run all transactions?"
        />
      )}
      {showCancelAllModal && (
        <ConfirmationModal
          buttonText="Yes, cancel all"
          content={
            <p>
              {totalPendingTransactions as number} transaction
              {totalPendingTransactions > 1 ? 's' : ''} will be cancelled
            </p>
          }
          handleCloseModal={() => setShowCancelAllModal(false)}
          loading={cancelTransactionsState.loading}
          onSubmit={handleCancelAll}
          title="Are you sure you want to cancel all pending transactions?"
        />
      )}
      {showEditTransactionModal && (
        <ConfirmationModal
          buttonText="Yes, save changes"
          content={getTransactionEditModalContent(
            getCurrentTransaction(dirtyTransaction.id),
            dirtyTransaction
          )}
          handleCloseModal={() => setShowEditTransactionModal(false)}
          loading={editTransactionState.loading}
          onSubmit={handleSaveTransactionEdits}
          title="Are you sure you want to update this transaction?"
        />
      )}
      <h2>
        {loading ? 'Run Transactions' : `Run Transactions for ${offeringTitle}`}
      </h2>
      <Card>
        <Row>
          <Col lg={5} push={{ lg: 4 }}>
            {showActions && (
              <>
                <h4>Bulk Actions</h4>
                {hasDraftTransactions && draftTransactionStats}
                {hasDraftTransactions && (
                  <button
                    className={`textLink underline ${styles.bulkButton}`}
                    disabled={loading || !!dirtyTransaction}
                    onClick={() => setShowRunAllModal(true)}
                    type="button"
                  >
                    Run all transactions
                  </button>
                )}
                {hasPendingTransactions && (
                  <button
                    className={`textLink underline ${styles.bulkButton}`}
                    disabled={loading}
                    onClick={() => setShowCancelAllModal(true)}
                    type="button"
                  >
                    Cancel all pending transactions
                  </button>
                )}
              </>
            )}
          </Col>
          <Col lg={4} pull={{ lg: 5 }}>
            <h4>Filter Transactions</h4>
            <Row>
              <Col md={6}>
                <Select
                  data-testid="statusFilter"
                  disabled={loading}
                  id="status"
                  label="Status"
                  onChange={value => handleSetStatus(value as string)}
                  options={hasTransactions ? getStatusOptions(statusCount) : []}
                  value={status}
                />
              </Col>
              <Col md={6}>
                <Select
                  data-testid="effectiveDateFilter"
                  disabled={loading}
                  id="date"
                  label="Effective Date"
                  onChange={value => handleSetEffectiveDate(value as string)}
                  options={
                    hasTransactions
                      ? getEffectiveDateOptions(effectiveDates as string[])
                      : []
                  }
                  value={selectedEffectiveDate}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={3}>
            {hasEditableTransactions && (
              <>
                <h4 className={styles.updateTransactionHeader}>
                  Update Transaction
                </h4>
                <div className={styles.updateButtonWrapper}>
                  <Button
                    disabled={!dirtyTransaction}
                    onClick={handleResetTransactionEdits}
                    variant="outlined"
                  >
                    Reset
                  </Button>
                  <Button
                    disabled={!dirtyTransaction}
                    onClick={() =>
                      setShowEditTransactionModal(!!dirtyTransaction)
                    }
                  >
                    Save
                  </Button>
                </div>
              </>
            )}
          </Col>
        </Row>
        <TransactionsTable
          dirtyTransaction={dirtyTransaction}
          handleTransactionEdit={handleTransactionEdit}
          loading={loading}
          offeringId={offeringId}
          transactions={filteredTransactions}
        />
      </Card>
    </>
  );
};

export default RunTransactions;
