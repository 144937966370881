/* eslint-disable react/require-default-props */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { forwardRef, MutableRefObject } from 'react';

import * as inputStyles from './RichEditor.module.scss';

export type Props = {
  content?: string;
  'data-testid'?: string;
  errorMessage?: string;
  onChange: (change: string) => void;
};

const config = {
  heading: {
    options: [
      { class: 'ck-heading_paragraph', model: 'paragraph', title: 'Paragraph' },
      {
        class: 'ck-heading_heading1',
        model: 'heading1',
        title: 'Heading 1',
        view: 'h1'
      },
      {
        class: 'ck-heading_heading2',
        model: 'heading2',
        title: 'Heading 2',
        view: 'h2'
      }
    ]
  },
  link: {
    decorators: {
      openInNewTab: {
        attributes: {
          rel: 'noopener noreferrer',
          target: '_blank'
        },
        defaultValue: true,
        label: 'Open in a new tab',
        mode: 'manual'
      }
    }
  },
  toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    '|',
    'blockQuote',
    '|',
    'numberedList',
    'bulletedList',
    'outdent',
    'indent',
    '|',
    'link',
    'insertTable',
    'undo',
    'redo'
  ],
  typing: {
    transformations: {
      remove: ['oneHalf', 'oneThird', 'twoThirds']
    }
  }
};

export const handleChange = (onChange: (change: string) => void) => {
  /* eslint-disable @typescript-eslint/no-explicit-any */

  return (_: any, editor: { getData: () => any }): void => {
    const data = editor.getData();
    onChange(data);
  };
};

const RichEditor = forwardRef<any, Props>(
  (
    {
      content = '',
      'data-testid': dataTestId,
      errorMessage = '',
      onChange = () => null
    },
    ref
  ) => {
    let className = '';
    if (errorMessage) className += ` fieldWithErrors`;

    return (
      <div className={className} data-testid={dataTestId}>
        <CKEditor
          config={config}
          data={content}
          editor={ClassicEditor}
          onChange={handleChange(onChange)}
          onReady={editor => {
            if (ref && Object.prototype.hasOwnProperty.call(ref, 'current'))
              (ref as MutableRefObject<HTMLDivElement>).current = editor;
          }}
        />
        {errorMessage && (
          <div className={`${inputStyles.errorMessage}`}>{errorMessage}</div>
        )}
      </div>
    );
  }
);

RichEditor.displayName = 'RichEditor';

export default RichEditor;
