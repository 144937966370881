import { Input, Select } from '@equitymultiple/react-eui';
import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import {
  EditInvestmentAccountInput,
  InvestmentAccount
} from '../../../../__generated__';
import { setFieldProps } from '../../../../utils/formHelpers';
import { iraBusinessTypeOptions } from '../../constants';

interface Props {
  account: InvestmentAccount;
  control: Control<EditInvestmentAccountInput>;
  errors: FieldErrors;
}
const FieldsIra: React.FC<Props> = ({ account, control, errors }) => {
  return (
    <div className="firstChildMarginTop0">
      <h3>Information</h3>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="entityName"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Custodian" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="iraAccountFboName"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Account / FBO Name"
              />
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="iraAccountNumber"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Custodian Account Number"
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="iraRoutingNumber"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Custodian Routing Number (Optional)"
              />
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="businessType"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="IRA Account Type"
                onChange={e => {
                  field.onChange(e);
                }}
                options={iraBusinessTypeOptions}
              />
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={6}>
          <Input
            disabled
            id="status"
            label="Status"
            value={account.status || ''}
          />
        </Col>
        <Col lg={4} md={6}>
          <Input
            disabled
            id="dateCompleted"
            label="Date Completed"
            value={
              account.completedAt
                ? moment(new Date(account.completedAt)).format('MM/DD/YYYY')
                : ''
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default FieldsIra;
