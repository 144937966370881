import * as yup from 'yup';

import { DocumentType } from '../../../../../__generated__';
import { SchemaShape } from '../../../../../types/yup';
import { date, futureDate, yupString } from '../../../../../utils/validations';
import { FormValues } from './constants';

// Validations shared between multiple schemas
const identificationDocument = yup.object().shape({
  attachment: yup.mixed().required('ID is required'),
  expiry: date.required('ID expiration date is required').test(futureDate),
  idType: yupString
    .oneOf(
      Object.values(DocumentType),
      'ID type is required and must be a valid value'
    )
    .required('ID type is required')
});

export const individualAccountFormSchema = yup.object<SchemaShape<FormValues>>({
  identificationDocument
});

export const jointAccountFormSchema = yup.object<SchemaShape<FormValues>>({
  identificationDocument,
  secondaryIdentityDocument: identificationDocument
});

export const entityAccountFormSchema = yup.object<SchemaShape<FormValues>>({
  einEvidenceDocument: yup.object().shape({
    attachment: yup.mixed().required('EIN evidence document is required')
  }),
  entityEvidenceDocument: yup.object().shape({
    attachment: yup
      .mixed()
      .required('Organizational evidence document is required')
  }),
  identificationDocument
});
