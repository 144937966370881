import * as yup from 'yup';

import { UploadDocumentInput } from '../../../__generated__';
import { SchemaShape } from '../../../types/yup';
import { yupString } from '../../../utils/validations';

export const documentSchema = yup.object<SchemaShape<UploadDocumentInput>>({
  associate: yupString.when('type', {
    is: 'account_document',
    then: () => yupString.required('Document Associate is required')
  }),
  attachment: yup.mixed().required('Document is required'),
  documentType: yupString
    .required('Document Type is required')
    .when('type', {
      is: 'tax_document',
      then: () => yupString.required('Tax Document type is required')
    })
    .when('type', {
      is: 'account_document',
      then: () => yupString.required('Account Document type is required')
    })
    .when('type', {
      is: 'investment_document',
      then: () => yupString.required('Investment Document type is required')
    }),
  taxYear: yupString.when('type', {
    is: 'tax_document',
    then: () => yupString.required('Tax Year is required')
  }),
  type: yupString.required('Document Type is required')
});
