const formatCurrency = (amount: number | string, decimal = 2): string => {
  const numberAmount = typeof amount === 'string' ? parseInt(amount) : amount;
  return new Intl.NumberFormat('en-US', {
    currency: 'USD',
    maximumFractionDigits: decimal,
    minimumFractionDigits: decimal,
    style: 'currency'
  }).format(numberAmount);
};

export default formatCurrency;
