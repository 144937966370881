import { Button, DateSelect, Modal } from '@equitymultiple/react-eui';
import moment from 'moment';
import React, { useState } from 'react';

import {
  OfferingTaxStatus,
  useEditOfferingTaxStatusMutation
} from '../../../__generated__';
import * as modalStyles from '../../../styles/components/Modal.module.scss';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import { date, futureDate } from '../../../utils/validations';

const editEstimatedCompletionDateMessages = {
  error:
    'An error occured while attempting to update the estimated completion date',
  loading: 'Updated estimated completion date',
  success: 'Estimated completion date updated'
};

export interface EditEstimatedCompletionDateModalInput {
  offeringTaxStatus: OfferingTaxStatus;
  remove: boolean;
}

export interface Props {
  handleCloseModal: () => void;
  params?: EditEstimatedCompletionDateModalInput;
}

const dateSchema = date.required('Please select a valid date').test(futureDate);

const EditEstimatedCompletionDateModal: React.FC<Props> = ({
  handleCloseModal,
  params
}) => {
  const estimatedCompletionDate =
    params?.offeringTaxStatus.estimatedCompletionDate;
  const currentDate = estimatedCompletionDate
    ? moment(estimatedCompletionDate).format('MM/DD/YYYY')
    : undefined;
  const remove = params?.remove;

  const [newDate, setNewDate] = useState(currentDate);
  const [errors, setErrors] = useState();
  const [editOfferingTaxStatusMutation, editOfferingTaxStatusMutationState] =
    useEditOfferingTaxStatusMutation();

  const handleDateChange = (value: string) => {
    setNewDate(moment(value).format('YYYY-MM-DD'));
    if (errors) setErrors(undefined);
  };

  const onCloseModal = () => {
    setNewDate(undefined);
    setErrors(undefined);
    handleCloseModal();
  };

  const handleEditOfferingTaxStatus = () => {
    callMutationWithToastMessages(
      editOfferingTaxStatusMutation,
      editEstimatedCompletionDateMessages,
      {
        variables: {
          offeringTaxStatus: {
            estimatedCompletionDate: remove ? null : newDate,
            id: params?.offeringTaxStatus.id as string
          }
        }
      }
    ).then(() => {
      onCloseModal();
      editOfferingTaxStatusMutationState.reset();
    });
  };

  const handleConfirmChange = async () => {
    if (remove) {
      handleEditOfferingTaxStatus();
    } else {
      dateSchema
        .validate(newDate)
        .then(handleEditOfferingTaxStatus)
        .catch(error => {
          setErrors(error.errors[0]);
        });
    }
  };

  const newDateFormatted = newDate ? moment(newDate).format('MM/DD/YYYY') : '';

  return (
    <Modal
      closeButtonText="Cancel"
      data-testid="editEstimatedCompletionDateModal"
      onClose={onCloseModal}
      open={!!params?.offeringTaxStatus}
      submitButton={
        <Button
          loading={editOfferingTaxStatusMutationState.loading}
          onClick={handleConfirmChange}
        >
          Confirm Change
        </Button>
      }
    >
      <div className={modalStyles.contentContainer}>
        <div className={modalStyles.contentItem}>
          <h5>Selected tax status</h5>
          <p>
            <span data-testid="offeringTaxStatusOffering">
              <b>Offering Title: </b>
              {params?.offeringTaxStatus.offeringTitle}
            </span>
            <br />
            <span data-testid="offeringTaxStatusEntity">
              <b>Entity Name: </b>
              {params?.offeringTaxStatus.entityName}
            </span>
            <br />
            <span data-testid="offeringTaxStatusSponsor">
              <b>Sponsor Name: </b>
              {params?.offeringTaxStatus.sponsorName}
            </span>
            <br />
          </p>
          <h5>Update estimated completion date</h5>
          {remove ? (
            <>
              <p>
                Remove the estimated completion date from this tax status. A new
                date can be added at any time.
              </p>
              <p className="textRed">
                Note: you can not retroactively add a past date for the
                estimated completion date. Please ensure you have selected the
                correct tax status before proceeding.
              </p>
            </>
          ) : (
            <DateSelect
              data-testid="estimated-completion-date"
              errorMessage={errors}
              futureDate
              id="estimated-completion-date"
              label="Estimated completion date"
              onChange={handleDateChange}
              value={newDateFormatted}
            />
          )}
          <p>
            <span data-testid="offeringTaxStatusFrom">
              <b>From: </b>
              {currentDate || 'None'}
            </span>
            <br />
            <span data-testid="offeringTaxStatusTo">
              <b>To: </b>
              {remove ? 'None' : newDateFormatted}
            </span>
            <br />
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default EditEstimatedCompletionDateModal;
