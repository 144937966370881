import * as yup from 'yup';

import { CreatePostInput, EditPostInput } from '../../../../__generated__';
import { SchemaShape } from '../../../../types/yup';
import { yupNumber, yupString } from '../../../../utils/validations';

export const postSchema = yup.object<
  SchemaShape<CreatePostInput | EditPostInput>
>({
  audience: yup.mixed(),
  data: yup.object({
    attachments: yup.array(yupString),
    richText: yup.object({ content: yupString })
  }),
  label: yup.array(yupString),
  offeringId: yupNumber.required('Offering is required'),
  postAuthorId: yupNumber.required('Author is required'),
  postDate: yupString,
  title: yupString.required('Title is required')
});
