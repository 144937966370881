import React from 'react';

import { useGetInvestmentAccountBeneficialOwnersQuery } from '../../../../../__generated__';

interface Props {
  accountId: string;
}

const BeneficialOwnerDocuments: React.FC<Props> = ({ accountId }) => {
  const { data } = useGetInvestmentAccountBeneficialOwnersQuery({
    variables: {
      investmentAccountId: accountId
    }
  });

  const beneficialOwners =
    data?.investmentAccountBeneficialOwners.beneficialOwners;

  return (
    <div data-testid="beneficialOwners">
      <h4>Beneficial Owners' Documents</h4>
      {beneficialOwners?.length ? (
        <div>
          {beneficialOwners.map(beneficialOwner => {
            const document = beneficialOwner?.document;
            return (
              <div key={beneficialOwner?.id}>
                <h5 className="margin10">
                  {beneficialOwner?.firstName} {beneficialOwner?.lastName}
                </h5>
                {document ? (
                  <div>
                    <strong>File:</strong>{' '}
                    <a
                      href={document.url as string}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {document.filename}
                    </a>
                    <br />
                    <strong>Type:</strong> {document.type}
                  </div>
                ) : (
                  <p>No document found</p>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <p>No beneficial owners found</p>
      )}
    </div>
  );
};

export default BeneficialOwnerDocuments;
