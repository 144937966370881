import moment from 'moment';
import * as yup from 'yup';

import states from '../constants/states';

// Custom versions of types to use globally in place of the specified type
// Adds nullable() so we don't have to repeat it in every instance

export const yupNumber = yup
  .number()
  .nullable()
  .transform(value => (isNaN(value) ? null : value));
export const yupString = yup.string().nullable().trim();
export const yupBool = yup.bool().nullable();

// More complex validations that are used in multiple places

// Used for percentages (up to 99%) which are floats with up two digits
export const twoDigitNumberWithDecimals = yup
  .number()
  .nullable()
  .test(
    'Length',
    'Cannot contain more than two digits or two decimals',
    (val: null | number | undefined) => {
      if (val) {
        if (val.toString().match(/\d{0,2}(\.\d{1,2})?/)) return true;
        return false;
      }
      return true;
    }
  );

// A valid date string in MM/DD/YYYY format
export const date = yup
  .string()
  .nullable()
  .test(
    'Is a full valid date',
    'Must be a valid date',
    (val: null | string | undefined) => {
      if (val) {
        val.match(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
      }
      return true;
    }
  );

// A valid dateTime string in ISO 8601 format
export const dateTime = yup
  .string()
  .nullable()
  .test(
    'Is a full valid date time',
    'Must be a valid datetime',
    (val: null | string | undefined) => {
      if (val) {
        if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(val))
          return false;
        return new Date(val).toISOString() === val;
      }
      return true;
    }
  );

// Valid email address, required
export const email = yupString
  .email('Must be a valid email address')
  .required('Email address is required');

// Phone number, required and 10+ digits
export const phone = yupString
  .test(
    'Phone number is at least 10 digits',
    'Phone number must be at least 10 digits long',
    val => !val || val.replace(/\D/g, '').length > 9
  )
  .required('Phone number is required');

// SSN & EIN, only testing that length is 9 digits with masking enforcing the rest
export const ssn = yupString
  .required('SSN is required')
  .test(
    'SSN is 9 digits long',
    'SSNs must be 9 digits long',
    (val: null | string | undefined): boolean => {
      if (val) {
        return val.replace(/[\W_]/g, '').length === 9;
      }
      return false;
    }
  );
export const ein = yupString
  .required('EIN is required')
  .test(
    'EIN is 9 digits long',
    'EINs must be 9 digits long',
    (val: null | string | undefined): boolean => {
      if (val) {
        return val.replace(/[\W_]/g, '').length === 9;
      }
      return false;
    }
  );

export const state = yupString.required('State is required').oneOf(
  states.map(({ code }) => code),
  'State is required and must be a valid value'
);

// --- Tests
// Objects that can be passed to yup.test containing reusable logic

// Age 18+
export const ageOver18 = {
  message: 'Must be at least 18 years old',
  name: 'Age is at least 18',
  test: (val: null | string | undefined): boolean => {
    if (val) {
      const validDate = moment(val, 'YYYY-MM-DD', true).isValid();
      if (validDate) {
        const eighteenYearsAgo = moment().subtract(18, 'years');
        const birthday = moment(val);
        return eighteenYearsAgo.isAfter(birthday);
      }
      return false;
    }
    return true;
  }
};

// Date is prior to the current date
export const pastDate = {
  message: 'Date must be before today',
  name: 'Date is in the past',
  test: (val: null | string | undefined): boolean => {
    if (val) {
      const validDate = moment(val, true).isValid();
      if (validDate) return moment().isAfter(val);
      return false;
    }
    return true;
  }
};

// Date is equal to or ahead of the current date
export const futureDate = {
  message: 'Date must be today or in the future',
  name: 'Date is in the future',
  test: (val: null | string | undefined): boolean => {
    if (val) {
      const validDate = moment(val, true).isValid();
      if (validDate) {
        return moment().add(-1, 'days').isBefore(val);
      }
      return false;
    }
    return true;
  }
};
