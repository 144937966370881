import { DateSelect, Input, Select } from '@equitymultiple/react-eui';
import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import {
  EditInvestmentAccountInput,
  InvestmentAccount
} from '../../../../__generated__';
import {
  setDateSelectFieldProps,
  setFieldProps
} from '../../../../utils/formHelpers';
import { ssn, ssnMaskOptions } from '../../../../utils/masks';
import { stateOptions } from './constants';

interface Props {
  account: InvestmentAccount;
  control: Control<EditInvestmentAccountInput>;
  errors: FieldErrors;
}

const FieldsIndividual: React.FC<Props> = ({ account, control, errors }) => {
  return (
    <div className="firstChildMarginTop0">
      <h3>Investor Information</h3>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="user.firstName"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="First Name" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="user.lastName"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Last Name" />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="user.ssn"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                inputMaskOptions={ssnMaskOptions}
                label="SSN"
                mask={ssn}
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="user.dob"
            render={({ field }) => (
              <DateSelect
                {...setDateSelectFieldProps(field, errors)}
                label="Date of Birth"
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="user.phone"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Phone" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Input
            disabled
            id="dateCompleted"
            label="Date Completed"
            value={
              account.completedAt
                ? moment(new Date(account.completedAt)).format('MM/DD/YYYY')
                : ''
            }
          />
        </Col>
      </Row>
      <h3>Address</h3>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="user.address"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Address" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="user.address2"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Address 2 (Optional)"
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="user.city"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="City" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="user.state"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="State"
                onChange={e => {
                  field.onChange(e);
                }}
                options={stateOptions}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="user.postalCode"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Postal Code"
                mask="9{5}"
              />
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

export default FieldsIndividual;
