import { Button, Modal } from '@equitymultiple/react-eui';
import React from 'react';

import {
  namedOperations,
  PostAuthor,
  useDeletePostAuthorMutation
} from '../../../__generated__';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';

export interface Props {
  selectedPostAuthor: null | PostAuthor;
  setSelectedPostAuthorState: (PostAuthor: null | PostAuthor) => void;
}

const messages = {
  error: 'An error occurred while deleting the post author',
  loading: 'Deleting',
  success: 'Post author deleted'
};

const DeletePostAuthorModal: React.FC<Props> = ({
  selectedPostAuthor,
  setSelectedPostAuthorState
}) => {
  const postAuthorId = selectedPostAuthor?.id as string;
  const [deletePostAuthorMutation, deletePostAuthorMutationState] =
    useDeletePostAuthorMutation({
      refetchQueries: [namedOperations.Query.getPostAuthors],
      variables: {
        postAuthorId
      }
    });

  const handleDeletePostAuthor = () => {
    callMutationWithToastMessages(deletePostAuthorMutation, messages).then(
      () => {
        setSelectedPostAuthorState(null);
        deletePostAuthorMutationState.reset();
      }
    );
  };

  const hideDeleteModal = () => setSelectedPostAuthorState(null);

  return (
    <Modal
      closeButtonText="Cancel"
      data-testid="deletePostAuthorModal"
      onClose={hideDeleteModal}
      open={selectedPostAuthor !== null}
      submitButton={
        <Button onClick={() => handleDeletePostAuthor()} variant="orange">
          Yes, Delete It
        </Button>
      }
      title={<h6>Are you sure you want to delete this post author?</h6>}
    >
      <div className="margin30">
        <div data-testid="deleteModalPostAuthorName">
          <strong>Name:</strong>{' '}
          {`${selectedPostAuthor?.firstName} ${selectedPostAuthor?.lastName}`}
        </div>
        <div data-testid="deleteModalPostAuthorEmail">
          <strong>Email:</strong> {selectedPostAuthor?.email}
        </div>
      </div>
    </Modal>
  );
};

export default DeletePostAuthorModal;
