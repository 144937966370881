import { EMLoadingIcon } from '@equitymultiple/react-eui';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetActiveUserQuery } from '../../../__generated__';

interface Props {
  children: React.ReactNode;
}

const UserAuth: React.FC<Props> = ({ children }) => {
  const { data, error, loading } = useGetActiveUserQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      navigate(`/login?returnTo=${window.location.pathname}`);
    }
  }, [error, navigate]);

  useEffect(() => {
    if (data?.activeUser) {
      Sentry.setUser({
        email: data.activeUser.email
      });
    }
  }, [data]);

  if (loading) {
    return <EMLoadingIcon data-testid="emLoadingIcon" />;
  }

  if (data?.activeUser) {
    return <>{children}</>;
  }

  return null;
};

export default UserAuth;
