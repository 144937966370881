import { MutationFunction } from '@apollo/client';
import { MutationBaseOptions } from '@apollo/client/core/watchQueryOptions';
import { toast } from 'react-hot-toast';

interface Messages {
  error?: string;
  loading?: string;
  success?: string;
}

const callMutationWithToastMessages = async (
  // eslint-disable-next-line
  mutation: MutationFunction<any, any>,
  messages: Messages,
  mutationOptions?: MutationBaseOptions
  // eslint-disable-next-line
): Promise<any> => {
  const loadingMessage = messages.loading || 'Loading';
  const errorMessage = messages.error || 'An error occurred';
  const successMessage = messages.success || 'Success';

  toast.loading(loadingMessage);
  return mutation(mutationOptions)
    .then(res => {
      toast.dismiss();
      if (res.errors) {
        toast.error(errorMessage);
      } else if (res.data) {
        const name = Object.keys(res.data)[0];
        const { error } = res.data[name];

        if (error?.message) {
          toast.error(error.message || errorMessage);
        } else {
          toast.success(successMessage);
        }
      }
      return res;
    })
    .catch(err => {
      toast.dismiss();
      toast.error(errorMessage);
      return err;
    });
};

export default callMutationWithToastMessages;
