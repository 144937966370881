import moment from 'moment';
import * as yup from 'yup';

import {
  AllocationMethod,
  CreatePeriodInput,
  EditPeriodInput,
  TransactionTypeCode
} from '../../../__generated__';
import { SchemaShape } from '../../../types/yup';
import { enumValueToLabel } from '../../../utils/stringFormatting';
import { date, yupString } from '../../../utils/validations';

const allowedTypesForPercent = [
  TransactionTypeCode.AssetManagementFee,
  TransactionTypeCode.ReferralBonus,
  TransactionTypeCode.OriginationFee
];

const generatePercentTypeErrorMessage = () => {
  let typesString = '';
  allowedTypesForPercent.forEach(type => {
    typesString = `${typesString}${enumValueToLabel(
      TransactionTypeCode,
      type
    )}, `;
  });
  typesString = typesString.substring(0, typesString.length - 2);

  return `Transaction type must be one of: [${typesString}] for Percent Allocation Method`;
};

export const periodSchema = yup.object<
  SchemaShape<CreatePeriodInput | EditPeriodInput>
>({
  allocations: yup
    .array(
      yup.object({
        amount: yupString.when('method', {
          is: AllocationMethod.ProRata,
          then: () => yupString.required('Allocation amount is required')
        }),
        method: yupString.required('Allocation method is required'),
        type: yupString.required('Allocation type is required').when('method', {
          is: AllocationMethod.Percent,
          then: () =>
            yupString.oneOf(
              allowedTypesForPercent,
              generatePercentTypeErrorMessage()
            )
        }),
        value: yupString.when('method', {
          is: AllocationMethod.Flat,
          then: () => yupString.required('Allocation value is required')
        })
      })
    )
    .min(1, 'At least one transaction type must be added'),
  endDate: date.required('End date is required').test({
    message: 'End date needs to be greater than the Start date',
    name: 'endDate',
    test: (endDate, context) => {
      if (context.parent.startDate) {
        return moment(new Date(context.parent.startDate)).isBefore(
          moment(new Date(endDate))
        );
      }
      return true;
    }
  }),
  offeringId: yupString.required('Offering ID is required'),
  startDate: date
});
