import { Button, EMLoadingIcon, Input } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, DefaultValues, useForm } from 'react-hook-form';

import {
  ApiTokenInput,
  useCreateApiTokenMutation,
  useGetApiTokensQuery,
  useToggleApiTokenMutation
} from '../../../__generated__';
import * as loadingIconStyles from '../../../styles/components/EMLoadingIcon.module.scss';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import { setFieldProps } from '../../../utils/formHelpers';
import { createApiTokenSchema } from './validation';

const createMessages = {
  error: 'An error occurred while attempting to create the api token',
  loading: 'Creating an api token',
  success: 'Created'
};

const toggleMessages = {
  error: 'An error occurred while attempting to update the api token',
  loading: 'Updating the api token',
  success: 'Updated'
};

type FormValues = ApiTokenInput;

const ApiTokens: React.FC = () => {
  const { data, loading, refetch } = useGetApiTokensQuery();

  const hasApiTokens = data?.apiTokens.data;

  const defaultValues = {
    name: ''
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<DefaultValues<FormValues>>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(createApiTokenSchema)
  });

  const [createApiToken, createApiTokenState] = useCreateApiTokenMutation();
  const creating = createApiTokenState.loading;

  const onSubmit = async (submitData: Partial<FormValues>) => {
    await callMutationWithToastMessages(createApiToken, createMessages, {
      variables: {
        apiToken: submitData as ApiTokenInput
      }
    });
    refetch();
    reset({ name: '' });
  };

  const [toggleApiToken, toggleApiTokenState] = useToggleApiTokenMutation();
  const toggling = toggleApiTokenState.loading;

  const toggleToken = (tokenId: null | string | undefined) => {
    if (tokenId) {
      callMutationWithToastMessages(toggleApiToken, toggleMessages, {
        variables: {
          apiTokenId: tokenId
        }
      });
    }
  };

  return (
    <>
      <h2 className="marginTop0" data-testid="apiTokensHeading">
        API Tokens
      </h2>

      <form data-testid="transactionForm" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={6} md={6} xl={4}>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  label="API Application Name"
                />
              )}
            />
          </Col>
        </Row>

        <Button className="margin40" loading={creating} type="submit">
          Create
        </Button>
      </form>

      <div id="apiTokensWrapper">
        {loading ? (
          <EMLoadingIcon className={loadingIconStyles.cardLoader} />
        ) : (
          hasApiTokens &&
          data.apiTokens.data.map(token => {
            return (
              <div key={token.id}>
                <h4>
                  {token.name} ({token.active ? 'Active' : 'Inactive'})
                </h4>

                <Row>
                  <Col lg={3} md={3} xl={3}>
                    <h6>API Key</h6>
                    <div className="codeBlock">{token.token}</div>
                  </Col>
                  <Col lg={6} md={6} xl={6}>
                    <h6>API Secret</h6>
                    <div className="codeBlock">{token.secret}</div>
                  </Col>
                  <Col lg={3} md={3} xl={3}>
                    <h6>{token.access} Hits</h6>
                    <button
                      className="textLink underline marginTop10"
                      disabled={toggling}
                      onClick={() => toggleToken(token.id)}
                      type="button"
                    >
                      {token.active ? 'Deactivate' : 'Activate'}
                    </button>
                  </Col>
                </Row>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default ApiTokens;
