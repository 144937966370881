import { Button, DateSelect } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';

import {
  useEditSettingMutation,
  useGetSettingsQuery
} from '../../../__generated__';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import { setDateSelectFieldProps } from '../../../utils/formHelpers';
import { taxTrackerSettingsSchema } from './validation';

const messages = {
  error: 'An error occurred while attempting to update',
  loading: 'Updating',
  success: 'Updated'
};

type FormValues = {
  startOfSeason?: null | string | undefined;
  taxFilingDeadline?: null | string | undefined;
};

const TaxTracker: React.FC = () => {
  const { data: startOfSeasonData, loading: startOfSeasonLoading } =
    useGetSettingsQuery({
      variables: {
        name: 'start_of_season'
      }
    });

  const startOfSeason =
    startOfSeasonData?.settings?.settings &&
    startOfSeasonData.settings.settings[0];

  const { data: taxFilingDeadlineData, loading: taxFilingDeadlineLoading } =
    useGetSettingsQuery({
      variables: {
        name: 'tax_filing_deadline'
      }
    });

  const taxFilingDeadline =
    taxFilingDeadlineData?.settings?.settings &&
    taxFilingDeadlineData.settings.settings[0];

  const defaultValues = {
    startOfSeason: startOfSeason?.value,
    taxFilingDeadline: taxFilingDeadline?.value
  };

  const loading = startOfSeasonLoading || taxFilingDeadlineLoading;

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm<DefaultValues<FormValues>>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(taxTrackerSettingsSchema)
  });

  useEffect(() => {
    if (startOfSeason?.value && taxFilingDeadline?.value) {
      setValue('startOfSeason', startOfSeason?.value);
      setValue('taxFilingDeadline', taxFilingDeadline?.value);
    }
  }, [startOfSeason, taxFilingDeadline, setValue]);

  const [editSetting, editSettingState] = useEditSettingMutation();
  const updating = editSettingState.loading;

  const onSubmit = async (submitData: Partial<FormValues>) => {
    callMutationWithToastMessages(editSetting, messages, {
      variables: {
        setting: {
          id: startOfSeason?.id || '',
          value: submitData.startOfSeason
        }
      }
    });
    // We don't want to show two messages, so for the second mutation call we'll only catch errors
    editSetting({
      variables: {
        setting: {
          id: taxFilingDeadline?.id || '',
          value: submitData.taxFilingDeadline
        }
      }
    }).catch(() => {
      toast.dismiss();
      toast.error(messages.error);
    });
  };

  return (
    <>
      <h2 className="marginTop0" data-testid="taxTrackerHeading">
        Tax Tracker Settings
      </h2>
      <form data-testid="taxTrackerForm" onSubmit={handleSubmit(onSubmit)}>
        <h4 className="marginTop0" data-testid="startOfSeasonHeading">
          Start of Season
        </h4>
        <p data-testid="startOfSeasonDescription">
          On this date, all Tax Tracker components should become visble to any
          Investor in Multiply
        </p>
        <Row>
          <Col lg={6}>
            <Controller
              control={control}
              name="startOfSeason"
              render={({ field }) => (
                <DateSelect
                  {...setDateSelectFieldProps(field, errors)}
                  anyDate
                  disabled={loading}
                  label="Start of Season"
                />
              )}
            />
          </Col>
        </Row>
        <hr />

        <h4 className="marginTop0" data-testid="taxFilingDeadlineHeading">
          Tax-filing Deadline
        </h4>
        <p data-testid="taxFilingDeadlineDescription">
          After this date, Tax Tracker components will be hidden for Investors
          who have received all of their Tax Documents. Investors with pending
          or delayed documents will still be shown the Tax Tracker components
          past this date.
        </p>
        <Row>
          <Col lg={6}>
            <Controller
              control={control}
              name="taxFilingDeadline"
              render={({ field }) => (
                <DateSelect
                  {...setDateSelectFieldProps(field, errors)}
                  anyDate
                  disabled={loading}
                  label="Tax Filing Deadline"
                />
              )}
            />
          </Col>
        </Row>

        <Button
          className="floatRight margin40"
          loading={updating}
          type="submit"
        >
          Submit
        </Button>
      </form>
    </>
  );
};

export default TaxTracker;
