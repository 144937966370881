import { StatusLabel } from '@equitymultiple/react-eui';
import { InvestmentAccountStatus } from '__generated__';
import React from 'react';

interface UserStatus {
  confirmed?: boolean;
  deleted?: boolean;
  suspended?: boolean;
}

export const getUserStatus = (user: UserStatus) => {
  if (user.deleted) return <StatusLabel color="#D01231" label="Deleted" />;
  if (user.suspended) return <StatusLabel color="#D01231" label="Suspended" />;
  if (user.confirmed) return <StatusLabel color="#4CAF50" label="Active" />;
  return <StatusLabel color="#FDB309" label="Pending" />;
};

export const getAccountStatus = (status: InvestmentAccountStatus) => {
  if (status === InvestmentAccountStatus.Flagged)
    return <StatusLabel color="#D01231" label="Flagged" />;
  if (status === InvestmentAccountStatus.Created)
    return <StatusLabel color="#4CAF50" label="Created" />;
  if (status === InvestmentAccountStatus.Approved)
    return <StatusLabel color="#4CAF50" label="Approved" />;
  if (status === InvestmentAccountStatus.InProgress)
    return <StatusLabel color="#FDB309" label="In Progress" />;
  if (status === InvestmentAccountStatus.Pending)
    return <StatusLabel color="#FDB309" label="Pending" />;
  return <StatusLabel color="#062164" label="To Do" />;
};
