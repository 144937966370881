import { Button, Select } from '@equitymultiple/react-eui';
import Option, {
  NonAsyncValue
  // eslint-disable-next-line import/no-unresolved
} from '@equitymultiple/react-eui/dist/types/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';

import {
  DwollaCustomer,
  DwollaFundingSource,
  useEditOfferingBankAccountMutation,
  useGetDwollaFundingSourcesLazyQuery
} from '../../../../__generated__';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import { setFieldProps } from '../../../../utils/formHelpers';
import { bankAccountSchema } from '../validation';

interface BankAccountInfo {
  dwollaCustomerId?: string;
  dwollaFundingSourceId?: string;
}

interface Props {
  bankAccountInfo?: BankAccountInfo;
  dwollaCustomers: DwollaCustomer[];
  id: string;
}

type FormValues = BankAccountInfo;

const messages = {
  error: 'An error occurred while updating bank account information',
  loading: 'Updating',
  success: 'Bank account information updated'
};

const AttachBankAccount: React.FC<Props> = ({
  bankAccountInfo,
  dwollaCustomers,
  id
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    setValue
  } = useForm<FormValues>({
    defaultValues: {
      ...bankAccountInfo
    },
    mode: 'onBlur',
    resolver: yupResolver(bankAccountSchema)
  });
  const [
    getFundingSources,
    {
      called,
      data: fundingSourcesData,
      loading: fundingSourcesLoading,
      refetch
    }
  ] = useGetDwollaFundingSourcesLazyQuery({
    notifyOnNetworkStatusChange: true
  });
  const [editOfferingBankAccount, editOfferingBankAccountState] =
    useEditOfferingBankAccountMutation();
  const { loading } = editOfferingBankAccountState;
  const [fundingSources, setFundingSources] = useState<DwollaFundingSource[]>(
    []
  );
  const { dwollaCustomerId } = getValues();

  useEffect(() => {
    // Load funding sources if a customer ID is already saved when the page loads
    if (dwollaCustomerId && !called)
      getFundingSources({ variables: { dwollaCustomerId } });

    // Set funding sources whenever the customer is changed and new sources are loaded
    if (fundingSourcesData?.dwollaFundingSources.dwollaFundingSources)
      setFundingSources(
        fundingSourcesData?.dwollaFundingSources
          .dwollaFundingSources as DwollaFundingSource[]
      );
  }, [dwollaCustomerId, fundingSourcesData, getFundingSources, called]);

  let customerOptions: Option[] = [];
  if (dwollaCustomers) {
    customerOptions = dwollaCustomers.map(customer => ({
      label: customer.businessName as string,
      value: customer.id as string
    }));
  }

  let bankAccountOptions: Option[] = [];
  if (fundingSources) {
    bankAccountOptions = fundingSources.map(fundingSource => {
      let label =
        fundingSource.name ||
        fundingSource.bankAccount?.name ||
        '[Unnamed Bank Account]';
      if (fundingSource.type === 'balance') label = 'Balance';
      label += ` (${fundingSource.status})`;

      return {
        label,
        value: fundingSource.id as string
      };
    });
  }

  const handleCustomerChange = (customerId: NonAsyncValue) => {
    const variables = {
      dwollaCustomerId: customerId as string
    };
    if (!called) getFundingSources({ variables });
    else refetch(variables);
    setValue('dwollaCustomerId', customerId as string);
  };

  const handleBankAccountChange = (bankAccountId: NonAsyncValue) => {
    setValue('dwollaFundingSourceId', bankAccountId as string);
  };

  const onSubmit = (values: FormValues) => {
    callMutationWithToastMessages(editOfferingBankAccount, messages, {
      variables: {
        offering: {
          id,
          ...values
        }
      }
    });
  };

  let bankAccountHelperText = '';
  // Loading bank accounts
  if (fundingSourcesLoading) bankAccountHelperText = 'Loading bank accounts...';
  // Loaded, no bank accounts found
  else if (dwollaCustomerId && !fundingSources.length)
    bankAccountHelperText = 'No bank accounts found';
  // Customer not yet selected
  else if (!dwollaCustomerId)
    bankAccountHelperText = 'Select a customer to see bank accounts';

  return (
    <form
      className="clearFix"
      data-testid="bankForm"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h3>Attach a Bank Account for Investments & Distributions</h3>

      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="dwollaCustomerId"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                disabled={fundingSourcesLoading}
                label="Customer"
                onChange={handleCustomerChange}
                options={customerOptions}
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="dwollaFundingSourceId"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                disabled={fundingSourcesLoading}
                helperText={bankAccountHelperText}
                helperTextAlwaysVisible
                label="Bank Account"
                onChange={handleBankAccountChange}
                options={bankAccountOptions}
              />
            )}
          />
        </Col>
      </Row>

      <Button className="floatRight" loading={loading} type="submit">
        Save Bank Account Info
      </Button>
    </form>
  );
};

export default AttachBankAccount;
