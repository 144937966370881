import { Card, Checkbox, Input, Table } from '@equitymultiple/react-eui';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import formatCurrency from 'utils/formatCurrency';
import { capitalize } from 'utils/stringFormatting';

import { useGetUsersQuery, User } from '../../__generated__';
import useDebounce from '../../hooks/useDebounce';
import { getUserStatus } from './helpers';

const columnHeaders = [
  'ID',
  'First Name',
  'Last Name',
  'Email',
  'Stage',
  'Signed Up',
  'Lifetime Invested',
  'Status',
  ''
];

const getRows = (users: (null | undefined | User)[]) =>
  users.map((user: null | undefined | User) => ({
    cells: user
      ? [
          user.id,
          user.firstName,
          user.lastName,
          user.email,
          capitalize(user.investorProfile?.stage),
          moment.utc(new Date(user.createdAt)).format('M/D/YYYY'),
          formatCurrency(user.lifetimeInvested),
          getUserStatus(user),
          <i className="fa fa-chevron-right" key="icon" />
        ]
      : [],
    link: `/users/${user.id}`
  }));

const Users: React.FC = () => {
  const [confirmedOnly, setConfirmedOnly] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const debouncedSearchValue: string = useDebounce<string>(searchValue, 300);

  const { data, fetchMore, loading, refetch } = useGetUsersQuery({
    variables: {
      confirmed: confirmedOnly,
      filter: debouncedSearchValue,
      pagination: {
        page,
        pageSize: rowsPerPage
      }
    }
  });

  useEffect(() => {
    if (debouncedSearchValue) refetch();
  }, [debouncedSearchValue, refetch]);

  const fetchMoreUsers = (pageNumber: number, pageSize: number) => {
    fetchMore({
      variables: {
        pagination: {
          page: pageNumber,
          pageSize
        }
      }
    });
  };

  const handleConfirmedOnlyChange = () => {
    setConfirmedOnly(!confirmedOnly);
  };

  const handleSearchValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(1);
    setSearchValue(event.target.value);
  };

  const handlePageChange = (
    _: null | React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => {
    const pageNumber = newPage + 1;
    setPage(pageNumber);
    fetchMoreUsers(pageNumber, rowsPerPage);
  };

  const handleRowsPerPageChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    const pageSize = parseInt(event.currentTarget.value);
    setRowsPerPage(pageSize);
    setPage(1);
    fetchMoreUsers(1, pageSize);
  };

  const hasUsers = data?.users?.data && data.users.data.length > 0;

  return (
    <>
      <h1 data-testid="usersHeading">Users</h1>
      <Card>
        <Row>
          <Col lg={4} md={6}>
            <Input
              id="usersSearch"
              label="Search"
              onChange={handleSearchValueChange}
              placeholder="First name, last name or email"
              value={searchValue}
            />
          </Col>
          <Col lg={4} md={6}>
            <Checkbox
              checked={confirmedOnly}
              className="margin20"
              id="confirmedUsers"
              label="Only confirmed and active users"
              onChange={handleConfirmedOnlyChange}
            />
          </Col>
        </Row>
        <Table
          columnHeaders={columnHeaders}
          data-testid="table"
          loading={loading}
          loadingRows={10}
          pagination={{
            count: data?.users?.pageInfo?.count || 0,
            onPageChange: handlePageChange,
            onRowsPerPageChange: handleRowsPerPageChange,
            // MUI expects 0 based index, API expects 1 based
            // MUI throws out of range error if page > 1 and there are no users
            page: hasUsers ? page - 1 : 0,
            rowsPerPage
          }}
          rows={
            hasUsers
              ? getRows(data?.users?.data as User[])
              : [{ cells: ['No users found'] }]
          }
        />
      </Card>
    </>
  );
};

export default Users;
