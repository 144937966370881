// eslint-disable-next-line import/no-unresolved
import Option from '@equitymultiple/react-eui/dist/types/Select';
import moment from 'moment';
import { FieldError, FieldErrors } from 'react-hook-form';

import cloneObject from '../utils/cloneObject';
import { capitalToTitleCase } from './stringFormatting';

type FieldValue = null | number | Option | string | string[];

interface Field {
  name: string;
  value?: FieldValue;
}

interface FieldProps {
  errorMessage?: string;
  id: string;
  value?: FieldValue;
}

interface CheckboxField {
  name: string;
  value?: boolean | null;
}

interface CheckboxFieldProps {
  checked?: boolean;
  errorMessage?: string;
  id: string;
}

interface DateSelectField {
  name: string;
  onChange: (date: string) => void;
  ref?: unknown;
  value?: null | string | undefined;
}

interface DateSelectFieldProps {
  errorMessage?: string;
  id: string;
  onChange: (date: string) => void;
  value?: null | string;
}

// Helper for unpacking nested error messages with nested fields. Recursively
// looks up the error message based on field name and returns it, if found.
const getNestedErrors = (fieldName: string, errors: FieldErrors): string => {
  const nestedKeys = fieldName.split('.');
  let current: FieldError | FieldErrors = errors;
  let hasError = false;
  // The name of the key in the error object that we are actually looking for
  const rootKey = nestedKeys[nestedKeys.length - 1];

  for (const keyName of nestedKeys) {
    const isRoot = keyName === rootKey;

    if (current === undefined) {
      break;
    }

    if (Object.prototype.hasOwnProperty.call(current, keyName)) {
      current = current[keyName];
    } else {
      break;
    }

    if (isRoot && Object.prototype.hasOwnProperty.call(current, 'message')) {
      hasError = true;
      break;
    }
  }

  if (hasError && typeof current.message === 'string') {
    return current.message;
  }

  return '';
};

// When passed a field object from the Controller component and formState.errors,
// sets commonly reused props on REUI field components
export const setFieldProps = <T>(
  field: Field & T,
  errors: FieldErrors
): FieldProps & T => {
  return {
    ...field,
    errorMessage: getNestedErrors(field.name, errors),
    id: field.name,
    value: field.value
  };
};

// Sets checkbox props
export const setCheckboxFieldProps = <T>(
  field: CheckboxField & T,
  errors: FieldErrors
): CheckboxFieldProps & T => {
  return {
    ...field,
    checked: field.value || false,
    errorMessage: getNestedErrors(field.name, errors),
    id: field.name
  };
};

// Sets DateSelect props
export const setDateSelectFieldProps = <T>(
  field: DateSelectField & T,
  errors: FieldErrors
): DateSelectFieldProps & T => {
  delete field?.ref;

  return {
    ...field,
    errorMessage: getNestedErrors(field.name, errors),
    id: field.name,
    onChange: date => {
      field.onChange(moment(new Date(date)).utc().format('YYYY-MM-DD'));
    },
    value: field.value
      ? moment(new Date(field.value)).utc().format('MM/DD/YYYY')
      : ''
  };
};

// Replaces all undefined values with null
export const setDefaultValues = (
  obj: Record<string, unknown>
): Record<string, unknown> => {
  const newObj = cloneObject(obj);
  Object.keys(newObj).forEach(key => {
    if (newObj[key] === undefined) newObj[key] = null;
  });

  return newObj;
};

/**
 * Utility for building out form options from enums.
 */
export const buildOptions = (
  entries: [string, string][]
): { label: string; uuid: string; value: string }[] => {
  const options = [];
  for (const [key, value] of entries) {
    options.push({
      label: capitalToTitleCase(key),
      uuid: value.toString(),
      value: value.toString()
    });
  }
  return options;
};
