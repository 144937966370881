import {
  TransactionTypeCode,
  TransactionTypeCodeContribution,
  TransactionTypeCodeDistribution,
  TransactionTypeCodeFee
} from '__generated__';
import { enumToSelectOptions } from 'utils/enumToSelectOptions';

const transactionTypeOptions = enumToSelectOptions(TransactionTypeCode);

export const contributionTypeOptions = transactionTypeOptions.filter(option =>
  Object.values(TransactionTypeCodeContribution).some(
    enumValue => option.value === enumValue
  )
);

export const distributionTypeOptions = transactionTypeOptions.filter(option =>
  Object.values(TransactionTypeCodeDistribution).some(
    enumValue => option.value === enumValue
  )
);

export const feeTypeOptions = transactionTypeOptions.filter(option =>
  Object.values(TransactionTypeCodeFee).some(
    enumValue => option.value === enumValue
  )
);

export const fundAccountTypeOptions = transactionTypeOptions.filter(
  option => option.value === TransactionTypeCode.FundAccount
);

export const withdrawAccountTypeOptions = transactionTypeOptions.filter(
  option => option.value === TransactionTypeCode.WithdrawAccount
);
