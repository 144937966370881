import { Table } from '@equitymultiple/react-eui';
import {
  Document,
  DocumentType,
  UploadDocumentInput,
  useDeleteDocumentsMutation,
  useGetDocumentsQuery,
  useRestorePreviousDocumentsMutation,
  useUploadDocumentMutation
} from '__generated__';
import DocumentsActions from 'components/DocumentsActions/DocumentsActions';
import { DocumentSubtype, DocumentTypeDbValue } from 'constants/documents';
import moment from 'moment';
import React, { useState } from 'react';
import callMutationWithToastMessages from 'utils/callMutationWithToastMessages';

import { ColumnHeaders } from './constants';
import DocumentForm from './DocumentForm';
import * as styles from './Documents.module.scss';
import { documentSchema } from './validation';

const uploadMessages = {
  error: 'An error occurred while attempting to upload the document',
  loading: 'Uploading Document',
  success: 'Document added'
};

const deleteMessages = {
  error: 'An error occurred while deleting the documents',
  loading: 'Deleting',
  success: 'Documents deleted'
};

const restoreMessages = {
  error: 'An error occurred while restoring the documents',
  loading: 'Restoring',
  success: 'Documents restored'
};

const Documents: React.FC = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [deleteDocumentsMutation] = useDeleteDocumentsMutation();
  const [restorePreviousDocumentsMutation] =
    useRestorePreviousDocumentsMutation();

  const { data, fetchMore, loading } = useGetDocumentsQuery({
    variables: {
      pagination: {
        page,
        pageSize: rowsPerPage
      }
    }
  });

  const hasDocuments = data?.documents.data != undefined;

  const fetchMoreDocuments = (pageNumber: number, pageSize: number) => {
    fetchMore({
      variables: {
        pagination: {
          page: pageNumber,
          pageSize
        }
      }
    });
  };

  const handlePageChange = (
    _: null | React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => {
    const pageNumber = newPage + 1;
    setPage(pageNumber);
    fetchMoreDocuments(pageNumber, rowsPerPage);
  };

  const handleRowsPerPageChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    const pageSize = parseInt(event.currentTarget.value);
    setRowsPerPage(pageSize);
    setPage(1);
    fetchMoreDocuments(1, pageSize);
  };

  const refetch = () => {
    setPage(1);
    fetchMoreDocuments(1, rowsPerPage);
  };

  const [uploadDocument, createDocumentState] = useUploadDocumentMutation();
  const uploading = createDocumentState.loading;

  const defaultValues = {
    attachment: null,
    type: DocumentType.TaxDocument
  };

  const onSubmit = async (submitData: UploadDocumentInput) => {
    const isValid = await documentSchema.isValid(submitData);

    if (submitData.amended != undefined) {
      submitData.amended = submitData.amended.toString();
    }

    if (submitData.documentReplace != undefined) {
      submitData.documentReplace = submitData.documentReplace.toString();
    }

    if (isValid) {
      await callMutationWithToastMessages(uploadDocument, uploadMessages, {
        variables: {
          document: submitData
        }
      });
      refetch();
      return true;
    }
    return null;
  };

  const handleDeleteDocuments = (document: Document) => {
    callMutationWithToastMessages(deleteDocumentsMutation, deleteMessages, {
      variables: {
        documentId: document.id
      }
    }).then(() => {
      fetchMoreDocuments(page, rowsPerPage);
    });
  };

  const handleRestorePreviousDocuments = (document: Document) => {
    callMutationWithToastMessages(
      restorePreviousDocumentsMutation,
      restoreMessages,
      {
        variables: {
          documentId: document.id
        }
      }
    ).then(() => {
      fetchMoreDocuments(page, rowsPerPage);
    });
  };

  const getRows = (documents: Array<Document>) => {
    return documents.map(document => {
      const { createdAt, filename, meta, type, uploader } = document;

      return {
        cells: [
          filename,
          DocumentSubtype[type],
          (meta?.documentType ? DocumentTypeDbValue[meta?.documentType] : '') +
            (meta?.taxYear ? ` [${meta.taxYear}]` : ''),
          meta?.associate,
          meta?.files,
          <>
            <div>{uploader}</div>
            <div>
              {moment
                .utc(new Date(createdAt))
                .local()
                .format('MM/DD/YYYY hh:mm:ss A')}
            </div>
          </>,
          <DocumentsActions
            deleteDocuments={handleDeleteDocuments}
            document={document}
            key={document.id}
            restorePreviousDocuments={handleRestorePreviousDocuments}
          />
        ]
      };
    });
  };

  return (
    <>
      <h2 className="marginTop0" data-testid="documentsHeading">
        Documents
      </h2>
      <h4 className="marginTop0" data-testid="documentUpload">
        Document Upload
      </h4>
      <DocumentForm
        defaultValues={defaultValues}
        loading={uploading}
        onSubmit={onSubmit}
      />

      <Table
        className={styles.documentsTable}
        columnHeaders={ColumnHeaders}
        loading={loading}
        loadingRows={10}
        pagination={{
          count: data?.documents?.pageInfo?.count || 0,
          onPageChange: handlePageChange,
          onRowsPerPageChange: handleRowsPerPageChange,
          page: hasDocuments ? page - 1 : 0,
          rowsPerPage
        }}
        rows={
          data?.documents.data
            ? getRows(data?.documents.data)
            : [{ cells: ['No Documents found'] }]
        }
      />
    </>
  );
};

export default Documents;
