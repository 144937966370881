import { Breadcrumbs, Card } from '@equitymultiple/react-eui';
import { useGetInvestmentAccountQuery, useGetUserQuery } from '__generated__';
import React, { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { investmentAccountTypeLabels } from 'views/Accounts/constants';

import * as styles from './InvestmentAccounts.module.scss';

const User = () => {
  const navigate = useNavigate();
  const { id, userId } = useParams();

  const {
    data,
    error: userError,
    loading: userLoading
  } = useGetUserQuery({
    variables: {
      userId: userId
    }
  });

  const {
    data: accountData,
    error: accountError,
    loading: accountLoading
  } = useGetInvestmentAccountQuery({
    variables: {
      investmentAccountId: id
    }
  });

  const user = !userLoading && data?.user?.user;
  const account =
    !accountLoading && accountData?.investmentAccount?.investmentAccount;

  useEffect(() => {
    if (userError || accountError) {
      toast.error('An error occurred while attempting to load the account');
      navigate(`/users/${userId}`);
    }
  }, [userError, accountError, userId, navigate]);

  return (
    <>
      <Breadcrumbs data-testid="breadcrumbs">
        <Link to="/users">Users</Link>
        {user && (
          <Link to={`/users/${userId}`}>
            {user.firstName} {user.lastName}
          </Link>
        )}
        {account && (
          <span>
            #{account.id} • {investmentAccountTypeLabels[account.type]}
          </span>
        )}
      </Breadcrumbs>
      <Card data-testid="userDetails">
        <div className={styles.accountPage}>
          Feature Not Available. Please visit{' '}
          <Link to="/accounts">Accounts</Link> for Investment accounts details.
        </div>
      </Card>
    </>
  );
};
export default User;
