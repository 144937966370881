import { TableRow } from '@equitymultiple/react-eui';
import { Props as TableRowProps } from '@equitymultiple/react-eui/dist/components/Table/components/TableRow';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { useGetOfferingInvestmentDetailsQuery } from '../../../__generated__';
import formatCurrency from '../../../utils/formatCurrency';

const OfferingRow: React.FC<TableRowProps> = props => {
  const { row } = props;
  const offeringId = row.cells[0] as string;

  const { data, loading } = useGetOfferingInvestmentDetailsQuery({
    variables: {
      offeringInvestmentDetailsId: offeringId
    }
  });

  row.cells = row.cells.map((cell, index: number) => {
    if ([4, 5, 6, 7].includes(index)) {
      if (loading)
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div data-testid="loadingSkeleton" key={index}>
            <Skeleton />
          </div>
        );
      else {
        switch (index) {
          case 4:
            return data?.offeringInvestmentDetails?.data?.noInvestors || '0';
          case 5:
            return formatCurrency(
              data?.offeringInvestmentDetails?.data?.totalIndicatedInterest ||
                0,
              0
            );
          case 6:
            return formatCurrency(
              data?.offeringInvestmentDetails?.data?.totalAmountSigned || 0,
              0
            );
          case 7:
            return `${formatCurrency(
              data?.offeringInvestmentDetails?.data
                ?.totalCompletedInvestmentAmount || 0,
              0
            )} (${formatCurrency(
              data?.offeringInvestmentDetails?.data
                ?.investmentsPostedTransactionAmount || 0,
              0
            )})`;
        }
      }
    }
    return cell;
  });

  return <TableRow {...props} />;
};

export default OfferingRow;
