import { Button, Modal } from '@equitymultiple/react-eui';
import React from 'react';

import { useDeletePostMutation } from '../../../../__generated__';
import * as modalStyles from '../../../../styles/components/Modal.module.scss';
import { deleteObjectFromCache } from '../../../../utils/apolloCacheHelpers';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import * as postStyles from '../../../Posts/posts.module.scss';

const deletePostMessages = {
  error: 'An error occurred while attempting to delete the post',
  loading: 'Deleting',
  success: 'Post deleted'
};

export interface Props {
  handleCloseModal: () => void;
  postData: null | {
    offeringTitle: string;
    postId: string;
    postTitle: string;
  };
}

const DeletePostModal: React.FC<Props> = ({ handleCloseModal, postData }) => {
  const postId = postData?.postId as string;
  const [deletePostMutation, deletePostMutationState] = useDeletePostMutation({
    update(cache) {
      deleteObjectFromCache(cache, 'Post', postId);
    },
    variables: {
      postId
    }
  });

  const handleDeletePost = () => {
    callMutationWithToastMessages(deletePostMutation, deletePostMessages).then(
      () => {
        handleCloseModal();
        deletePostMutationState.reset();
      }
    );
  };

  return (
    <Modal
      closeButtonText="Cancel"
      data-testid="deletePostModal"
      onClose={handleCloseModal}
      open={postData !== null}
      submitButton={
        <Button
          loading={deletePostMutationState.loading}
          onClick={handleDeletePost}
          variant="orange"
        >
          Yes, Delete It
        </Button>
      }
      title={<h6>Are you sure you want to delete this post?</h6>}
    >
      <div className={modalStyles.contentContainer}>
        <div className={modalStyles.contentItem}>
          <p className={postStyles.postModalTitle} data-testid="postTitle">
            {postData?.postTitle}
          </p>
          <p
            className={postStyles.postModalOffering}
            data-testid="postOffering"
          >
            {postData?.offeringTitle}
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default DeletePostModal;
