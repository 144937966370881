import * as yup from 'yup';

import { yupNumber, yupString } from '../../../utils/validations';

export const wireAndCheckInfoSchema = yup.object().shape({
  account: yupNumber
    .required('Account number is required')
    .typeError('Account number is required and must be a number'),
  accountName: yupString.required('Account name is required'),
  address: yupString.required('Address is required'),
  bankAddress: yupString.required('Bank address is required'),
  bankName: yupString.required('Bank name is required'),
  payeeName: yupString.required('Payee name is required'),
  wireRouting: yupString
    .required('Routing number is required')
    .matches(/[0-9]+/gi, 'Routing number is required and must be a number')
    .length(9, 'Routing number must be 9 digits')
});

export const bankAccountSchema = yup.object().shape({
  dwollaCustomerId: yupString.required('Customer is required'),
  dwollaFundingSourceId: yupString.required('Bank account is required')
});
