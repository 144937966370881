import { Button, Card, FileUploader, Input } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

// import {
//   CreateSponsorInput,
//   EditSponsorInput,
// } from '../../../__generated__/index';
import { sponsorSchema } from './validation';

// type FormValues = CreateSponsorInput | EditSponsorInput;
// eslint-disable-next-line
type FormValues = Record<string, any>;

export interface Props {
  defaultValues?: DefaultValues<FormValues>;
  loading: boolean;
  onSubmit(submitData: FormValues): unknown;
}

const SponsorForm: React.FC<Props> = ({ defaultValues, loading, onSubmit }) => {
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    reset
  } = useForm<DefaultValues<FormValues>>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(sponsorSchema)
  });

  const formValues = getValues();

  useEffect(() => {
    if (defaultValues && !formValues?.name) {
      reset({ ...defaultValues } as DefaultValues<FormValues>);
    }
  }, [defaultValues, reset, formValues?.name]);

  return (
    <>
      <Card className={`contentNarrow firstChildMarginTop0`}>
        <form data-testid="sponsorForm" onSubmit={handleSubmit(onSubmit)}>
          <Col lg={4} md={6}>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <Input
                  {...field}
                  data-testid="sponsorName"
                  errorMessage={errors.name?.message as string}
                  id="sponsorName"
                  label="Sponsor Name"
                  value={field.value || ''}
                />
              )}
            />
          </Col>
          <p>Sponsor Logo</p>
          <Controller
            control={control}
            name="logo"
            render={({ field }) => (
              <FileUploader
                {...field}
                acceptedFileTypes={['JPG', 'PNG']}
                errorMessage={errors.logo?.message as string}
                existingFile={
                  field.value?.url
                    ? {
                        ...field.value,
                        name: field.value.url.split('/').pop()
                      }
                    : field.value
                }
                onRemove={() => field.onChange(null)}
                showImage
                upload={(uploadData: File) => field.onChange(uploadData)}
              />
            )}
          />
          <Row>
            <Col className="alignItemsCenter" xs={8}>
              <Link className="textLink underline" to="/sponsors" type="button">
                Cancel
              </Link>
            </Col>
            <Col xs={4}>
              <Button className="floatRight" loading={loading} type="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </form>
      </Card>
    </>
  );
};

export default SponsorForm;
