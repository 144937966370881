import {
  PaymentMethod,
  TransactionEventTypeCode,
  TransactionStatus,
  TransactionTypeCode
} from '../../../__generated__';

export const transactionStatuses = {
  [TransactionStatus.Cancelled]: 'Cancelled',
  [TransactionStatus.Draft]: 'Draft',
  [TransactionStatus.Failed]: 'Failed',
  [TransactionStatus.Pending]: 'Pending',
  [TransactionStatus.Posted]: 'Posted'
};

export const paymentMethods = {
  [PaymentMethod.Ach]: 'ACH',
  [PaymentMethod.Check]: 'CHECK',
  [PaymentMethod.Custom]: 'CUSTOM',
  [PaymentMethod.Redemption]: 'REDEMPTION',
  [PaymentMethod.Rollover]: 'ROLLOVER',
  [PaymentMethod.Wire]: 'WIRE'
};

export const eventTypes = {
  [TransactionEventTypeCode.Contribution]: 'Contribution',
  [TransactionEventTypeCode.Distribution]: 'Distribution',
  [TransactionEventTypeCode.Fee]: 'Fee',
  [TransactionEventTypeCode.FundAccount]: 'Fund Account',
  [TransactionEventTypeCode.WithdrawAccount]: 'Withdraw Account'
};

export const transactionTypes = {
  [TransactionTypeCode.AccruedInterest]: 'Accrued Interest',
  [TransactionTypeCode.AdditionalInterest]: 'Additional Interest',
  [TransactionTypeCode.AdministrativeExpenseReimbursement]:
    'Administrative Expense Reimbursement',
  [TransactionTypeCode.AssetManagementFee]: 'Asset Management Fee',
  [TransactionTypeCode.CapitalCall]: 'Capital Call',
  [TransactionTypeCode.CapitalCorrection]: 'Capital Correction',
  [TransactionTypeCode.Dividend]: 'Dividend',
  [TransactionTypeCode.DividendReinvestment]: 'Dividend Reinvestment',
  [TransactionTypeCode.EarningsCorrection]: 'Earnings Correction',
  [TransactionTypeCode.FundAccount]: 'Fund Account',
  [TransactionTypeCode.InterestEarned]: 'Interest Earned',
  [TransactionTypeCode.InterestParticipation]: 'Interest Participation',
  [TransactionTypeCode.Investment]: 'Investment',
  [TransactionTypeCode.OriginationFee]: 'Origination Fee',
  [TransactionTypeCode.PeakEquity]: 'Peak Equity',
  [TransactionTypeCode.PrincipalPayment]: 'Principal Payment',
  [TransactionTypeCode.ProfitParticipation]: 'Profit Participation',
  [TransactionTypeCode.ReferralBonus]: 'Referral Bonus',
  [TransactionTypeCode.Refund]: 'Refund',
  [TransactionTypeCode.ReturnOfCapital]: 'Return of Capital',
  [TransactionTypeCode.WithdrawAccount]: 'Withdraw Account'
};
