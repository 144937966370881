import { Button, Modal } from '@equitymultiple/react-eui';
import { useDeleteDocumentsMutation, UserDocument } from '__generated__';
import React from 'react';
import { deleteObjectFromCache } from 'utils/apolloCacheHelpers';
import { snakeToTitleCase } from 'utils/stringFormatting';

import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';

export interface Props {
  selectedDocument: null | UserDocument;
  setSelectedDocumentState: (document: null | UserDocument) => void;
}

const messages = {
  error: 'An error occurred while deleting the document',
  loading: 'Deleting',
  success: 'Document deleted'
};

const DeleteDocumentModal: React.FC<Props> = ({
  selectedDocument,
  setSelectedDocumentState
}) => {
  const documentId = selectedDocument?.id as string;
  const [deleteDocumentsMutation, deleteDocumentsMutationState] =
    useDeleteDocumentsMutation({
      update(cache) {
        deleteObjectFromCache(cache, 'UserDocument', documentId);
      },
      variables: {
        documentId
      }
    });

  const handleDeleteDocument = () => {
    callMutationWithToastMessages(deleteDocumentsMutation, messages).then(
      () => {
        setSelectedDocumentState(null);
        deleteDocumentsMutationState.reset();
      }
    );
  };

  const hideDeleteModal = () => setSelectedDocumentState(null);

  return (
    <Modal
      closeButtonText="Cancel"
      data-testid="deleteDocumentModal"
      onClose={hideDeleteModal}
      open={selectedDocument !== null}
      submitButton={
        <Button onClick={() => handleDeleteDocument()} variant="orange">
          Yes, Delete It
        </Button>
      }
      title={<h6>Are you sure you want to delete this document?</h6>}
    >
      <div className="margin30">
        <div data-testid="deleteModalDocumentName">
          <strong>Name:</strong> {selectedDocument?.filename || '-'}
        </div>
        <div data-testid="deleteModalDocumentType">
          <strong>Type:</strong>{' '}
          {selectedDocument?.type && snakeToTitleCase(selectedDocument.type)}
        </div>
      </div>
    </Modal>
  );
};

export default DeleteDocumentModal;
