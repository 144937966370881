import { EMLoadingIcon } from '@equitymultiple/react-eui';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  EditPostAuthorInput,
  useEditPostAuthorMutation,
  useGetPostAuthorQuery
} from '../../../../__generated__';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import PostAuthorForm from '../PostAuthorForm/PostAuthorForm';
import { postAuthorSchema } from '../PostAuthorForm/validation';

const messages = {
  error: 'An error occurred while updating the post author',
  loading: 'Updating',
  success: 'Post author updated'
};

const EditPostAuthor: React.FC = () => {
  const postAuthorId = useParams().id as string;

  const { data, loading } = useGetPostAuthorQuery({
    fetchPolicy: 'no-cache',
    variables: { postAuthorId }
  });

  const postAuthor = data?.postAuthor.postAuthor;

  const [editPostAuthorMutation, editPostAuthorMutationState] =
    useEditPostAuthorMutation();

  const submitting = editPostAuthorMutationState.loading;

  const navigate = useNavigate();

  const defaultValues = {
    avatar: { url: postAuthor?.avatar?.url },
    email: postAuthor?.email as string,
    firstName: postAuthor?.firstName as string,
    lastName: postAuthor?.lastName as string,
    title: postAuthor?.title as string
  };

  const onSubmit = async (submitData: EditPostAuthorInput) => {
    const isValid = await postAuthorSchema.isValid(submitData);

    if (isValid) {
      const logoUpdated = submitData.avatar instanceof File;
      if (!logoUpdated) {
        delete submitData.avatar;
      }
      const mutationOptions: EditPostAuthorInput = {
        ...submitData,
        id: postAuthorId
      };

      await callMutationWithToastMessages(editPostAuthorMutation, messages, {
        variables: {
          postAuthor: mutationOptions
        }
      }).then(() => {
        navigate('/posts#post_authors');
      });
    }
  };

  return loading ? (
    <EMLoadingIcon data-testid="emLoadingIcon" />
  ) : (
    <>
      <h2 className="contentNarrow">Edit Post Author</h2>

      <PostAuthorForm
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        submitting={submitting}
      />
    </>
  );
};

export default EditPostAuthor;
