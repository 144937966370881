import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CreatePostInput,
  namedOperations,
  PostLabel,
  useCreatePostMutation
} from '../../../../__generated__';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import PostForm from '../PostForm/PostForm';
import { postSchema } from '../PostForm/validation';

const createPostMessages = {
  error: 'An error occurred while adding a post',
  loading: 'Adding post',
  success: 'Post added'
};

const defaultValues = { label: [PostLabel.InvestmentUpdate] };

const AddPost: React.FC = () => {
  const [createPost, createPostState] = useCreatePostMutation();

  const loading = createPostState.loading;

  const navigate = useNavigate();

  const onSubmit = async (submitData: CreatePostInput) => {
    const isValid = await postSchema.isValid(submitData);

    if (isValid) {
      const { data } = await callMutationWithToastMessages(
        createPost,
        createPostMessages,
        {
          refetchQueries: [namedOperations.Query.getPosts],
          variables: {
            post: submitData
          }
        }
      );
      const post = data?.createPost.post;

      if (post) {
        navigate('/posts');
      }
    }
  };

  return (
    <>
      <h2 className="contentNarrow">Add Post</h2>
      <PostForm
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        submitting={loading}
      />
    </>
  );
};

export default AddPost;
