import { ToggleableTooltip } from '@equitymultiple/react-eui';
import React from 'react';
import { Link } from 'react-router-dom';

import { UserDocument } from '../../../../__generated__';

export interface Props {
  document: null | UserDocument;
  setSelectedDocument: (document: null | UserDocument) => void;
}

const DocumentActions: React.FC<Props> = ({
  document,
  setSelectedDocument
}) => {
  const { id } = document;

  return (
    <div data-testid={`kebab-${document?.id}`}>
      <ToggleableTooltip placement="leftStart">
        <Link
          data-testid="pdfLink"
          target={'_blank'}
          to={`/documents/${id}/download`}
        >
          Download
        </Link>
        <button
          className="textLink underline"
          onClick={() => setSelectedDocument(document)}
          type="button"
        >
          Delete
        </button>
      </ToggleableTooltip>
    </div>
  );
};

export default DocumentActions;
