import { Button, Input, Table } from '@equitymultiple/react-eui';
import useDebounce from 'hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useNavigate } from 'react-router-dom';

import {
  DwollaCustomer,
  useGetDwollaCustomersQuery
} from '../../../__generated__';
import AccountActions from './AdminAccountActions';
import * as styles from './AdminAccounts.module.scss';

const columnHeaders = [
  'ID',
  'Status',
  'Business Name',
  'Email',
  'Verified Bank Accounts',
  'Actions'
];

const getAccountStatus = (account: DwollaCustomer) => {
  if (account.status === 'verified')
    return <div className="textGreen">Verified</div>;
  else return <div className="textRed">Suspended</div>;
};

const getRows = (accounts: (DwollaCustomer | null | undefined)[]) =>
  accounts.map((account: DwollaCustomer | null | undefined) => ({
    cells: account
      ? [
          account.id,
          getAccountStatus(account),
          account.businessName,
          account.email,
          account.bankAccountsCount,
          <AccountActions dwollaCustomer={account} key={account.id} />
        ]
      : []
  }));

const AdminAccounts: React.FC = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue: string = useDebounce<string>(searchValue, 300);

  const { data, fetchMore, loading, refetch } = useGetDwollaCustomersQuery({
    variables: {
      filter: debouncedSearchValue,
      pagination: {
        page,
        pageSize: rowsPerPage
      }
    }
  });

  useEffect(() => {
    if (debouncedSearchValue) refetch();
  }, [debouncedSearchValue, refetch]);

  const fetchMoreAccounts = (pageNumber: number, pageSize: number) => {
    fetchMore({
      variables: {
        pagination: {
          page: pageNumber,
          pageSize
        }
      }
    });
  };

  const handlePageChange = (
    _: null | React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => {
    const pageNumber = newPage + 1;
    setPage(pageNumber);
    fetchMoreAccounts(pageNumber, rowsPerPage);
  };

  const handleRowsPerPageChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    const pageSize = parseInt(event.currentTarget.value);
    setRowsPerPage(pageSize);
    setPage(1);
    fetchMoreAccounts(1, pageSize);
  };

  const handleSearchValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(1);
    setSearchValue(event.target.value);
  };

  const navigate = useNavigate();

  const handleNewAccount = () => {
    navigate('/accounts/new');
  };

  const hasAccounts =
    data?.dwollaCustomers?.data && data.dwollaCustomers.data.length > 0;

  return (
    <>
      <h2 data-testid="headingAdmin">Admin Accounts</h2>
      <Row>
        <Col lg={4} md={6}>
          <Input
            id="adminAccountSearch"
            label="Search"
            onChange={handleSearchValueChange}
            placeholder="Account ID, entity name, or email"
            value={searchValue}
          />
        </Col>
        <Col
          className={`alignItemsCenter justifyContentEnd ${styles.newAccountButton}`}
          lg={8}
          md={6}
        >
          <Button className="alignItemsCenter" onClick={handleNewAccount}>
            New Account
          </Button>
        </Col>
      </Row>

      <Table
        columnHeaders={columnHeaders}
        data-testid="table"
        loading={loading}
        loadingRows={10}
        pagination={{
          count: data?.dwollaCustomers?.pageInfo?.count || 0,
          onPageChange: handlePageChange,
          onRowsPerPageChange: handleRowsPerPageChange,
          // MUI expects 0 based index, API expects 1 based
          // MUI throws out of range error if page > 1 and there are no accounts
          page: hasAccounts ? page - 1 : 0,
          rowsPerPage
        }}
        rows={
          hasAccounts
            ? getRows(data?.dwollaCustomers?.data as DwollaCustomer[])
            : [{ cells: ['No accounts found'] }]
        }
      />
    </>
  );
};

export default AdminAccounts;
