import { DateTimePicker, Input, Select } from '@equitymultiple/react-eui';
// eslint-disable-next-line import/no-unresolved
import Option from '@equitymultiple/react-eui/dist/types/Select';
import moment, { Moment as MomentType } from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValue,
  UseWatchProps
} from 'react-hook-form';

import {
  Offering,
  OfferingInput,
  OfferingType,
  useGetManagersQuery
} from '../../../__generated__';
import { setFieldProps } from '../../../utils/formHelpers';
import {
  assetClassOptions,
  assetManagementOfferingTypeOptions,
  entityTypeOptions,
  equitySubtypeOptions,
  otherSubtypeOptions,
  restrictedToOptions
} from './constants';

interface Props {
  control: Control<OfferingInput>;
  errors: FieldErrors<OfferingInput>;
  offering: Offering;
  useWatch: (args: UseWatchProps<OfferingInput>) => FieldValue<OfferingInput>;
}

const getSubtypeOptions = (offeringType: null | OfferingType | undefined) => {
  if (offeringType) {
    if (
      ['equity', 'preferred_equity', 'senior_equity'].includes(offeringType)
    ) {
      return equitySubtypeOptions;
    } else {
      return otherSubtypeOptions;
    }
  }
  return [];
};

const AssetManagementFields: React.FC<Props> = ({
  control,
  errors,
  offering,
  useWatch
}) => {
  const [assetManagerOptions, setAssetManagerOptions] = useState<Option[]>([]);

  const { data, loading } = useGetManagersQuery();

  const offeringType = useWatch({
    control,
    name: 'offeringType'
  }) as OfferingType;

  useEffect(() => {
    if (data && data?.managers?.data) {
      const newManagerOptions = data?.managers?.data.map(manager => ({
        label: manager.name as string,
        value: manager.id as string
      }));

      setAssetManagerOptions(newManagerOptions);
    }
  }, [data]);

  return (
    <>
      <hr />
      <h4>Asset Management Details</h4>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="emAssetManagerId"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                disabled={loading}
                helperText={loading ? 'Loading asset managers...' : ''}
                helperTextAlwaysVisible
                label="Asset Manager"
                onChange={e => {
                  field.onChange(e);
                }}
                options={assetManagerOptions}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="entityName"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Entity Name" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="entityType"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Entity Type"
                onChange={e => field.onChange(e)}
                options={entityTypeOptions}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Type"
                onChange={e => field.onChange(e)}
                options={assetManagementOfferingTypeOptions}
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="assetClass"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Asset Class"
                onChange={e => field.onChange(e)}
                options={assetClassOptions}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="subtype"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Subtype"
                onChange={e => field.onChange(e)}
                options={getSubtypeOptions(offeringType)}
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="restrictedTo"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Restricted To"
                onChange={e => field.onChange(e)}
                options={restrictedToOptions}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Input
            disabled
            id="nextPaymentOn"
            label="Next Payment On"
            name="nextPaymentOn"
            value={
              offering.nextPaymentOn
                ? moment(new Date(offering.nextPaymentOn)).format('MM/DD/YYYY')
                : ''
            }
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="closedOn"
            render={({ field }) => (
              // @ts-expect-error incorrect warning
              <DateTimePicker
                {...setFieldProps(field, errors)}
                label="Closed On"
                onChange={date =>
                  date
                    ? field.onChange((date as MomentType).toISOString())
                    : field.onChange('')
                }
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Input
            disabled
            id="maturesOn"
            label="Matures On"
            name="maturesOn"
            value={
              offering.maturesOn
                ? moment(new Date(offering.maturesOn)).format('MM/DD/YYYY')
                : ''
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default AssetManagementFields;
