import { Card, Checkbox, Select, Table } from '@equitymultiple/react-eui';
import moment from 'moment';
import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { toast } from 'react-hot-toast';

import {
  OfferingTaxStatus,
  OfferingTaxStatusEstimateStatus,
  OfferingTaxStatusStep,
  useGetOfferingTaxStatusesByYearQuery
} from '../../__generated__';
import cloneObject from '../../utils/cloneObject';
import {
  estimateOptions,
  offeringTaxStatusDocumentType,
  offeringTaxStatusStatus,
  offeringTaxStatusStep,
  OfferingTaxStatusStepKey,
  stepOptions
} from './constants';
import EditEstimatedCompletionDateModal, {
  EditEstimatedCompletionDateModalInput
} from './EditEstimatedCompletionDateModal/EditEstimatedCompletionDateModal';
import EditOfferingTaxStatusModal, {
  EditOfferingTaxStatusModalInput
} from './EditOfferingTaxStatusModal/EditOfferingTaxStatusModal';
import FlagUnderReviewModal, {
  FlagUnderReviewModalInput
} from './FlagUnderReviewModal/FlagUnderReviewModal';
import * as styles from './taxTracker.module.scss';

type FilteredOfferingTaxStatuses = Record<
  'all' | OfferingTaxStatusStepKey,
  OfferingTaxStatus[]
>;

/* eslint-disable perfectionist/sort-objects */
const baseOfferingTaxStatuses: FilteredOfferingTaxStatuses = {
  all: [],
  initial_data_preparation: [],
  tax_document_requested: [],
  tax_document_received_and_under_review: [],
  preparing_investor_tax_document: [],
  tax_document_available: []
};
/* eslint-enable */

const columnHeaders = [
  'Entity Name',
  'Offering Name',
  'Sponsor',
  'Document Type',
  'Status',
  'Step',
  'Estimate Status',
  'Estimated Completion Date'
];

const currentTaxYear = moment().year() - 1;
const minYear = 2015;
const yearOptions: { label: string; value: string }[] = [];
for (let year = currentTaxYear; year >= minYear; year--) {
  yearOptions.push({ label: year.toString(), value: year.toString() });
}

const TaxTracker: React.FC = () => {
  const [editOfferingTaxStatusModalState, setEditOfferingTaxStatusModalState] =
    useState<EditOfferingTaxStatusModalInput>();
  const [step, setStep] = useState<'all' | keyof typeof offeringTaxStatusStep>(
    'all'
  );
  const [flagUnderReviewModalState, setFlagUnderReviewModalState] =
    useState<FlagUnderReviewModalInput>();
  const [taxYear, setTaxYear] = useState(currentTaxYear.toString());
  const [
    editEstimatedCompletionDateModalState,
    setEditEstimatedCompletionDateModalState
  ] = useState<EditEstimatedCompletionDateModalInput>();

  let offeringTaxStatuses = baseOfferingTaxStatuses;

  const { data, loading } = useGetOfferingTaxStatusesByYearQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { taxYear }
  });

  if (!loading && data) {
    const newOfferingTaxStatuses = cloneObject(baseOfferingTaxStatuses);
    newOfferingTaxStatuses.all = data?.offeringTaxStatuses.data || [];

    newOfferingTaxStatuses.all.forEach(offeringTaxStatus => {
      newOfferingTaxStatuses[
        offeringTaxStatus.step as OfferingTaxStatusStepKey
      ].push(offeringTaxStatus);
    });

    offeringTaxStatuses = newOfferingTaxStatuses;
  }

  const hasOfferingTaxStatuses = offeringTaxStatuses[step].length;

  const handleStepChange = (
    newStep: OfferingTaxStatusStep,
    offeringTaxStatus: OfferingTaxStatus
  ) => {
    const newStepIndex = stepOptions.findIndex(
      ({ value }) => newStep === value
    );
    const currentStepIndex = stepOptions.findIndex(
      ({ value }) => offeringTaxStatus.step === value
    );

    switch (newStepIndex - currentStepIndex) {
      case 0:
        break;
      case 1:
      case -1:
        setEditOfferingTaxStatusModalState({ newStep, offeringTaxStatus });
        break;
      default:
        toast.error('Step must be updated in sequential order');
        break;
    }
  };

  const handleEstimateChange = (
    newEstimate: OfferingTaxStatusEstimateStatus,
    offeringTaxStatus: OfferingTaxStatus
  ) => {
    if (
      newEstimate !== OfferingTaxStatusEstimateStatus.EstimateUploaded &&
      !!offeringTaxStatus.estimateStatus
    ) {
      toast.error('This estimate can only be set to Estimate Uploaded');
    } else {
      setEditOfferingTaxStatusModalState({
        newEstimate,
        offeringTaxStatus
      });
    }
  };

  const handleEstimatedCompletionDateClick = (
    offeringTaxStatus: OfferingTaxStatus,
    remove = false
  ) => {
    setEditEstimatedCompletionDateModalState({ offeringTaxStatus, remove });
  };

  const handleFlagUnderReview = (
    newUnderReview: boolean,
    offeringTaxStatus: OfferingTaxStatus
  ) => setFlagUnderReviewModalState({ newUnderReview, offeringTaxStatus });
  const getRows = () =>
    offeringTaxStatuses[step].map(offeringTaxStatus => {
      const {
        documentType,
        entityName,
        estimatedCompletionDate,
        estimateStatus,
        id,
        offeringTitle,
        sponsorName,
        status,
        step: itemStep,
        stepLastUpdatedBy,
        stepLastUpdatedOn,
        underReview
      } = offeringTaxStatus;

      return {
        cells: [
          entityName,
          offeringTitle,
          sponsorName,
          offeringTaxStatusDocumentType[
            documentType as keyof typeof offeringTaxStatusDocumentType
          ],
          offeringTaxStatusStatus[
            status as keyof typeof offeringTaxStatusStatus
          ],
          <Col key={'select-offering-step-' + id}>
            <Select
              className={styles.stepSelect}
              id={'select-offering-step-' + id}
              onChange={value =>
                handleStepChange(
                  value as OfferingTaxStatusStep,
                  offeringTaxStatus
                )
              }
              options={stepOptions}
              value={itemStep}
            />
            <Checkbox
              checked={underReview ?? false}
              id={'check-under-review-' + id}
              label={'Under Review'}
              onChange={event =>
                handleFlagUnderReview(!!event.target.checked, offeringTaxStatus)
              }
            />
            {stepLastUpdatedOn && (
              <div className={styles.stepLastUpdatedOn}>
                <b>Last Changed On: </b>
                {moment(stepLastUpdatedOn).format('MM/DD/YYYY HH:MM:SS')}
              </div>
            )}
            {stepLastUpdatedBy && (
              <div className={styles.stepLastUpdatedBy}>
                <b>Last Changed By: </b>
                {stepLastUpdatedBy}
              </div>
            )}
          </Col>,
          <Select
            className={styles.estimateSelect}
            disabled={
              estimateStatus ===
              OfferingTaxStatusEstimateStatus.EstimateUploaded
            }
            id={'select-offering-estimate-' + id}
            key={'select-offering-estimate-' + id}
            onChange={value =>
              handleEstimateChange(
                value as OfferingTaxStatusEstimateStatus,
                offeringTaxStatus
              )
            }
            options={estimateOptions}
            value={estimateStatus || ''}
          />,
          <div
            data-testid={'estimated-completion-date-' + id}
            key={'estimated-completion-date-' + id}
          >
            <div>
              {estimatedCompletionDate && (
                <div>
                  {moment(estimatedCompletionDate).format('MM/DD/YYYY')}
                </div>
              )}
              <button
                className="textLink underline"
                onClick={() =>
                  handleEstimatedCompletionDateClick(offeringTaxStatus)
                }
                type="button"
              >
                {estimatedCompletionDate ? 'Edit' : 'Add'}
              </button>
              {estimatedCompletionDate && (
                <>
                  <br />
                  <button
                    className="textLink underline"
                    onClick={() =>
                      handleEstimatedCompletionDateClick(
                        offeringTaxStatus,
                        true
                      )
                    }
                    type="button"
                  >
                    Remove
                  </button>
                </>
              )}
            </div>
          </div>
        ]
      };
    });

  const stepFilterOptions = Object.entries(offeringTaxStatuses).map(
    ([key, value]) => ({
      label: `${
        key === 'all'
          ? 'All'
          : offeringTaxStatusStep[key as OfferingTaxStatusStepKey]
      } (${value.length})`,
      value: key
    })
  );

  return (
    <>
      <FlagUnderReviewModal
        handleCloseModal={() => setFlagUnderReviewModalState(undefined)}
        params={flagUnderReviewModalState}
      />
      <EditOfferingTaxStatusModal
        handleCloseModal={() => setEditOfferingTaxStatusModalState(undefined)}
        params={editOfferingTaxStatusModalState}
      />
      <EditEstimatedCompletionDateModal
        handleCloseModal={() =>
          setEditEstimatedCompletionDateModalState(undefined)
        }
        params={editEstimatedCompletionDateModalState}
      />
      <h1 data-testid="taxTrackerHeading">Tax Tracker</h1>
      <Card>
        <Row>
          <Col lg={4} md={6}>
            <Select
              disabled={loading}
              id="step-select"
              label="Step"
              onChange={value =>
                setStep(value as 'all' | OfferingTaxStatusStepKey)
              }
              options={stepFilterOptions || []}
              value={step}
            />
          </Col>
          <Col lg={4} md={6}>
            <Select
              disabled={loading}
              id="year-select"
              label="Tax Year"
              onChange={value => setTaxYear(value as string)}
              options={yearOptions || []}
              value={taxYear}
            />
          </Col>
        </Row>
        <Table
          allowSorting={[0, 1, 2, 3, 4, 5]}
          className={styles.table}
          columnHeaders={columnHeaders}
          loading={loading}
          loadingRows={10}
          rows={
            hasOfferingTaxStatuses
              ? getRows()
              : [{ cells: ['No offering tax statuses found'] }]
          }
        />
      </Card>
    </>
  );
};

export default TaxTracker;
