import { Card, Table } from '@equitymultiple/react-eui';
import {
  Closing,
  Document,
  DocumentType,
  UploadOfferingDocumentInput,
  useDeleteDocumentsMutation,
  useGetClosingsByOfferingIdQuery,
  useGetOfferingDocumentsQuery,
  useRestorePreviousDocumentsMutation,
  useUploadOfferingDocumentMutation
} from '__generated__';
import DocumentsActions from 'components/DocumentsActions/DocumentsActions';
import {
  DocumentSubtype,
  DocumentTypeDbValue,
  OfferingDocumentGrouping,
  OfferingDocumentTypeOptions
} from 'constants/documents';
import moment from 'moment';
import React, { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import callMutationWithToastMessages from 'utils/callMutationWithToastMessages';
import OfferingDocumentForm from 'views/Offerings/OfferingDocuments/OfferingDocumentForm';

import { formatClosingName } from '../helpers';
import * as styles from './OfferingDocuments.module.scss';

const columnHeaders = [
  'File Name',
  'Type',
  'Document Type',
  'Associate To',
  'File Count',
  'Uploaded By',
  'Group Document',
  'Closing',
  'Actions'
];

const uploadMessages = {
  error: 'An error occurred while attempting to upload the document',
  loading: 'Uploading offering document',
  success: 'Document added'
};

const deleteMessages = {
  error: 'An error occurred while deleting the documents',
  loading: 'Deleting',
  success: 'Documents deleted'
};

const restoreMessages = {
  error: 'An error occurred while restoring the documents',
  loading: 'Restoring',
  success: 'Documents restored'
};

const getClosingLabel = (closings: Closing[], closingId: string) => {
  const selectedClosing = closings?.find(closing => closing.id === closingId);
  return selectedClosing ? formatClosingName(selectedClosing) : 'All Closings';
};

const OfferingDocuments = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [deleteDocumentsMutation] = useDeleteDocumentsMutation();
  const [restorePreviousDocumentsMutation] =
    useRestorePreviousDocumentsMutation();

  const {
    data: documentsData,
    error: documentsError,
    fetchMore,
    loading: documentsLoading
  } = useGetOfferingDocumentsQuery({
    variables: {
      id
    }
  });

  const {
    data: closingsData,
    error: closingsError,
    loading: closingsLoading
  } = useGetClosingsByOfferingIdQuery({
    variables: {
      offeringId: id
    }
  });

  const [uploadOfferingDocument, uploadOfferingDocumentState] =
    useUploadOfferingDocumentMutation();
  const uploading = uploadOfferingDocumentState.loading;

  const offeringDocuments = documentsData?.offeringDocuments?.data;
  const closings = closingsData?.closings?.closings;
  const offering = closingsData?.offering?.offering;

  const loading = closingsLoading || documentsLoading || uploading;

  const hasError = !!(
    closingsError ||
    closingsData?.closings?.error ||
    documentsError ||
    documentsData?.offeringDocuments?.error
  );

  useEffect(() => {
    if (hasError) {
      toast.error('An error occurred while loading offering documents');
      navigate('/');
    }
  }, [hasError, navigate]);

  const onSubmit = async (data: UploadOfferingDocumentInput) => {
    data.offeringId = id;

    await callMutationWithToastMessages(
      uploadOfferingDocument,
      uploadMessages,
      {
        variables: {
          input: data
        }
      }
    );
    fetchMore({
      variables: {
        id
      }
    });
    return true;
  };

  const handleDeleteDocuments = (document: Document) => {
    callMutationWithToastMessages(deleteDocumentsMutation, deleteMessages, {
      variables: {
        documentId: document.id
      }
    }).then(() => {
      fetchMore({
        variables: {
          id
        }
      });
    });
  };

  const handleRestorePreviousDocuments = (document: Document) => {
    callMutationWithToastMessages(
      restorePreviousDocumentsMutation,
      restoreMessages,
      {
        variables: {
          documentId: document.id
        }
      }
    ).then(() => {
      fetchMore({
        variables: {
          id
        }
      });
    });
  };

  const defaultValues = {
    attachment: null,
    groupDocument: OfferingDocumentGrouping.Individual,
    type: DocumentType.TaxDocument
  };

  const hasDocuments = offeringDocuments && offeringDocuments.length > 0;

  const getRows = (documents: Array<Document>) => {
    return documents.map(document => {
      const { createdAt, filename, meta, type, uploader } = document;

      return {
        cells: [
          filename,
          DocumentSubtype[type],
          (meta?.documentType ? DocumentTypeDbValue[meta?.documentType] : '') +
            (meta?.taxYear ? ` [${meta.taxYear}]` : ''),
          meta?.associate,
          meta?.files,
          <>
            <div>{uploader?.split('@')[0]}</div>
            <div>
              {moment
                .utc(new Date(createdAt))
                .local()
                .format('MM/DD/YYYY hh:mm:ss A')}
            </div>
          </>,
          meta.groupDocument ? 'Yes' : 'No',
          meta.groupDocument && closings
            ? getClosingLabel(closings, meta.closingId)
            : 'N/A',
          <DocumentsActions
            deleteDocuments={handleDeleteDocuments}
            document={document}
            key={document.id}
            restorePreviousDocuments={handleRestorePreviousDocuments}
          />
        ]
      };
    });
  };

  return (
    <>
      <h2>
        {loading ? (
          <Skeleton style={{ maxWidth: '100%' }} width={500} />
        ) : (
          `Documents in ${offering?.title}`
        )}
      </h2>
      <Card>
        <OfferingDocumentForm
          closings={closings}
          defaultValues={defaultValues}
          documentTypeOptions={OfferingDocumentTypeOptions}
          loading={loading}
          onSubmit={onSubmit}
        />
        <h4>Showing only up to 5 of the most recent Documents</h4>
        <p data-testid="offeringDocumentsTableHelperText">
          Use{' '}
          {<Link to="/configuration/documents">Documents Configuration</Link>}{' '}
          for further document management
        </p>
        <Table
          className={styles.offeringDocumentsTable}
          columnHeaders={columnHeaders}
          loading={loading}
          loadingRows={5}
          rows={
            hasDocuments
              ? getRows(offeringDocuments)
              : [{ cells: ['No Documents found'] }]
          }
        />
      </Card>
    </>
  );
};

export default OfferingDocuments;
