import { DocumentType } from '__generated__';
import ObjType from 'types/ObjType';
import SelectOption from 'types/SelectOption';

export const DocumentTypeOptions: SelectOption[] = [
  {
    label: 'Tax Documents',
    value: DocumentType.TaxDocument
  },
  {
    label: 'Account Documents',
    value: DocumentType.AccountDocument
  },
  {
    label: 'Investment Documents',
    value: DocumentType.InvestmentDocument
  }
];

export const AttachmentLabel: ObjType = {
  account_document:
    'Account Documents require that documents are pre-pended with the ID of the associated user or investment account.',
  investment_document:
    'Investment Documents require that documents are pre-pended with the ID of the associated investment record.',
  tax_document:
    'Tax Documents require that documents are pre-pended with the ID of the associated investment record.'
};

const generateTaxYearOptions = (baseYear: number): Array<number> => {
  const now = new Date().getUTCFullYear();
  return Array(now - (baseYear - 1))
    .fill('')
    .map((_, index) => now - index) as Array<number>;
};

interface TaxYearOption {
  label: string;
  value: string;
}

export const TaxYearOptions: TaxYearOption[] = generateTaxYearOptions(2013).map(
  (year: number) => ({ label: year.toString(), value: year.toString() })
);

export const DocumentSubtype: ObjType = {
  account_document: 'Account Document',
  investment_document: 'Investment Document',
  tax_document: 'Tax Document'
};

export const TaxDocumentTypeOptions: SelectOption[] = [
  { label: 'K-1', value: DocumentType.K_1 },
  { label: 'K-1 Estimate', value: DocumentType.K_1Estimate },
  { label: '1099-DIV', value: DocumentType.N1099Div },
  { label: '1099-INT', value: DocumentType.N1099Int },
  { label: '1099-MISC', value: DocumentType.N1099Misc },
  { label: 'State K-1', value: DocumentType.StateK_1 },
  { label: 'Withholding Form', value: DocumentType.WithholdingForm },
  { label: 'Grantor Trust Letter', value: DocumentType.GrantorTrustLetter }
];

export const AccountDocumentTypeOptions: SelectOption[] = [
  { label: 'Account Statement', value: DocumentType.AccountStatement },
  {
    label: 'Account Transfer Agreement',
    value: DocumentType.AccountTransferAgreement
  },
  {
    label: 'Accreditation Professional Letter',
    value: DocumentType.ProfessionalLetter
  },
  { label: 'Beneficiary Form', value: DocumentType.BeneficiaryForm },
  { label: 'Form CRS', value: DocumentType.Crs },
  { label: 'Power of Attorney Letter', value: DocumentType.PoaAgreement },
  { label: 'Tax Update', value: DocumentType.TaxUpdate }
];

export const InvestmentDocumentTypeOptions: SelectOption[] = [
  { label: 'Audited Financial', value: DocumentType.AuditedFinancial },
  { label: 'Bank Statement', value: DocumentType.BankStatement },
  {
    label: 'Borrower Dependent Note Disclosure',
    value: DocumentType.BorrowerDependentNoteDisclosure
  },
  { label: 'Exit Summary', value: DocumentType.ExitSummary },
  {
    label: 'Investment Update',
    value: DocumentType.InvestmentUpdate
  },
  { label: 'Side Letter', value: DocumentType.SideLetter },
  {
    label: 'Subscription Agreement',
    value: DocumentType.SubscriptionAgreement
  }
];

export const GroupTaxDocumentTypeOptions: SelectOption[] = [
  ...TaxDocumentTypeOptions.filter(option => {
    return option.value === DocumentType.K_1Estimate;
  }),
  { label: 'Tax Loss Letter', value: DocumentType.TaxLossLetter }
];

export const GroupInvestmentDocumentTypeOptions: SelectOption[] =
  InvestmentDocumentTypeOptions.filter(option => {
    return (
      option.value !== DocumentType.SideLetter &&
      option.value !== DocumentType.SubscriptionAgreement
    );
  });

export const documentSubtypeOptions = (
  type: DocumentType | undefined
): SelectOption[] => {
  if (type === DocumentType.AccountDocument) {
    return AccountDocumentTypeOptions;
  } else if (type === DocumentType.InvestmentDocument) {
    return InvestmentDocumentTypeOptions;
  } else {
    return TaxDocumentTypeOptions;
  }
};

export enum OfferingDocumentGrouping {
  Group = 'group',
  Individual = 'individual'
}

export const offeringDocumentSubtypeOptions = (
  type: DocumentType | undefined,
  documentGrouping: OfferingDocumentGrouping
): SelectOption[] => {
  if (documentGrouping === OfferingDocumentGrouping.Group) {
    if (type === DocumentType.InvestmentDocument) {
      return GroupInvestmentDocumentTypeOptions;
    } else {
      return GroupTaxDocumentTypeOptions;
    }
  } else {
    return documentSubtypeOptions(type);
  }
};

export const DocumentTypeDbValue: ObjType = {
  [DocumentType.AccountDocument]: 'Account Document',
  [DocumentType.AccountStatement]: 'Account Statement',
  [DocumentType.AccountTransferAgreement]: 'Account Transfer Agreement',
  [DocumentType.AccreditationLetter]: 'Accreditation Letter',
  [DocumentType.AuditedFinancial]: 'Audited Financial',
  [DocumentType.BankStatement]: 'Bank Statement',
  [DocumentType.BeneficiaryForm]: 'Beneficiary Form',
  [DocumentType.BorrowerDependentNoteDisclosure]:
    'Borrower Dependent Note Disclosure',
  [DocumentType.ConfidentialityAgreement]: 'Confidentiality Agreement',
  [DocumentType.Crs]: 'Form CRS',
  [DocumentType.ExitSummary]: 'Exit Summary',
  [DocumentType.GrantorTrustLetter]: 'Grantor Trust Letter',
  [DocumentType.IdCard]: 'ID Card',
  [DocumentType.Identification]: 'Identification',
  [DocumentType.InvestmentDocument]: 'Investment Document',
  [DocumentType.InvestmentUpdate]: 'Investment Update',
  [DocumentType.K_1]: 'K-1',
  [DocumentType.K_1Estimate]: 'K-1 Estimate',
  [DocumentType.License]: 'License',
  [DocumentType.LlcAgreement]: 'LLC Agreement',
  [DocumentType.Miscellaneous]: 'Miscellaneous',
  [DocumentType.N1099]: '1099',
  [DocumentType.N1099Div]: '1099-DIV',
  [DocumentType.N1099Int]: '1099-INT',
  [DocumentType.N1099Misc]: '099-MISC',
  [DocumentType.Other]: 'Other',
  [DocumentType.Passport]: 'Passport',
  [DocumentType.PoaAgreement]: 'Power of Attorney Letter',
  [DocumentType.ProfessionalLetter]: 'Professional Letter',
  [DocumentType.ReinvestmentAgreement]: 'Reinvestment Agreement',
  [DocumentType.SecondaryIdentification]: 'Secondary Identification',
  [DocumentType.SideLetter]: 'Side Letter',
  [DocumentType.StateK_1]: 'State K-1',
  [DocumentType.SubscriptionAgreement]: 'Subscription Agreement',
  [DocumentType.TaxDocument]: 'Tax Document',
  [DocumentType.TaxLossLetter]: 'Tax Loss Letter',
  [DocumentType.TaxUpdate]: 'Tax Update',
  [DocumentType.W9Form]: 'W9 Form',
  [DocumentType.WithholdingForm]: 'Withholding Form'
};

export const OfferingDocumentTypeOptions: SelectOption[] =
  DocumentTypeOptions.filter(option => {
    return option.value !== DocumentType.AccountDocument;
  });

export const OfferingDocumentGroupOptions: SelectOption[] = [
  {
    label: 'Individual Documents',
    value: OfferingDocumentGrouping.Individual
  },
  {
    label: 'Group Document',
    value: OfferingDocumentGrouping.Group
  }
];

export const OfferingDocumentAcceptedFileTypes: {
  [key in OfferingDocumentGrouping]: string[];
} = {
  group: ['JPG', 'PDF', 'PNG'],
  individual: ['ZIP']
};

const groupDocumentAttachmentLabel =
  'Group Documents require that only a single (unzipped) file is uploaded. This document will be copied to every investor in the selected closing.';

export const OfferingDocumentAttachmentLabel: {
  [key in OfferingDocumentGrouping]: ObjType;
} = {
  group: {
    investment_document: groupDocumentAttachmentLabel,
    tax_document: groupDocumentAttachmentLabel
  },
  individual: {
    investment_document: AttachmentLabel.investment_document,
    tax_document: AttachmentLabel.tax_document
  }
};
