import { Button, Modal } from '@equitymultiple/react-eui';
import { ButtonProps } from '@equitymultiple/react-eui/dist/components/Button/Button';
import React from 'react';
import * as modalStyles from 'styles/components/Modal.module.scss';

export interface Props {
  buttonText?: string;
  buttonVariant?: ButtonProps['variant'];
  content?: React.ReactNode;
  handleCloseModal: () => void;
  loading?: boolean;
  onSubmit: () => void;
  title?: string;
}

const ConfirmationModal: React.FC<Props> = ({
  buttonText,
  buttonVariant,
  content,
  handleCloseModal,
  loading,
  onSubmit,
  title
}) => {
  return (
    <Modal
      closeButtonText="Cancel"
      data-testid="confirmationModal"
      onClose={handleCloseModal}
      open
      submitButton={
        <Button
          loading={loading}
          onClick={onSubmit}
          variant={buttonVariant || 'orange'}
        >
          {buttonText || 'Yes'}
        </Button>
      }
      title={title && <h6>{title}</h6>}
    >
      <div className={modalStyles.contentItem} data-testid="modalContent">
        {content || ''}
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
