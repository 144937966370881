import {
  Button,
  Card,
  EMLoadingIcon,
  Input,
  Select
} from '@equitymultiple/react-eui';
// eslint-disable-next-line import/no-unresolved
import Option from '@equitymultiple/react-eui/dist/types/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DwollaCustomer,
  DwollaFundingSource,
  InputMaybe,
  namedOperations,
  Scalars,
  useGetDwollaCustomerBalanceQuery,
  useMoveDwollaCustomerBalanceMutation
} from '__generated__';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import callMutationWithToastMessages from 'utils/callMutationWithToastMessages';
import { dwollaCustomerFundingSourcesLink } from 'utils/constants';
import { setFieldProps } from 'utils/formHelpers';

import * as loadingIconStyles from '../../../styles/components/EMLoadingIcon.module.scss';
import * as styles from './AdminAccounts.module.scss';
import { moveDwollaCustomerBalanceSchema } from './validation';

type DwollaBalanceInput = {
  destination?: InputMaybe<Scalars['ID']['input']>;
};

const toastMessages = {
  error: 'An error occurred while attempting to move the Dwolla balance',
  loading: 'Moving Dwolla balance',
  success: 'Dwolla balance moved'
};

const MoveDwollaBalance: React.FC = () => {
  const [bankAccountOptions, setBankAccountOptions] = useState<Option[]>([]);
  const [moveDwollaCustomerBalance, moveDwollaCustomerBalanceState] =
    useMoveDwollaCustomerBalanceMutation();
  const submitting = moveDwollaCustomerBalanceState.loading;

  const { id } = useParams();
  const { data, loading } = useGetDwollaCustomerBalanceQuery({
    variables: {
      dwollaCustomerId: id as string
    }
  });

  const dwollaCustomer = data?.dwollaCustomer?.dwollaCustomer as DwollaCustomer;

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<DefaultValues<DwollaBalanceInput>>({
    mode: 'onBlur',
    resolver: yupResolver(moveDwollaCustomerBalanceSchema)
  });

  useEffect(() => {
    if (dwollaCustomer?.fundingSources?.length > 0) {
      const bankAccounts = dwollaCustomer.fundingSources.map(
        (fundingSource: DwollaFundingSource) => ({
          label: `${fundingSource.bankAccount?.name} (${fundingSource.bankAccount?.accountNumber})`,
          value: fundingSource.id as string
        })
      );
      setBankAccountOptions(bankAccounts);
    }
  }, [dwollaCustomer]);

  const onSubmit = (submitData: DwollaBalanceInput) => {
    callMutationWithToastMessages(moveDwollaCustomerBalance, toastMessages, {
      refetchQueries: [namedOperations.Query.getDwollaCustomerBalance],
      variables: {
        destination: submitData.destination,
        dwollaCustomerId: dwollaCustomer.id as string
      }
    }).then(res => {
      if (!res.errors && !res?.data?.moveDwollaCustomerBalance?.error) {
        reset();
      }
    });
  };

  return loading ? (
    <EMLoadingIcon
      className={loadingIconStyles.cardLoader}
      data-testid="emLoadingIcon"
    />
  ) : (
    <>
      <h2 data-testid="moveDwollaBalance">
        {dwollaCustomer
          ? `Move Dwolla Balance - #${dwollaCustomer.id}`
          : 'Move Dwolla Balance'}
      </h2>
      <Card>
        <div className={styles.formTop}>
          <Link to="/accounts">&lt; Back</Link>
        </div>
        <form
          data-testid="moveDwollaCustomerBalance"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col lg={4} md={6}>
              <h4>Balance</h4>
              <Input
                allowDecimal
                data-testid="dwolla-balance"
                disabled
                dollarMask
                label="Dwolla Balance"
                value={dwollaCustomer.balance}
              />
              <a
                href={dwollaCustomerFundingSourcesLink(dwollaCustomer)}
                rel="noreferrer"
                target="_blank"
              >
                View in Dwolla
              </a>
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={6}>
              <h4>Destination</h4>
              <Controller
                control={control}
                name="destination"
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    data-testid="destination-funding-source"
                    label="Select Bank Account"
                    onChange={e => field.onChange(e)}
                    options={bankAccountOptions}
                  />
                )}
              />
            </Col>
          </Row>
          <Button className="floatRight" loading={submitting} type="submit">
            Move Dwolla Balance
          </Button>
        </form>
      </Card>
    </>
  );
};

export default MoveDwollaBalance;
