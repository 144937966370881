import { Button, FileUploader, Table } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { useState } from 'react';
import { Col } from 'react-grid-system';
import { Controller, DefaultValues, useForm } from 'react-hook-form';

import {
  Document,
  TransactionUploadInput,
  useDeleteTransactionsMutation,
  useGetTransactionUploadsQuery,
  useTransactionUploadMutation
} from '../../../__generated__';
import { serverSideUploadLimit } from '../../../constants';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import { ColumnHeaders } from './constants';
import * as styles from './Transactions.module.scss';
import TransactionsActions from './TransactionsActions';
import { transactionUploadSchema } from './validation';

const uploadMessages = {
  error: 'An error occurred while attempting to upload the transactions',
  loading: 'Uploading transactions',
  success: 'Uploaded'
};

const deleteMessages = {
  error: 'An error occurred while deleting the transactions',
  loading: 'Deleting',
  success: 'Transactions Deleted'
};

type FormValues = TransactionUploadInput;

const Transactions: React.FC = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [deleteTransactionsMutation] = useDeleteTransactionsMutation();
  const defaultValues = {
    attachment: null
  };

  const { data, fetchMore, loading } = useGetTransactionUploadsQuery({
    variables: {
      pagination: {
        page,
        pageSize: rowsPerPage
      }
    }
  });

  const hasDocuments = data?.transactionUploads.data != undefined;

  const fetchMoreTransactionUploads = (
    pageNumber: number,
    pageSize: number
  ) => {
    fetchMore({
      variables: {
        pagination: {
          page: pageNumber,
          pageSize
        }
      }
    });
  };

  const handlePageChange = (
    _: null | React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => {
    const pageNumber = newPage + 1;
    setPage(pageNumber);
    fetchMoreTransactionUploads(pageNumber, rowsPerPage);
  };

  const handleRowsPerPageChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    const pageSize = parseInt(event.currentTarget.value);
    setRowsPerPage(pageSize);
    setPage(1);
    fetchMoreTransactionUploads(1, pageSize);
  };

  const refetch = () => {
    setPage(1);
    fetchMoreTransactionUploads(1, rowsPerPage);
  };

  const handleDeleteTransactions = (document: Document) => {
    callMutationWithToastMessages(deleteTransactionsMutation, deleteMessages, {
      variables: {
        documentId: document.id
      }
    }).then(() => {
      fetchMoreTransactionUploads(page, rowsPerPage);
    });
  };

  const getRows = (documents: Array<Document>) => {
    return documents.map(document => {
      const { createdAt, filename, uploader } = document;

      return {
        cells: [
          filename,
          uploader,
          moment.utc(new Date(createdAt)).format('M/D/YYYY'),
          <TransactionsActions
            deleteTransactions={handleDeleteTransactions}
            document={document}
            key={document.id}
          />
        ]
      };
    });
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<DefaultValues<FormValues>>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(transactionUploadSchema)
  });

  const [transactionUpload, transactionUploadState] =
    useTransactionUploadMutation();
  const uploading = transactionUploadState.loading;

  const onSubmit = async (submitData: Partial<FormValues>) => {
    const isValid = await transactionUploadSchema.isValid(submitData);

    if (isValid) {
      await callMutationWithToastMessages(transactionUpload, uploadMessages, {
        variables: {
          document: submitData as TransactionUploadInput
        }
      });

      refetch();
      reset({ attachment: null });
    }
  };

  return (
    <>
      <h2 className="marginTop0" data-testid="transactionsHeading">
        Transactions
      </h2>
      <h4 className="marginTop0" data-testid="transactionUpload">
        Transaction Upload
      </h4>

      <form data-testid="transactionForm" onSubmit={handleSubmit(onSubmit)}>
        <Col lg={8} md={8} xl={6}>
          <Controller
            control={control}
            name="attachment"
            render={({ field }) => (
              <FileUploader
                {...field}
                acceptedFileTypes={['XLSX']}
                errorMessage={errors.attachment?.message as string}
                existingFile={field.value}
                maxSize={serverSideUploadLimit}
                onRemove={() => field.onChange(null)}
                reset={!field.value}
                upload={(uploadData: File) => field.onChange(uploadData)}
              />
            )}
          />
        </Col>

        <Button
          className="floatRight margin40"
          loading={uploading}
          type="submit"
        >
          Submit
        </Button>
      </form>

      <Table
        className={styles.transactionsTable}
        columnHeaders={ColumnHeaders}
        loading={loading}
        loadingRows={10}
        pagination={{
          count: data?.transactionUploads?.pageInfo?.count || 0,
          onPageChange: handlePageChange,
          onRowsPerPageChange: handleRowsPerPageChange,
          page: hasDocuments ? page - 1 : 0,
          rowsPerPage
        }}
        rows={
          hasDocuments
            ? getRows(data?.transactionUploads.data)
            : [{ cells: ['No transactions upload found'] }]
        }
      />
    </>
  );
};

export default Transactions;
