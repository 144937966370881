import { Card, EMLoadingIcon } from '@equitymultiple/react-eui';
import React, { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Offering,
  OmcmsClassifiedOfferings,
  useGetOfferingQuery,
  useGetOmcmsOfferingsQuery
} from '../../../__generated__';
import * as loadingIconStyles from '../../../styles/components/EMLoadingIcon.module.scss';
import OfferingForm from './OfferingForm';

const EditOffering: React.FC = () => {
  const { id } = useParams();
  const {
    data: offeringData,
    error: offeringError,
    loading: offeringLoading
  } = useGetOfferingQuery({
    variables: {
      offeringId: id as string
    }
  });
  const {
    data: omcmsOfferingsData,
    error: omcmsOfferingsError,
    loading: omcmsOfferingsLoading
  } = useGetOmcmsOfferingsQuery();

  const loading = offeringLoading || omcmsOfferingsLoading;
  const offering = offeringData?.offering.offering;
  const omcmsOfferings = omcmsOfferingsData?.omcmsOfferings.data;
  const hasError =
    offeringError || omcmsOfferingsError || offeringData?.offering.error;
  const showForm = !loading && !hasError;

  const navigate = useNavigate();

  useEffect(() => {
    if (hasError) {
      toast.error('An error occurred while loading the offering');
      navigate('/');
    }
  }, [hasError, navigate]);

  return (
    <>
      <h2>
        {loading ? 'Edit Offering' : `Edit Offering - ${offering?.title}`}
      </h2>
      <Card className="firstChildMarginTop0">
        {showForm ? (
          <>
            <OfferingForm
              offering={offering as Offering}
              omcmsOfferings={omcmsOfferings as OmcmsClassifiedOfferings}
            />
          </>
        ) : (
          <EMLoadingIcon
            className={loadingIconStyles.cardLoader}
            data-testid="emLoadingIcon"
          />
        )}
      </Card>
    </>
  );
};

export default EditOffering;
