import {
  AnnualReturnLabel,
  AssetClass,
  AssetType,
  DistributionFrequency,
  InterestDayCountConvention,
  InvestmentStrategy,
  OfferingApproach,
  OfferingEntityType,
  OfferingPillar,
  OfferingStrategy,
  OfferingSubtype,
  OfferingType,
  OriginationFeePaidBy,
  PreferredReturnLabel,
  ProjectStage,
  RestrictedTo,
  TermPeriod,
  TypeOffering
} from '../../../__generated__';

interface OfferingApproachOption {
  label: string;
  value: OfferingApproach;
}

export const offeringApproachOptions: OfferingApproachOption[] = [
  {
    label: 'Direct',
    value: OfferingApproach.Direct
  },
  {
    label: 'Fund',
    value: OfferingApproach.Fund
  },
  {
    label: 'Tax-Deferred',
    value: OfferingApproach.TaxDeferred
  },
  {
    label: 'Alternative to Savings',
    value: OfferingApproach.AlternativeToSavings
  }
];

export const offeringApproachByValue = (value: string) => {
  if (value === null) return '';
  return (
    offeringApproachOptions.find(option => option.value === value)?.label || ''
  );
};

interface OfferingPillarOption {
  label: string;
  value: OfferingPillar;
}

export const offeringPillarOptions: OfferingPillarOption[] = [
  {
    label: 'Earn',
    value: OfferingPillar.Earn
  },
  {
    label: 'Grow',
    value: OfferingPillar.Grow
  },
  {
    label: 'Keep',
    value: OfferingPillar.Keep
  }
];

interface OfferingStrategyOption {
  label: string;
  value: OfferingStrategy;
}

export const offeringStrategyOptions: OfferingStrategyOption[] = [
  {
    label: 'Debt',
    value: OfferingStrategy.Debt
  },
  {
    label: 'Equity',
    value: OfferingStrategy.Equity
  },
  {
    label: '1031',
    value: OfferingStrategy.N1031
  },
  {
    label: 'Opportunity Zone',
    value: OfferingStrategy.OpportunityZone
  },
  {
    label: 'Preferred Equity',
    value: OfferingStrategy.PreferredEquity
  },
  {
    label: 'Short Term Note',
    value: OfferingStrategy.ShortTermNote
  }
];

interface OfferingTypeOption {
  label: string;
  value: OfferingType;
}

export const offeringTypeOptions: OfferingTypeOption[] = [
  {
    label: 'Equity',
    value: OfferingType.Equity
  },
  {
    label: 'Preferred Equity',
    value: OfferingType.PreferredEquity
  },
  {
    label: 'Senior Equity',
    value: OfferingType.SeniorEquity
  },
  {
    label: 'Mezzanine Debt',
    value: OfferingType.MezzanineDebt
  },
  {
    label: 'Senior Debt',
    value: OfferingType.SeniorDebt
  },
  {
    label: 'Debt + Equity',
    value: OfferingType.DebtEquity
  },
  {
    label: 'Debt + JV Equity',
    value: OfferingType.DebtJvEquity
  },
  {
    label: 'Donation',
    value: OfferingType.Donation
  },
  {
    label: 'Company Raise',
    value: OfferingType.CompanyRaise
  },
  {
    label: 'Short Term Note',
    value: OfferingType.ShortTermNote
  }
];

interface ProjectStageOption {
  label: string;
  value: ProjectStage;
}

export const projectStageOptions: ProjectStageOption[] = [
  {
    label: 'Entitlement',
    value: ProjectStage.Entitlement
  },
  {
    label: 'Development',
    value: ProjectStage.Development
  },
  {
    label: 'Value Add',
    value: ProjectStage.ValueAdd
  },
  {
    label: 'Stabilized',
    value: ProjectStage.Stabilized
  }
];

interface AssetTypeOption {
  label: string;
  value: AssetType;
}

export const assetTypeOptions: AssetTypeOption[] = [
  {
    label: 'Affordable Housing',
    value: AssetType.AffordableHousing
  },
  {
    label: 'Campground',
    value: AssetType.Campground
  },
  {
    label: 'Car Wash',
    value: AssetType.CarWash
  },
  {
    label: 'Condo',
    value: AssetType.Condo
  },
  {
    label: 'Health Care',
    value: AssetType.HealthCare
  },
  {
    label: 'Hotel',
    value: AssetType.Hotel
  },
  {
    label: 'Industrial',
    value: AssetType.Industrial
  },
  {
    label: 'Infrastructure',
    value: AssetType.Infrastructure
  },
  {
    label: 'Land',
    value: AssetType.Land
  },
  {
    label: 'Manufactured Housing Community',
    value: AssetType.ManufacturedHousingCommunity
  },
  {
    label: 'Medical Office',
    value: AssetType.MedicalOffice
  },
  {
    label: 'Mixed-Use',
    value: AssetType.MixedUse
  },
  {
    label: 'Mobile Home Park',
    value: AssetType.MobileHomePark
  },
  {
    label: 'Multi-Asset',
    value: AssetType.MultiAsset
  },
  {
    label: 'Multifamily',
    value: AssetType.Multifamily
  },
  {
    label: 'Office',
    value: AssetType.Office
  },
  {
    label: 'Other',
    value: AssetType.Other
  },
  {
    label: 'Residential',
    value: AssetType.Residential
  },
  {
    label: 'Retail',
    value: AssetType.Retail
  },
  {
    label: 'Self-Storage',
    value: AssetType.SelfStorage
  },
  {
    label: 'Senior Housing',
    value: AssetType.SeniorHousing
  },
  {
    label: 'Student Housing',
    value: AssetType.StudentHousing
  },
  {
    label: 'Townhomes',
    value: AssetType.Townhomes
  }
];

interface InvestmentStrategyOption {
  label: string;
  value: InvestmentStrategy;
}

export const investmentStrategyOptions: InvestmentStrategyOption[] = [
  {
    label: 'Core',
    value: InvestmentStrategy.Core
  },
  {
    label: 'Core-Plus',
    value: InvestmentStrategy.CorePlus
  },
  {
    label: 'Opportunistic',
    value: InvestmentStrategy.Opportunistic
  },
  {
    label: 'Value Add',
    value: InvestmentStrategy.ValueAdd
  }
];

interface AnnualReturnLabelOption {
  label: string;
  value: AnnualReturnLabel;
}

export const annualReturnLabelOptions: AnnualReturnLabelOption[] = [
  {
    label: 'Annual Return Objective',
    value: AnnualReturnLabel.AnnualReturnObjective
  },
  {
    label: 'Annualized Yield',
    value: AnnualReturnLabel.AnnualizedYield
  },
  {
    label: 'Stabilized Return on Cost',
    value: AnnualReturnLabel.StabilizedReturnOnCost
  },
  {
    label: 'Target Average Cash Yield',
    value: AnnualReturnLabel.TargetAverageCashYield
  }
];

interface DistributionFrequencyOption {
  label: string;
  value: DistributionFrequency;
}

export const distributionFrequencyOptions: DistributionFrequencyOption[] = [
  {
    label: 'Monthly',
    value: DistributionFrequency.Monthly
  },
  {
    label: 'Quarterly',
    value: DistributionFrequency.Quarterly
  },
  {
    label: 'Upon Sale',
    value: DistributionFrequency.UponSale
  }
];

export const distributionFrequencyByValue = (value: string) => {
  if (value === null) return '';
  return (
    distributionFrequencyOptions.find(option => option.value === value)
      ?.label || ''
  );
};

interface InterestDayCountConventionOption {
  label: string;
  value: InterestDayCountConvention;
}

export const interestDayCountConventionOptions: InterestDayCountConventionOption[] =
  [
    {
      label: '30/360',
      value: InterestDayCountConvention['30_360']
    },
    {
      label: '30/365',
      value: InterestDayCountConvention['30_365']
    },
    {
      label: 'Actual/360',
      value: InterestDayCountConvention.Actual_360
    },
    {
      label: 'Actual/365',
      value: InterestDayCountConvention.Actual_365
    }
  ];

interface PreferredReturnLabelOption {
  label: string;
  value: PreferredReturnLabel;
}

export const preferredReturnLabelOptions: PreferredReturnLabelOption[] = [
  {
    label: 'Profit Participation',
    value: PreferredReturnLabel.ProfitParticipation
  },
  {
    label: 'Total Preferred Return',
    value: PreferredReturnLabel.TotalPreferredReturn
  }
];

interface TermPeriodOption {
  label: string;
  value: TermPeriod;
}

export const termPeriodOptions: TermPeriodOption[] = [
  {
    label: 'Months',
    value: TermPeriod.Months
  },
  {
    label: 'Years',
    value: TermPeriod.Years
  }
];

export const termPeriodByValue = (value: string) => {
  if (value === null) return '';
  return termPeriodOptions.find(option => option.value === value)?.label || '';
};

interface OriginationFeePaidByOption {
  label: string;
  value: OriginationFeePaidBy;
}

export const originationFeePaidByOptions: OriginationFeePaidByOption[] = [
  {
    label: 'Investor',
    value: OriginationFeePaidBy.Investor
  },
  {
    label: 'Sponsor',
    value: OriginationFeePaidBy.Sponsor
  }
];

interface EntityTypeOption {
  label: string;
  value: OfferingEntityType;
}

export const entityTypeOptions: EntityTypeOption[] = [
  {
    label: 'Community Property',
    value: OfferingEntityType.CommunityProperty
  },
  {
    label: 'Individual',
    value: OfferingEntityType.Individual
  },
  {
    label: 'LLC',
    value: OfferingEntityType.Llc
  },
  {
    label: 'LP',
    value: OfferingEntityType.Lp
  },
  {
    label: 'Trust',
    value: OfferingEntityType.Trust
  }
];

interface AssetManagementOfferingTypeOption {
  label: string;
  value: TypeOffering;
}

export const assetManagementOfferingTypeOptions: AssetManagementOfferingTypeOption[] =
  [
    {
      label: 'None',
      value: TypeOffering.None
    },
    {
      label: 'Opportunity Zone',
      value: TypeOffering.OpportunityZone
    },
    {
      label: '1031 Exchange',
      value: TypeOffering.Exchange_1031
    }
  ];

interface AssetClassOption {
  label: string;
  value: AssetClass;
}

export const assetClassOptions: AssetClassOption[] = [
  {
    label: 'Real Estate',
    value: AssetClass.RealEstate
  },
  {
    label: 'Consumer Loan',
    value: AssetClass.ConsumerLoan
  },
  {
    label: 'Student Loan',
    value: AssetClass.StudentLoan
  },
  {
    label: 'SMB Loan',
    value: AssetClass.SmbLoan
  },
  {
    label: 'Startup Equity',
    value: AssetClass.StartupEquity
  }
];

interface RestrictedToOption {
  label: string;
  value: RestrictedTo;
}

export const restrictedToOptions: RestrictedToOption[] = [
  {
    label: 'None',
    value: RestrictedTo.None
  },
  {
    label: '506(b)',
    value: RestrictedTo.B506
  },
  {
    label: '506(c)',
    value: RestrictedTo.C506
  }
];

interface SubTypeOption {
  label: string;
  value: OfferingSubtype;
}

export const equitySubtypeOptions: SubTypeOption[] = [
  {
    label: 'Common',
    value: OfferingSubtype.Common
  },
  {
    label: 'Preferred',
    value: OfferingSubtype.Preferred
  }
];

export const otherSubtypeOptions: SubTypeOption[] = [
  {
    label: 'First Lien',
    value: OfferingSubtype.FirstLien
  },
  {
    label: 'Mezzanine',
    value: OfferingSubtype.Mezzanine
  },
  {
    label: 'Second Lien',
    value: OfferingSubtype.SecondLien
  }
];

export const reinvestmentCampaignOptions = [
  { label: 'Debt Fund', value: 'debt_fund' },
  { label: 'Alpine Note', value: 'alpine_note' }
];
