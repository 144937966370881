import { Button, Card, Input } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CreateDwollaCustomerInput,
  DwollaCustomerPayload,
  GetDefaultDwollaCustomerDataQuery,
  namedOperations,
  useCreateDwollaCustomerMutation,
  useGetDefaultDwollaCustomerDataQuery
} from '__generated__';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate } from 'react-router-dom';
import { DefaultDwollaData } from 'types/DefaultDwollaData';
import callMutationWithToastMessages from 'utils/callMutationWithToastMessages';
import classifications from 'utils/dwollaBusinessClass';
import { setFieldProps } from 'utils/formHelpers';

import { accountSchema } from '../validation';
import * as styles from './AddAdminAccount.module.scss';

const defaultDwollaDataParser = (
  data: GetDefaultDwollaCustomerDataQuery
): DefaultDwollaData | null => {
  if (data?.settings?.settings?.[0]?.value) {
    const parsedData: DefaultDwollaData = JSON.parse(
      (data?.settings?.settings?.[0]?.value as string)?.replaceAll('=>', ':')
    );

    const businessClass = classifications._embedded[
      'business-classifications'
    ].find(busClass => {
      return parsedData.businessClassification === busClass.id;
    });

    const industryClass = businessClass?._embedded[
      'industry-classifications'
    ].find(indClass => {
      return parsedData.industryClassification === indClass.id;
    });

    parsedData.businessClassification = businessClass?.name;
    parsedData.industryClassification = industryClass?.name;
    parsedData.ssn = parsedData?.ssn?.replace(/^(\D*\d\D*){5}/, 'XXX-XX-');
    parsedData.dateOfBirth = parsedData?.dateOfBirth?.replace(
      /^(\D*\d\D*){4}/,
      'XX/XX/'
    );

    return parsedData;
  }

  return null;
};

const toastMessages = {
  error: 'An error occurred while attempting to add the account',
  loading: 'Adding Account',
  success: 'Account Added'
};

const defaultValues = {
  businessName: '',
  doingBusinessAs: '',
  ein: '',
  email: ''
};

const AddAdminAccount: React.FC = () => {
  const [defaultData, setDefaultData] = useState<DefaultDwollaData | null>(
    null
  );

  const { data: defaultDwollaCustomerData } =
    useGetDefaultDwollaCustomerDataQuery({
      variables: {
        name: 'dwolla_account_default'
      }
    });

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm<DefaultValues<CreateDwollaCustomerInput>>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(accountSchema)
  });

  const [createDwollaCustomer, createDwollaCustomerState] =
    useCreateDwollaCustomerMutation();

  const loading = createDwollaCustomerState.loading;

  const navigate = useNavigate();

  const onSubmit = async (submitData: Partial<CreateDwollaCustomerInput>) => {
    const isValid = await accountSchema.isValid(submitData);

    if (isValid) {
      const { data } = await callMutationWithToastMessages(
        createDwollaCustomer,
        toastMessages,
        {
          refetchQueries: [namedOperations.Query.getDwollaCustomers],
          variables: {
            dwollaCustomer: submitData
          }
        }
      );

      const { dwollaCustomer } =
        data?.createDwollaCustomer as DwollaCustomerPayload;

      if (dwollaCustomer) {
        navigate(`/accounts/${dwollaCustomer.id}/edit`);
      }
    }
  };

  useEffect(() => {
    setDefaultData(
      defaultDwollaDataParser(
        defaultDwollaCustomerData as GetDefaultDwollaCustomerDataQuery
      )
    );
  }, [defaultDwollaCustomerData]);

  return (
    <>
      <div>
        <h2 data-testid="addAdminAccountHeading">Add Account</h2>
      </div>
      <Card>
        <p>
          An EquityMultiple Entity is setup for each Offering to send and
          receive funds to investors. The values below have been standardized
          for your convenience. Should they need to be changed, you can do so as
          an admin by navigating to Admin &gt; Configuration &gt;{' '}
          <Link to="/configuration/dwolla_configuration">
            Dwolla Configuration
          </Link>
        </p>
        <div className={styles.defaultValues}>
          <strong>First Name:</strong> {defaultData?.firstName || <Skeleton />}
        </div>
        <div className={styles.defaultValues}>
          <strong>Last Name:</strong> {defaultData?.lastName || <Skeleton />}
        </div>
        <div className={styles.defaultValues}>
          <strong>Title:</strong> {defaultData?.title || <Skeleton />}
        </div>
        <div className={styles.defaultValues}>
          <strong>City:</strong> {defaultData?.city || <Skeleton />}
        </div>
        <div className={styles.defaultValues}>
          <strong>State:</strong> {defaultData?.state || <Skeleton />}
        </div>
        <div className={styles.defaultValues}>
          <strong>Postal Code:</strong>
          {defaultData?.postalCode || <Skeleton />}
        </div>
        <br />
        <div className={`${styles.defaultValues} ${styles.longField}`}>
          <strong>Social Security Number (SSN):</strong>
          {defaultData?.ssn || <Skeleton />}
        </div>
        <div className={`${styles.defaultValues} ${styles.longField}`}>
          <strong>Date of Birth (DOB):</strong>
          {defaultData?.dateOfBirth || <Skeleton />}
        </div>
        <div className={styles.defaultValues}>
          <strong>Phone:</strong> {defaultData?.phone || <Skeleton />}
        </div>
        <div className={styles.defaultValues}>
          <strong>Address:</strong>
          {(defaultData?.address1 && `${defaultData?.address1}, `) || (
            <Skeleton />
          )}
          {defaultData?.address2}
        </div>
        <br />
        <div className={`${styles.defaultValues} ${styles.longField}`}>
          <strong>Business:</strong>
          {defaultData?.businessClassification || <Skeleton />}
        </div>
        <div className={`${styles.defaultValues} ${styles.longField}`}>
          <strong>Industry:</strong>
          {defaultData?.industryClassification || <Skeleton />}
        </div>
        <div className={styles.defaultValues}>
          <strong>Business Type:</strong>
          {defaultData?.businessType?.toUpperCase() || <Skeleton />}
        </div>
        <hr />
        <form data-testid="addAccountForm" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col lg={4} md={6}>
              <Controller
                control={control}
                name="ein"
                render={({ field }) => (
                  <Input
                    {...setFieldProps(field, errors)}
                    label="EIN"
                    mask="9{2}-9{7}"
                  />
                )}
              />
            </Col>
            <Col lg={4} md={6}>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <Input {...setFieldProps(field, errors)} label="Email" />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={6}>
              <Controller
                control={control}
                name="businessName"
                render={({ field }) => (
                  <Input
                    {...setFieldProps(field, errors)}
                    label="Business Name"
                  />
                )}
              />
            </Col>
            <Col lg={4} md={6}>
              <Controller
                control={control}
                name="doingBusinessAs"
                render={({ field }) => (
                  <Input
                    {...setFieldProps(field, errors)}
                    label="Doing Business As (Optional)"
                  />
                )}
              />
            </Col>
          </Row>
          <Button className="floatRight" loading={loading} type="submit">
            Submit
          </Button>
        </form>
      </Card>
    </>
  );
};

export default AddAdminAccount;
