import { Select, Table } from '@equitymultiple/react-eui';
import Option, {
  NonAsyncValue
  // eslint-disable-next-line import/no-unresolved
} from '@equitymultiple/react-eui/dist/types/Select';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';

import {
  Address,
  GetOfferingDocument,
  GetOfferingQuery,
  Offering,
  useCreateAddressMutation,
  useDeleteAddressMutation
} from '../../../__generated__';
import { deleteObjectFromCache } from '../../../utils/apolloCacheHelpers';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import cloneObject from '../../../utils/cloneObject';

interface Props {
  offering: Offering;
}

const createMessages = {
  error: 'An error occurred while adding the address',
  loading: 'Adding',
  success: 'Address added'
};

const deleteMessages = {
  error: 'An error occurred while deleting the address',
  loading: 'Deleting',
  success: 'Address deleted'
};

const getAddressAttribute = (values: string[]): null | string => {
  if (values.includes('country')) {
    return 'country';
  } else if (
    values.includes('locality') ||
    values.includes('postal_town') ||
    values.includes('sublocality_level_1')
  ) {
    return 'city';
  } else if (values.includes('administrative_area_level_1')) {
    return 'state';
  } else if (values.includes('postal_code')) {
    return 'postalCode';
  }
  return null;
};

interface AddressInput {
  [key: string]: string;
}

const columnHeaders = ['Address', 'City', 'State', 'Postal Code', 'Actions'];

const OfferingAddresses: React.FC<Props> = ({ offering }) => {
  const {
    setValue,
    suggestions: { data, status },
    value
  } = usePlacesAutocomplete();
  const [addressSuggestions, setAddressSuggestions] = useState<Option[]>([]);

  const [createAddress, createAddressState] = useCreateAddressMutation();

  const [deleteAddress] = useDeleteAddressMutation();

  useEffect(() => {
    if (status === 'OK' && data.length > 0) {
      const selectOptions = data.map(({ description }) => ({
        label: description,
        value: description
      }));
      setAddressSuggestions(selectOptions);
    }
  }, [status, data]);

  const handleInputChange = (val: string): void => {
    setValue(val);
  };

  const handleAddressSelect = (address: NonAsyncValue): void => {
    setValue(address as string, false);
    getGeocode({ address: address as string }).then(res => {
      const addressInput: AddressInput = {};
      res[0].address_components.forEach(addressComponent => {
        const key = getAddressAttribute(addressComponent.types);
        if (key) {
          addressInput[key] = addressComponent.long_name;
        }
      });
      const input = {
        address: address as string,
        city: addressInput.city,
        country: addressInput.country,
        postalCode: addressInput.postalCode,
        state: addressInput.state
      };

      callMutationWithToastMessages(createAddress, createMessages, {
        update: (cache, result) => {
          const offeringResult = cache.readQuery({
            query: GetOfferingDocument,
            variables: { offeringId: offering.id }
          }) as GetOfferingQuery;

          const newAddress = result.data?.createAddress.address;

          if (offeringResult && newAddress) {
            const newOfferingResult = cloneObject(offeringResult);

            if (newOfferingResult.offering.offering?.addresses) {
              const currentAddresses =
                offeringResult.offering?.offering?.addresses?.addresses;

              newOfferingResult.offering.offering.addresses = {
                addresses: [...(currentAddresses || []), newAddress]
              };

              cache.writeQuery({
                data: newOfferingResult,
                query: GetOfferingDocument
              });
            }
          }
        },
        variables: {
          address: input,
          offeringId: offering.id
        }
      });
      setValue('', false);
      setAddressSuggestions([]);
    });
  };

  const handleDeleteAddress = (addressId: string) => {
    callMutationWithToastMessages(deleteAddress, deleteMessages, {
      update(cache) {
        deleteObjectFromCache(cache, 'Address', addressId);
      },
      variables: {
        addressId: addressId,
        offeringId: offering.id
      }
    });
  };

  const getRows = () => {
    return offering?.addresses?.addresses &&
      offering.addresses.addresses.length > 0
      ? offering.addresses.addresses.map(offeringAddress => {
          const { address, city, id, postalCode, state } =
            offeringAddress as Address;

          return {
            cells: [
              address,
              city,
              state,
              postalCode,
              <button
                className="textLink underline"
                key={id}
                onClick={() => handleDeleteAddress(id as string)}
                type="button"
              >
                Delete
              </button>
            ]
          };
        })
      : [{ cells: ['No addresses found'] }];
  };

  return (
    <>
      <hr />
      <div className="margin30">
        <h3>Addresses</h3>
        <Row>
          <Col lg={8} md={12}>
            <Table columnHeaders={columnHeaders} rows={getRows()} />
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={4} md={6}>
            <Select
              disabled={createAddressState.loading}
              helperText="Type to search for location"
              helperTextAlwaysVisible
              id="new-address"
              label="Add new address"
              onChange={handleAddressSelect}
              onInputChange={handleInputChange}
              options={addressSuggestions}
              value={value}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OfferingAddresses;
