// Allows 2 digit perecentages with optional two digit decimals
// Examples: 9%, 99%, 99.9%, 99.99%
export const percentageMask = '9[9][.9[9]]%';
export const percentageMaskRange = '(9[9][.9[9]][-9[9][.9[9]]]%)|[N/A]';

// inputMaskOptions for percentage values as string
export const percentageMaskOptions = {
  onUnMask: (maskedValue: string): string => maskedValue,
  suffix: '%'
};

// inputMaskOptions for percentage values as string
export const percentageMaskRangeOptions = {
  onUnMask: (maskedValue: string): string => maskedValue,
  suffix: '%'
};

// inputMaskOptions for numbers
export const numberMaskOptions = {
  autoUnmask: true,
  removeMaskOnSubmit: false
};

// inputMaskOptions for range
export const rangeMaskOptions = {
  autoUnmask: true,
  removeMaskOnSubmit: false
};

// inputMask onUnMask option, for number types
export const percentageMaskNumberOnUnMask = (
  maskedValue: string,
  _: string,
  // eslint-disable-next-line
  options: Record<string, any>
): null | number | string => {
  let returnValue = maskedValue;
  returnValue = returnValue.replace('_', '');
  returnValue = returnValue.replace(',', '');
  if (options.prefix) returnValue = returnValue.replace(options.prefix, '');
  if (options.suffix) returnValue = returnValue.replace(options.suffix, '');
  if (returnValue.charAt(returnValue.length - 1) === '.')
    return returnValue || null;
  else return returnValue ? Number(returnValue) : null;
};

// Supports masked and unmasked SSNs & EINs
// Examples:
// SSN - XXX-XX-123, 123-45-6789
// EIN - XX-XXX1234, 12-3456789
const maskedChar = 'X|9';
export const ssn = `${maskedChar}${maskedChar}${maskedChar}-${maskedChar}${maskedChar}-9999`;
export const ein = `${maskedChar}${maskedChar}-${maskedChar}${maskedChar}${maskedChar}9999`;

// Inputmask options required for SSN fields
export const ssnMaskOptions = {
  definitions: {
    X: {
      validator: '[X]'
    }
  }
};
