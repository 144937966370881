import { Button, DateSelect, Input, Select } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  EditAdminAccountDefaultInput,
  GetAdminAccountDefaultQuery,
  useEditAdminAccountDefaultMutation
} from '__generated__';
import dwollaBusinessClassifications from 'constants/dwollaBusinessClassifications';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import callMutationWithToastMessages from 'utils/callMutationWithToastMessages';
import { setDateSelectFieldProps, setFieldProps } from 'utils/formHelpers';
import { ssn, ssnMaskOptions } from 'utils/masks';
import { sortArrayofObjectsByProperty } from 'utils/sorting';

import {
  businessClassOptions,
  entityBusinessTypeOptions,
  stateOptions
} from '../constants';
import { adminAccountDefaultSchema } from './validation';

const messages = {
  error: 'An error occurred while updating the account default values',
  loading: 'Saving',
  success: 'Account default values updated'
};

interface IndustryOption {
  label: string;
  value: string;
}

export const getIndustryClass = (
  businessClassValue: null | string | undefined
): IndustryOption[] => {
  if (businessClassValue) {
    const businessClasses =
      dwollaBusinessClassifications._embedded['business-classifications'];
    const industries = businessClasses.find(
      businessClass => businessClass.id === businessClassValue
    )?._embedded['industry-classifications'];

    if (industries) {
      sortArrayofObjectsByProperty(industries, 'name');

      return industries.map(industry => ({
        label: industry.name,
        value: industry.id
      }));
    }

    return [];
  }
  return [];
};

type Props = { data?: GetAdminAccountDefaultQuery };

export const DwollaConfigurationForm: React.FC<Props> = ({ data }) => {
  const [editAdminAccountDefault, editAdminAccountDefaultState] =
    useEditAdminAccountDefaultMutation();

  // __typename is needed here
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { __typename: typeName, ...adminAccountDefault } =
    data?.adminAccountDefault || {};

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    setValue
  } = useForm<EditAdminAccountDefaultInput>({
    defaultValues: adminAccountDefault,
    mode: 'onBlur',
    resolver: yupResolver(adminAccountDefaultSchema)
  });

  const onSubmit = (values: EditAdminAccountDefaultInput) => {
    callMutationWithToastMessages(editAdminAccountDefault, messages, {
      variables: {
        adminAccountDefault: { ...values }
      }
    });
  };

  return (
    <form
      data-testid="dwollaConfigurationForm"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="firstName"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="First Name" />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="lastName"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Last Name" />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="title"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Title" />
            )}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="address1"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Address" />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="address2"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Address 2 (optional)"
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="city"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="City" />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="state"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="State"
                options={stateOptions}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="postalCode"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Postal Code"
                mask="9{5}"
              />
            )}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="ssn"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                inputMaskOptions={ssnMaskOptions}
                label="SSN"
                mask={ssn}
              />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="dateOfBirth"
            render={({ field }) => (
              <DateSelect
                {...setDateSelectFieldProps(field, errors)}
                label="Date of Birth"
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="phone"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Phone Number"
                mask="(999) 999-9999[ ext 999999]"
              />
            )}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="businessClassification"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Business"
                onChange={e => {
                  field.onChange(e);
                  setValue('industryClassification', '');
                }}
                options={businessClassOptions}
              />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="industryClassification"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                helperText={
                  getValues().businessClassification
                    ? ''
                    : 'Select a business to view options'
                }
                label="Industry"
                onChange={e => {
                  field.onChange(e);
                }}
                options={getIndustryClass(getValues().businessClassification)}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="businessType"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Business Type"
                options={entityBusinessTypeOptions}
              />
            )}
          />
        </Col>
      </Row>
      <Button
        className="floatRight"
        loading={editAdminAccountDefaultState.loading}
        type="submit"
      >
        Save
      </Button>
    </form>
  );
};
