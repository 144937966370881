import { Button, Modal } from '@equitymultiple/react-eui';
import React, { useCallback, useEffect, useState } from 'react';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import { useTimer } from 'react-timer-hook';

import { useGetActiveUserLazyQuery } from '../../../__generated__';
import * as modalStyles from '../../../styles/components/Modal.module.scss';

// Wait 55 minutes before showing the modal
const sessionExpirationDelay = 1000 * 60 * 55;
// Show the modal for 5 minutes
const sessionModalShownFor = 1000 * 300;

const handleUserLoggedOut = () => {
  window.location.replace('/logout');
};

const SessionExpiration: React.FC = () => {
  const [getActiveUser] = useGetActiveUserLazyQuery();
  const [modalOpen, setModalOpen] = useState(false);

  const getTime = () => {
    const time = new Date();
    time.setMilliseconds(time.getMilliseconds() + sessionModalShownFor);

    return time;
  };

  const handleLogout = () => {
    handleUserLoggedOut();
    window.localStorage.setItem('loggedOut', Math.random().toString());
  };

  const {
    minutes,
    pause: pauseTimer,
    restart: restartTimer,
    seconds,
    start: startTimer
  } = useTimer({
    autoStart: false,
    expiryTimestamp: getTime(),
    onExpire: () => handleLogout()
  });

  const handleResetModal = useCallback(() => {
    setModalOpen(false);
    pauseTimer();
    restartTimer(getTime(), false);
  }, [pauseTimer, restartTimer]);

  const handleStorageChange = useCallback(
    (event: StorageEvent) => {
      if (event.key === 'loggedOut') {
        handleUserLoggedOut();
      } else if (event.key === 'resetModal') handleResetModal();
    },
    [handleResetModal]
  );

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.addEventListener('storage', handleStorageChange);
    };
  }, [handleStorageChange]);

  const handleExtendSession = () => {
    getActiveUser();
    handleResetModal();
    window.localStorage.setItem('resetModal', Math.random().toString());
  };

  const onIdle = () => {
    if (!modalOpen) {
      setModalOpen(true);

      restartTimer(getTime(), false);
      startTimer();
    }
  };

  useIdleTimer({
    crossTab: true,
    onIdle,
    timeout: sessionExpirationDelay,
    // Use default timers in test environments to enable jest fake timers
    timers:
      process.env.NODE_ENV === 'test'
        ? {
            clearInterval: clearInterval,
            clearTimeout: clearTimeout,
            setInterval: setInterval,
            setTimeout: setTimeout
          }
        : workerTimers
  });

  const showLeadingZero = seconds < 10 && seconds > 0;
  const showTrailingZero = seconds === 0;

  return modalOpen ? (
    <Modal
      closeButtonText="Log out"
      data-testid="sessionExpirationModal"
      onClose={handleLogout}
      open
      submitButton={
        <Button onClick={handleExtendSession}>I'm still here</Button>
      }
      title={<h6>Are you still here?</h6>}
    >
      <div className={modalStyles.contentContainer}>
        <div
          className={modalStyles.contentItem}
          data-testid="sessionModalContent"
        >
          Your session is going to stop in{' '}
          <span data-testid="sessionModalCountdown">
            {`${minutes}:${showLeadingZero ? '0' : ''}${seconds}${
              showTrailingZero ? '0' : ''
            }`}
          </span>
          . You will be automatically logged out when the timer is up.
        </div>
      </div>
    </Modal>
  ) : null;
};

export default SessionExpiration;
