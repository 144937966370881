import { EMLoadingIcon } from '@equitymultiple/react-eui';
import { useGetAdminAccountDefaultQuery } from '__generated__';
import React from 'react';
import * as loadingIconStyles from 'styles/components/EMLoadingIcon.module.scss';

import { DwollaConfigurationForm } from './DwollaConfigurationForm';

export const DwollaConfiguration: React.FC = () => {
  const { data, loading } = useGetAdminAccountDefaultQuery();

  return (
    <>
      <h2 className="marginTop0" data-testid="dwollaConfigurationHeading">
        EM Entity Account Default Values
      </h2>
      {loading ? (
        <EMLoadingIcon
          className={loadingIconStyles.cardLoader}
          data-testid="emLoadingIcon"
        />
      ) : (
        <DwollaConfigurationForm data={data} />
      )}
    </>
  );
};
