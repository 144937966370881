import { Button, Modal } from '@equitymultiple/react-eui';
import React from 'react';

import { Sponsor, useDeleteSponsorMutation } from '../../../__generated__';
import { deleteObjectFromCache } from '../../../utils/apolloCacheHelpers';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';

export interface Props {
  selectedSponsor: null | Sponsor;
  setSelectedSponsorState: (sponsor: null | Sponsor) => void;
}

const messages = {
  error: 'An error occurred while deleting the sponsor',
  loading: 'Deleting',
  success: 'Sponsor Deleted'
};

const DeleteSponsorModal: React.FC<Props> = ({
  selectedSponsor,
  setSelectedSponsorState
}) => {
  const sponsorId = selectedSponsor?.id as string;
  const [deleteSponsorMutation, deleteSponsorMutationState] =
    useDeleteSponsorMutation({
      update(cache) {
        deleteObjectFromCache(cache, 'Sponsor', sponsorId);
      },
      variables: {
        sponsorId
      }
    });

  const handleDeleteSponsor = () => {
    callMutationWithToastMessages(deleteSponsorMutation, messages).then(() => {
      setSelectedSponsorState(null);
      deleteSponsorMutationState.reset();
    });
  };

  const hideDeleteModal = () => setSelectedSponsorState(null);

  return (
    <Modal
      closeButtonText="Cancel"
      data-testid="deleteSponsorModal"
      onClose={hideDeleteModal}
      open={selectedSponsor !== null}
      submitButton={
        <Button onClick={() => handleDeleteSponsor()} variant="orange">
          Yes, Delete It
        </Button>
      }
      title={<h6>Are you sure you want to delete this sponsor?</h6>}
    >
      <p className="margin30">
        <strong>Name:</strong>{' '}
        {selectedSponsor && (selectedSponsor as Sponsor).name}
        <br />
        <strong>Number of Offerings: </strong>{' '}
        {/* span is for testing purposes */}
        <span>
          {selectedSponsor && (selectedSponsor as Sponsor).offeringCount}
        </span>
      </p>
    </Modal>
  );
};

export default DeleteSponsorModal;
