import { EMLoadingIcon } from '@equitymultiple/react-eui';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  EditSponsorInput,
  useEditSponsorMutation,
  useGetSponsorQuery
} from '../../../__generated__';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import SponsorForm from '../SponsorForm/SponsorForm';
import { sponsorSchema } from '../SponsorForm/validation';

const messages = {
  error: 'An error occurred while updating the sponsor',
  loading: 'Updating',
  success: 'Sponsor updated'
};
const EditSponsor: React.FC = () => {
  const sponsorId = useParams().id as string;

  const { data, loading } = useGetSponsorQuery({
    fetchPolicy: 'no-cache',
    variables: { sponsorId }
  });

  const sponsor = data?.sponsor.sponsor;

  const [editSponsorMutation, editSponsorMutationState] =
    useEditSponsorMutation();

  const submitting = editSponsorMutationState.loading;

  const navigate = useNavigate();

  const defaultValues = {
    logo: { url: sponsor?.logo?.url },
    name: sponsor?.name as string
  };

  const onSubmit = async (submitData: EditSponsorInput) => {
    const isValid = await sponsorSchema.isValid(submitData);

    if (isValid) {
      const nameUpdated = submitData.name !== defaultValues.name;
      const logoUpdated = submitData.logo instanceof File;
      const mutationOptions: EditSponsorInput = {
        id: sponsorId,
        ...(nameUpdated && { name: submitData.name }),
        ...(logoUpdated && { logo: submitData.logo })
      };

      await callMutationWithToastMessages(editSponsorMutation, messages, {
        variables: {
          sponsor: mutationOptions
        }
      }).then(() => {
        navigate('/sponsors');
      });
    }
  };

  return loading ? (
    <EMLoadingIcon data-testid="emLoadingIcon" />
  ) : (
    <>
      <h2 className="contentNarrow">Edit Sponsor</h2>
      <SponsorForm
        defaultValues={defaultValues}
        loading={submitting}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default EditSponsor;
