import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

import apolloCacheConfig from './apolloCacheConfig';
import getCookie from './getCookie';

// If any request 401s, log user out completely
const logoutLink = onError(({ networkError }: ErrorResponse) => {
  if (
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 401
  ) {
    // window.location.replace('/login'); // TODO make this URL a constant, somewhere, later
  }
});

const uploadLink = createUploadLink({
  credentials: 'same-origin',
  headers: {
    'Apollo-Require-Preflight': 'true',
    'Csrf-Token': getCookie('__Host-Xsrf-Token')
  },
  uri: '/api/graphql'
}) as unknown as ApolloLink;

const client = new ApolloClient({
  cache: new InMemoryCache(apolloCacheConfig),
  link: logoutLink.concat(uploadLink)
});

export default client;
