import { Button, Input } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Controller, DefaultValues, useForm } from 'react-hook-form';

import {
  useVerifyBankAccountMutation,
  VerifyBankAccountInput
} from '../../../../__generated__';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import { setFieldProps } from '../../../../utils/formHelpers';
import { verifyBankAccountSchema } from '../validation';

const toastMessages = {
  error: 'An error occurred while attempting to verify the bank account',
  loading: 'Verifying bank account',
  success: 'Bank account verified'
};

export interface Props {
  bankAccountId: string;
  dwollaCustomerId: string;
}

const defaultValues = {
  amount1: '',
  amount2: ''
};

const VerifyBankAccount: React.FC<Props> = ({
  bankAccountId,
  dwollaCustomerId
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<DefaultValues<VerifyBankAccountInput>>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(verifyBankAccountSchema)
  });

  const [verifyBankAccount, verifyBankAccountState] =
    useVerifyBankAccountMutation();

  const loading = verifyBankAccountState.loading;

  const onSubmit = async (submitData: Partial<VerifyBankAccountInput>) => {
    await callMutationWithToastMessages(verifyBankAccount, toastMessages, {
      variables: {
        bankAccountInput: {
          amount1: submitData.amount1 as string,
          amount2: submitData.amount2 as string,
          id: bankAccountId as string
        },
        dwollaCustomerId: dwollaCustomerId as string
      }
    });
    reset();
  };

  return (
    <Container>
      <h4 data-testid="verifyBankAccountHeading">Verify Bank Account</h4>
      <form
        data-testid="verifyBankAccountForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row>
          <Col lg={6} md={6}>
            <Controller
              control={control}
              name="amount1"
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  label="Amount 1"
                  mask="0.09"
                />
              )}
            />
          </Col>
          <Col lg={6} md={6}>
            <Controller
              control={control}
              name="amount2"
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  label="Amount 2"
                  mask="0.09"
                />
              )}
            />
          </Col>
        </Row>
        <Button className="floatRight" loading={loading} type="submit">
          Verify
        </Button>
      </form>
    </Container>
  );
};

export default VerifyBankAccount;
