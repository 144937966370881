import { DocumentMetaAssociate } from '__generated__';
import SelectOption from 'types/SelectOption';

export const AssociateToOptions: SelectOption[] = [
  { label: 'User', value: DocumentMetaAssociate.User },
  { label: 'Account', value: DocumentMetaAssociate.Account }
];

export const ColumnHeaders: string[] = [
  'File Name',
  'Type',
  'Document Type',
  'Associate To',
  'File Count',
  'Uploaded By',
  'Actions'
];
