import { ErrorLabel, Input, Select } from '@equitymultiple/react-eui';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
  UseFormClearErrors,
  UseFormGetValues
} from 'react-hook-form';

import {
  AllocationMethod,
  CreatePeriodInput,
  TransactionTypeCode
} from '../../../__generated__';
import { enumToSelectOptions } from '../../../utils/enumToSelectOptions';
import { setFieldProps } from '../../../utils/formHelpers';
import * as styles from './AllocationFields.module.scss';

interface Props {
  clearErrors: UseFormClearErrors<CreatePeriodInput>;
  control: Control<CreatePeriodInput>;
  errors: FieldErrors<CreatePeriodInput>;
  getValues: UseFormGetValues<CreatePeriodInput>;
  isSubmitted: boolean;
}

const inputMaskOptions = {
  autoUnmask: false,
  clearIncomplete: true,
  removeMaskOnSubmit: false
};

const percentTransactionTypeCodes = {
  AssetManagementFee: TransactionTypeCode.AssetManagementFee,
  OriginationFee: TransactionTypeCode.OriginationFee,
  ReferralBonus: TransactionTypeCode.ReferralBonus
};

const allocationMethodOptions = enumToSelectOptions(AllocationMethod);
const regularTransactionTypeCodeOptions =
  enumToSelectOptions(TransactionTypeCode);
const percentTransactionTypeCodeOptions = enumToSelectOptions(
  percentTransactionTypeCodes
);

const AllocationForm: React.FC<Props> = ({
  clearErrors,
  control,
  errors,
  getValues,
  isSubmitted
}) => {
  const {
    append,
    fields: allocations,
    remove,
    update
  } = useFieldArray({
    control,
    name: 'allocations'
  });

  const isAllocationProRata = (index: number) => {
    return getValues().allocations[index].method === AllocationMethod.ProRata;
  };

  const isAllocationFlat = (index: number) => {
    return getValues().allocations[index].method === AllocationMethod.Flat;
  };

  const isAllocationPercent = (index: number) => {
    return getValues().allocations[index].method === AllocationMethod.Percent;
  };

  const renderTransactions = () => {
    return allocations.map((allocation, index) => {
      const isProRata = isAllocationProRata(index);
      const isFlat = isAllocationFlat(index);
      const isPercent = isAllocationPercent(index);

      return (
        <Row className="margin20" key={allocation.id}>
          <Col lg={11} md={10.5} sm={9.5}>
            <Row>
              <Col md={4}>
                <Controller
                  control={control}
                  name={`allocations.${index}.type`}
                  render={({ field }) => (
                    <Select
                      {...setFieldProps(field, errors)}
                      label="Transaction Type"
                      onChange={e => field.onChange(e)}
                      options={
                        isPercent
                          ? percentTransactionTypeCodeOptions
                          : regularTransactionTypeCodeOptions
                      }
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  control={control}
                  name={`allocations.${index}.amount`}
                  render={({ field }) => (
                    <Input
                      {...setFieldProps(field, errors)}
                      allowDecimal
                      disabled={!isProRata}
                      dollarMask={isProRata}
                      inputMaskOptions={inputMaskOptions}
                      label="Amount (Gross)"
                      // For some reason, useForm uses the onChange handler
                      // for onBlur by default. This produces weird behavior
                      // during validation, so it's been nooped
                      onBlur={() => {}}
                      placeholder={isProRata ? '$0' : '$TBD'}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  control={control}
                  name={`allocations.${index}.method`}
                  render={({ field }) => (
                    <Select
                      {...setFieldProps(field, errors)}
                      label="Allocation Method"
                      onChange={e => {
                        field.onChange(e);
                        update(index, {
                          ...getValues().allocations[index],
                          amount: '',
                          value: ''
                        });
                        clearErrors([
                          `allocations.${index}.amount`,
                          `allocations.${index}.value`,
                          `allocations.${index}.type`
                        ]);
                      }}
                      options={allocationMethodOptions}
                    />
                  )}
                />
                {isFlat && (
                  <Controller
                    control={control}
                    name={`allocations.${index}.value`}
                    render={({ field }) => (
                      <Input
                        {...setFieldProps(field, errors)}
                        allowDecimal
                        dollarMask
                        inputMaskOptions={inputMaskOptions}
                        label="Value"
                        // For some reason, useForm uses the onChange handler
                        // for onBlur by default. This produces weird behavior
                        // during validation, so it's been nooped
                        onBlur={() => {}}
                        placeholder="$0"
                      />
                    )}
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col lg={1} md={1.5} sm={2.5}>
            <div className={styles.allocationDeleteWrapper}>
              <button
                className="textLink red margin20 underline"
                data-testid={`allocations.${index}.delete`}
                onClick={() => remove(index)}
                type="button"
              >
                Delete
              </button>
            </div>
          </Col>
        </Row>
      );
    });
  };

  const hasAllocationsError = errors.allocations && errors.allocations.message;

  const addTransaction = () => {
    append(
      {
        amount: '',
        method: AllocationMethod.ProRata,
        type: '',
        value: ''
      },
      { shouldFocus: false }
    );

    if (hasAllocationsError) {
      clearErrors('allocations');
    }
  };

  return (
    <>
      <h4>Transaction(s)</h4>
      <p>Add the transactions for this period</p>
      <div data-testid="transactionsContainer">{renderTransactions()}</div>
      {hasAllocationsError && isSubmitted && (
        <ErrorLabel message={errors.allocations?.message} />
      )}
      <button
        className="textLink margin30 underline"
        onClick={addTransaction}
        type="button"
      >
        Add Another Transaction
      </button>
    </>
  );
};

export default AllocationForm;
