import {
  Button,
  Card,
  DateSelect,
  EMLoadingIcon
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  CreatePeriodInput,
  EditPeriodInput,
  useGetOfferingForPaymentQuery
} from '../../../__generated__';
import * as loadingIconStyles from '../../../styles/components/EMLoadingIcon.module.scss';
import { setDateSelectFieldProps } from '../../../utils/formHelpers';
import AllocationFields from './AllocationFields';
import { periodSchema } from './validation';

export interface Props {
  dataLoading?: boolean;
  dataSubmitting?: boolean;
  defaultValues: Record<string, unknown>;
  headingText(title?: null | string): string;
  onSubmit(submitData: CreatePeriodInput | EditPeriodInput): unknown;
}

const PeriodForm: React.FC<Props> = ({
  dataLoading,
  dataSubmitting,
  defaultValues,
  headingText,
  onSubmit
}) => {
  const { offeringId } = useParams();
  const navigate = useNavigate();

  const {
    data: offeringData,
    error: offeringError,
    loading: offeringLoading
  } = useGetOfferingForPaymentQuery({
    variables: {
      offeringId: offeringId as string
    }
  });

  const loading = offeringLoading || dataLoading;
  const hasError = offeringError || offeringData?.offering.error;
  const showForm = !loading && !hasError;
  const offeringTitle = offeringData?.offering?.offering?.title;

  useEffect(() => {
    if (hasError) {
      toast.error('Offering not found');
      navigate(`/payments`);
    }
  }, [hasError, navigate]);

  const {
    clearErrors,
    control,
    formState: { errors, isSubmitted, isSubmitting },
    getValues,
    handleSubmit,
    reset
  } = useForm<CreatePeriodInput | EditPeriodInput>({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(periodSchema),
    shouldFocusError: false
  });

  useEffect(() => {
    if (defaultValues) {
      reset({ ...defaultValues });
    }
  }, [defaultValues, reset]);

  const submitting = isSubmitting || dataSubmitting;

  return (
    <>
      <h2>{headingText(offeringTitle)}</h2>
      {showForm ? (
        <Card>
          <form data-testid="periodForm" onSubmit={handleSubmit(onSubmit)}>
            <h4>Period Dates</h4>
            <p>
              Enter the period end date and an optional start date for the
              transactions you want to create.
            </p>
            <Row>
              <Col md={6} xl={4}>
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field }) => (
                    <DateSelect
                      {...setDateSelectFieldProps(field, errors)}
                      anyDate
                      label="Start Date (Optional)"
                    />
                  )}
                />
              </Col>
              <Col md={6} xl={4}>
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field }) => (
                    <DateSelect
                      {...setDateSelectFieldProps(field, errors)}
                      anyDate
                      label="End Date"
                    />
                  )}
                />
              </Col>
            </Row>
            <AllocationFields
              clearErrors={clearErrors}
              control={control}
              errors={errors}
              getValues={getValues}
              isSubmitted={isSubmitted}
            />
            <div>
              <Row>
                <Col className="alignItemsCenter">
                  <Link to={`/payments/schedule/${offeringId}/transactions`}>
                    Back
                  </Link>
                </Col>
                <Col>
                  <Button
                    className="floatRight"
                    loading={submitting}
                    type="submit"
                  >
                    Continue
                  </Button>
                </Col>
              </Row>
            </div>
          </form>
        </Card>
      ) : (
        <EMLoadingIcon
          className={loadingIconStyles.cardLoader}
          data-testid="emLoadingIcon"
        />
      )}
    </>
  );
};

export default PeriodForm;
