import { Card } from '@equitymultiple/react-eui';
import Stack from '@mui/material/Stack';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Link, useParams } from 'react-router-dom';

import ApiTokens from './ApiTokens/ApiTokens';
import * as style from './Configurations.module.scss';
import Documents from './Documents/Documents';
import { DwollaConfiguration } from './DwollaConfiguration/DwollaConfiguration';
import InvestPage from './InvestPage/InvestPage';
import TaxTracker from './TaxTrackers/TaxTracker';
import Transactions from './Transactions/Transactions';

const Configurations: React.FC = () => {
  const { page } = useParams();

  const ConfigPage = () => {
    switch (page) {
      case 'documents':
        return <Documents />;
      case 'transactions':
        return <Transactions />;
      case 'dwolla_configuration':
        return <DwollaConfiguration />;
      case 'api_tokens':
        return <ApiTokens />;
      case 'tax_tracker':
        return <TaxTracker />;
      case 'invest_page':
        return <InvestPage />;
      default:
        return <Documents />;
    }
  };

  const isActive = (pageName: string) => {
    return (
      (page === undefined && pageName === 'documents') || page === pageName
    );
  };

  return (
    <>
      <h1 data-testid="configurationsHeading">Configurations</h1>
      <Row className={style.configurationsRow}>
        <Col lg={3} xl={2}>
          <Card className={style.sidebarCard}>
            <Stack
              alignItems="flex-start"
              direction="column"
              justifyContent="flex-start"
              spacing={2}
            >
              <div data-testid="documentsLink">
                <Link
                  className={isActive('documents') ? style.linkSelected : ''}
                  to="/configuration/documents"
                >
                  Documents
                </Link>
              </div>
              <div data-testid="transactionsLink">
                <Link
                  className={isActive('transactions') ? style.linkSelected : ''}
                  to="/configuration/transactions"
                >
                  Transactions
                </Link>
              </div>
              <div data-testid="dwollaConfigurationLink">
                <Link
                  className={
                    isActive('dwolla_configuration') ? style.linkSelected : ''
                  }
                  to="/configuration/dwolla_configuration"
                >
                  Dwolla Configuration
                </Link>
              </div>
              <div data-testid="apiTokensLink">
                <Link
                  className={isActive('api_tokens') ? style.linkSelected : ''}
                  to="/configuration/api_tokens"
                >
                  API Tokens
                </Link>
              </div>
              <div data-testid="taxTrackerLink">
                <Link
                  className={isActive('tax_tracker') ? style.linkSelected : ''}
                  to="/configuration/tax_tracker"
                >
                  Tax Tracker
                </Link>
              </div>
              <div data-testid="investPageLink">
                <Link
                  className={isActive('invest_page') ? style.linkSelected : ''}
                  to="/configuration/invest_page"
                >
                  Invest Page
                </Link>
              </div>
            </Stack>
          </Card>
        </Col>
        <Col lg={9} xl={10}>
          <Card>
            <ConfigPage />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Configurations;
