import * as yup from 'yup';

import { yupString } from '../../../../utils/validations';

export const postAuthorSchema = yup.object().shape({
  avatar: yup.mixed().required('Avatar is required'),
  email: yupString.required('Email is required'),
  firstName: yupString.required('First name is required'),
  lastName: yupString.required('Last name is required'),
  title: yupString.required('Title is required')
});
