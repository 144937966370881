import { Button, FileUploader } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Control, Controller, FieldErrors, useForm } from 'react-hook-form';

import {
  Document,
  DocumentType,
  InvestmentAccountDocumentType,
  InvestmentAccountType,
  Maybe,
  namedOperations,
  useUploadInvestmentAccountDocumentMutation
} from '../../../../../__generated__';
import callMutationWithToastMessages from '../../../../../utils/callMutationWithToastMessages';
import cloneObject from '../../../../../utils/cloneObject';
import { snakeToCamelCase } from '../../../../../utils/stringFormatting';
import BeneficialOwnerDocuments from './BeneficialOwnerDocuments';
import { DocFieldKey, FormValues, IdFields as IdFieldsType } from './constants';
import IdFields from './IdFields';
import {
  entityAccountFormSchema,
  individualAccountFormSchema,
  jointAccountFormSchema
} from './validation';

const messages = {
  error: 'An error occurred while updating the account documents',
  loading: 'Updating account documents',
  success: 'Account documents updated'
};

type Documents = Maybe<Array<Maybe<Document>>>;

interface Props {
  accountId: string;
  accountType: InvestmentAccountType;
  documents?: Documents;
}

const getAccountSchema = (accountType: InvestmentAccountType) => {
  switch (accountType) {
    case 'entity':
      return entityAccountFormSchema;
    case 'joint_account':
      return jointAccountFormSchema;
    default:
      return individualAccountFormSchema;
  }
};

const getDefaultValues = (
  accountType: InvestmentAccountType,
  accountId: string,
  documents: Documents
) => {
  // Account holder ID

  const idDoc = documents?.find(
    document =>
      document?.label === InvestmentAccountDocumentType.IdentificationDocument
  );

  const fields: FormValues = {
    identificationDocument: {
      attachment: idDoc
        ? {
            name: idDoc.filename,
            url: idDoc.url
          }
        : null,
      documentType: InvestmentAccountDocumentType.IdentificationDocument,
      expiry: idDoc?.expiry || '',
      idType: (idDoc?.meta?.idType as DocumentType) || null
    },
    investmentAccountId: accountId
  };

  // Secondary account holder ID for joint accounts

  if (accountType === 'joint_account') {
    const id2Doc = documents?.find(
      document =>
        document?.label ===
        InvestmentAccountDocumentType.SecondaryIdentityDocument
    );

    fields.secondaryIdentityDocument = {
      attachment: id2Doc
        ? {
            name: id2Doc.filename,
            url: id2Doc.url
          }
        : null,
      documentType: InvestmentAccountDocumentType.SecondaryIdentityDocument,
      expiry: id2Doc?.expiry || '',
      idType: (id2Doc?.meta?.idType as DocumentType) || null
    };
  }

  // Entity & EIN evidence documents for entity accounts

  if (accountType === 'entity') {
    // Entity evidence

    const entityDoc = documents?.find(
      document =>
        document?.label === InvestmentAccountDocumentType.EntityEvidenceDocument
    );

    fields.entityEvidenceDocument = {
      attachment: entityDoc
        ? {
            name: entityDoc.filename,
            url: entityDoc.url
          }
        : null,
      documentType: InvestmentAccountDocumentType.EntityEvidenceDocument
    };

    // EIN evidence

    const einDoc = documents?.find(
      document =>
        document?.label === InvestmentAccountDocumentType.EinEvidenceDocument
    );

    fields.einEvidenceDocument = {
      attachment: einDoc
        ? {
            name: einDoc.filename,
            url: einDoc.url
          }
        : null,
      documentType: InvestmentAccountDocumentType.EinEvidenceDocument
    };
  }

  return fields;
};

const getAccountFields = (
  accountType: InvestmentAccountType,
  control: Control<FormValues>,
  errors: FieldErrors<FormValues>
) => {
  const fieldProps = { control, errors };

  switch (accountType) {
    case 'individual':
      return (
        <>
          <IdFields {...fieldProps} />
        </>
      );
    case 'joint_account':
      return (
        <>
          <h4>Primary Account Holder</h4>
          <IdFields {...fieldProps} />
          <h4>Secondary Account Holder</h4>
          <IdFields {...fieldProps} isSecondary />
        </>
      );
    case 'entity':
      return (
        <>
          <IdFields {...fieldProps} />
          <h5>Organizational Evidence Document</h5>
          <Row>
            <Col id="entityEvidenceDocument" lg={6} md={8}>
              <Controller
                control={control}
                name="entityEvidenceDocument.attachment"
                render={({ field }) => (
                  <FileUploader
                    {...field}
                    acceptedFileTypes={['JPG', 'PNG', 'PDF']}
                    errorMessage={
                      errors.entityEvidenceDocument?.attachment
                        ?.message as string
                    }
                    existingFile={field.value}
                    onRemove={() => field.onChange(null)}
                    showImage
                    showLink
                    upload={(uploadData: File) => field.onChange(uploadData)}
                  />
                )}
              />
            </Col>
          </Row>
          <h5>EIN Evidence Document</h5>
          <Row>
            <Col id="einEvidenceDocument" lg={6} md={8}>
              <Controller
                control={control}
                name="einEvidenceDocument.attachment"
                render={({ field }) => (
                  <FileUploader
                    {...field}
                    acceptedFileTypes={['JPG', 'PNG', 'PDF']}
                    errorMessage={
                      errors.einEvidenceDocument?.attachment?.message as string
                    }
                    existingFile={field.value}
                    onRemove={() => field.onChange(null)}
                    showImage
                    showLink
                    upload={(uploadData: File) => field.onChange(uploadData)}
                  />
                )}
              />
            </Col>
          </Row>
        </>
      );
    default:
      return null;
  }
};

const DocumentsForm: React.FC<Props> = ({
  accountId,
  accountType,
  documents
}) => {
  const accountSchema = getAccountSchema(accountType as InvestmentAccountType);
  const accountDefaultValues = getDefaultValues(
    accountType,
    accountId,
    documents as Documents
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm<FormValues>({
    defaultValues: accountDefaultValues,
    mode: 'onBlur',
    resolver: yupResolver(accountSchema)
  });
  const [
    uploadInvestmentAccountDocument,
    uploadInvestmentAccountDocumentState
  ] = useUploadInvestmentAccountDocumentMutation();
  const { loading } = uploadInvestmentAccountDocumentState;

  const onSubmit = (values: FormValues) => {
    // Create an array of document data to iterate through and call uploadInvestmentAccountDocument on
    const documentsToSubmit = [];

    // This will not clone any files, so they're manually included from the values if the file has changed
    // Cloning prevents operating on the form values directly and removing the file if it hasn't changed
    // which would cause an invisible error if the form is submitted twice
    const submittedValues = cloneObject(values);

    // Primary account holder ID
    if (values.identificationDocument) {
      const idUpdated =
        values.identificationDocument?.attachment instanceof File;
      if (idUpdated)
        (submittedValues.identificationDocument as IdFieldsType).attachment =
          values.identificationDocument.attachment;
      else delete submittedValues.identificationDocument?.attachment;
      documentsToSubmit.push(submittedValues.identificationDocument);
    }

    // Secondary account holder ID
    if (values.secondaryIdentityDocument) {
      const idUpdated =
        values.secondaryIdentityDocument?.attachment instanceof File;
      if (idUpdated)
        (submittedValues.secondaryIdentityDocument as IdFieldsType).attachment =
          values.secondaryIdentityDocument.attachment;
      else delete submittedValues.secondaryIdentityDocument?.attachment;
      documentsToSubmit.push(submittedValues.secondaryIdentityDocument);
    }

    // Entity evidence
    if (values.entityEvidenceDocument) {
      const docUpdated =
        values.entityEvidenceDocument?.attachment instanceof File;
      if (docUpdated) documentsToSubmit.push(values.entityEvidenceDocument);
    }

    // EIN evidence
    if (values.einEvidenceDocument) {
      const docUpdated = values.einEvidenceDocument?.attachment instanceof File;
      if (docUpdated) documentsToSubmit.push(values.einEvidenceDocument);
    }

    documentsToSubmit.forEach(document => {
      const variables = {
        documentInput: {
          documentType: document?.documentType as InvestmentAccountDocumentType,
          investmentAccountId: submittedValues.investmentAccountId,
          ...document
        }
      };

      callMutationWithToastMessages(uploadInvestmentAccountDocument, messages, {
        refetchQueries: [namedOperations.Query.getInvestmentAccountDocuments],
        variables
      }).then(res => {
        // Replace the file in the form value with the attachment object that the server returns
        if (document?.attachment) {
          const docType = document.documentType;
          const updatedDoc =
            res.data?.uploadInvestmentAccountDocument?.documents?.find(
              (doc: Document) => doc?.label === docType
            );

          if (updatedDoc) {
            const docFieldKey = snakeToCamelCase(docType);

            setValue(`${docFieldKey as DocFieldKey}.attachment`, {
              name: updatedDoc.filename,
              url: updatedDoc.url
            });
          }
        }
      });
    });
  };

  return (
    <>
      <hr />
      <h3>Documents</h3>
      <form
        className="clearFix"
        data-testid="investmentAccountDocumentsForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="margin30">
          {getAccountFields(accountType, control, errors)}
          {accountType === 'entity' && (
            <BeneficialOwnerDocuments accountId={accountId} />
          )}
        </div>
        <Button className="floatRight" loading={loading} type="submit">
          Save Account Documents
        </Button>
      </form>
    </>
  );
};

export default DocumentsForm;
