import {
  Button,
  Card,
  Checkbox,
  EMLoadingIcon,
  ErrorLabel,
  Input,
  RadioButton,
  Table
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  ClosingStage,
  EditPeriodInput,
  namedOperations,
  PeriodSelectionType,
  useEditPeriodMutation,
  useGetPeriodAllocationDataQuery
} from '../../../__generated__';
import * as loadingIconStyles from '../../../styles/components/EMLoadingIcon.module.scss';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import formatCurrency from '../../../utils/formatCurrency';
import { enumValueToLabel } from '../../../utils/stringFormatting';
import { normalizeEditPeriodInput } from '../transactionCodeMapper';
import * as styles from './PeriodAllocation.module.scss';
import { periodAllocationSchema } from './validation';

const closingsTableHeaders = ['Select', 'Closing', 'Stage', 'Investors'];
const investorsTableHeaders = ['Select', 'Name', 'Closing', 'Amount'];

const editPeriodMessages = {
  error: 'An error occurred while updating a period',
  loading: 'Updating period',
  success: 'Period updated'
};

const PeriodAllocation: React.FC = () => {
  const offeringId = useParams().offeringId as string;
  const periodId = useParams().periodId as string;
  const [searchValue, setSearchValue] = useState('');
  const [closingSelections, setClosingSelections] = useState<string[]>([]);
  const [investorSelections, setInvestorSelections] = useState<string[]>([]);
  const [editPeriod, editPeriodState] = useEditPeriodMutation();
  const navigate = useNavigate();

  const queryState = useGetPeriodAllocationDataQuery({
    fetchPolicy: 'no-cache',
    variables: {
      offeringId: offeringId,
      periodId: periodId
    }
  });

  const { data } = queryState;
  const { loading } = queryState;
  const offering = data?.offering?.offering;
  const period = data?.period?.period;
  const closings = data?.closings.closings;
  const periodInvestors = data?.periodInvestors?.data;

  const hasError =
    queryState.error ||
    data?.offering?.error ||
    data?.period?.error ||
    data?.closings?.error ||
    data?.periodInvestors?.error;

  useEffect(() => {
    if (hasError) {
      if (queryState.error) {
        toast.error(queryState.error.message);
        navigate(`/payments`);
      } else if (
        data?.offering?.error ||
        data?.closings?.error ||
        data?.periodInvestors?.error
      ) {
        toast.error('Offering not found');
        navigate(`/payments`);
      } else {
        toast.error('Period not found');
        navigate(`/payments/schedule/${offeringId}/transactions`);
      }
    }
  }, [hasError, navigate, queryState.error, data, offeringId]);

  const getDefaultValues = useCallback(
    () => ({
      allocations: period?.allocations.map(allocation => ({
        amount: allocation.amount,
        method: allocation.method,
        type: allocation.type.toString(),
        value: allocation.value
      })),
      endDate: period?.endDate,
      id: periodId,
      offeringId: offeringId,
      selection: {
        type: period?.selection?.type ?? PeriodSelectionType.All,
        value: period?.selection?.value
      },
      startDate: period?.startDate
    }),
    [period, offeringId, periodId]
  );

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch
  } = useForm<EditPeriodInput>({
    defaultValues: getDefaultValues(),
    mode: 'onSubmit',
    resolver: yupResolver(periodAllocationSchema)
  });

  useEffect(() => {
    if (period) {
      reset({ ...getDefaultValues() });
    }
  }, [getDefaultValues, period, reset]);

  const getClosingsRows = () => {
    if (closings) {
      return closings.map(closing => {
        return {
          cells: [
            <Controller
              control={control}
              key={`selection-${closing.id}`}
              name={`selection.value`}
              render={({ field }) => (
                <Checkbox
                  checked={field.value?.some(option => option === closing.id)}
                  id={closing.id}
                  label=""
                  onChange={e => {
                    const valueCopy = field.value ? [...field.value] : [];
                    if (e.target.checked) {
                      valueCopy.push(closing.id);
                    } else {
                      const valueIndex = valueCopy.indexOf(closing.id);
                      valueCopy.splice(valueIndex, 1);
                    }
                    field.onChange(valueCopy);
                  }}
                />
              )}
            />,
            closing.name,
            enumValueToLabel(ClosingStage, closing.stage),
            closing.completedInvestments
          ]
        };
      });
    } else {
      return [{ cells: ['No closings found'] }];
    }
  };

  const renderClosings = () => {
    return (
      <>
        <hr />
        <h4>Select Closing(s)</h4>
        <Table
          columnHeaders={closingsTableHeaders}
          loading={loading}
          rows={getClosingsRows()}
        />
      </>
    );
  };

  const getInvestorRows = () => {
    if (periodInvestors) {
      let filteredInvestors = [...periodInvestors];
      if (searchValue) {
        filteredInvestors = periodInvestors.filter(investor =>
          investor.name.toLowerCase().includes(searchValue.toLowerCase())
        );
      }
      return filteredInvestors.map(investor => {
        return {
          cells: [
            <Controller
              control={control}
              key={`selection-${investor.id}`}
              name={`selection.value`}
              render={({ field }) => (
                <Checkbox
                  checked={field.value?.some(option => option === investor.id)}
                  id={investor.id}
                  label=""
                  onChange={e => {
                    const valueCopy = field.value ? [...field.value] : [];
                    if (e.target.checked) {
                      valueCopy.push(investor.id);
                    } else {
                      const valueIndex = valueCopy.indexOf(investor.id);
                      valueCopy.splice(valueIndex, 1);
                    }
                    field.onChange(valueCopy);
                  }}
                />
              )}
            />,
            investor.name,
            investor.closing,
            formatCurrency(investor.amount)
          ]
        };
      });
    } else {
      return [{ cells: ['No investors found'] }];
    }
  };

  const renderInvestors = () => {
    return (
      <>
        <hr />
        <h4>Select Investor(s)</h4>
        <Input
          id="sponsorSearch"
          label="Search"
          onChange={event => setSearchValue(event.target.value)}
          placeholder="Investor Name"
          value={searchValue}
        />
        <Table
          columnHeaders={investorsTableHeaders}
          loading={loading}
          rows={getInvestorRows()}
        />
      </>
    );
  };

  const handleSelectionTypeChange = (newSelectionType: string) => {
    const previousSelectionType = getValues('selection.type');

    switch (previousSelectionType) {
      case PeriodSelectionType.Closing:
        setClosingSelections(getValues('selection.value') as string[]);
        break;
      case PeriodSelectionType.Investment:
        setInvestorSelections(getValues('selection.value') as string[]);
        break;
      default:
        break;
    }

    switch (newSelectionType) {
      case PeriodSelectionType.All:
        setValue('selection.value', []);
        break;
      case PeriodSelectionType.Closing:
        setValue('selection.value', closingSelections);
        break;
      case PeriodSelectionType.Investment:
        setValue('selection.value', investorSelections);
        break;
      default:
        break;
    }
  };

  const valueError =
    errors.selection && 'value' in errors.selection
      ? errors.selection.value
      : null;

  const selectionType = watch('selection.type');

  const onSubmit = async (submitData: EditPeriodInput) => {
    const isValid = await periodAllocationSchema.isValid(submitData);
    if (isValid) {
      const periodUpdate = normalizeEditPeriodInput({
        ...getDefaultValues(),
        selection: submitData.selection
      } as EditPeriodInput);

      const { data: editPeriodData } = await callMutationWithToastMessages(
        editPeriod,
        editPeriodMessages,
        {
          refetchQueries: [
            // These include objects other than Period and are still needed
            namedOperations.Query.getPeriodAllocationData,
            namedOperations.Query.getAllocationSummary,
            namedOperations.Query.getPaymentPeriods
          ],
          variables: {
            period: periodUpdate
          }
        }
      );

      const id = editPeriodData?.editPeriod.period?.id;

      if (id) {
        navigate(`/payments/schedule/${offeringId}/periods/${id}/review`);
      }
    }
  };

  return (
    <>
      <h2 data-testid="periodAllocationHeading">
        Period Allocation{offering?.title && ` - ${offering.title}`}
      </h2>
      {loading ? (
        <EMLoadingIcon
          className={loadingIconStyles.cardLoader}
          data-testid="emLoadingIcon"
        />
      ) : (
        <Card>
          <form
            data-testid="periodAllocationForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <h4>Allocate To</h4>
            <p>Select the investors you would like to allocate to.</p>
            <Controller
              control={control}
              name="selection.type"
              render={({ field }) => (
                <div className={styles.radioButtonContainer}>
                  <RadioButton
                    {...field}
                    checked={field.value === PeriodSelectionType.All}
                    id="selection-type-all"
                    label="All Closings"
                    onChange={e => {
                      e.target.value = PeriodSelectionType.All;
                      handleSelectionTypeChange(PeriodSelectionType.All);
                      field.onChange(e);
                    }}
                  />
                  <RadioButton
                    {...field}
                    checked={field.value === PeriodSelectionType.Closing}
                    id="selection-type-closing"
                    label="Specific Closings"
                    onChange={e => {
                      e.target.value = PeriodSelectionType.Closing;
                      handleSelectionTypeChange(PeriodSelectionType.Closing);
                      field.onChange(e);
                    }}
                  />
                  <RadioButton
                    {...field}
                    checked={field.value === PeriodSelectionType.Investment}
                    id="selection-type-investment"
                    label="Specific Investors"
                    onChange={e => {
                      e.target.value = PeriodSelectionType.Investment;
                      handleSelectionTypeChange(PeriodSelectionType.Investment);
                      field.onChange(e);
                    }}
                  />
                  {valueError && (
                    <ErrorLabel message={valueError.message as string} />
                  )}
                </div>
              )}
            />
            {selectionType === PeriodSelectionType.Closing && renderClosings()}
            {selectionType === PeriodSelectionType.Investment &&
              renderInvestors()}
            <Row className="marginTop30">
              <Col className="alignItemsCenter">
                <Link
                  to={`/payments/schedule/${offeringId}/periods/${periodId}/edit`}
                >
                  Back
                </Link>
              </Col>
              <Col>
                <Button
                  className="floatRight"
                  loading={editPeriodState.loading}
                  type="submit"
                >
                  Continue
                </Button>
              </Col>
            </Row>
          </form>
        </Card>
      )}
    </>
  );
};

export default PeriodAllocation;
