import { DwollaCustomer } from '__generated__';

// Max file size for uploads, currently 10mb
export const maxUploadSize = 1024 * 1024 * 10;

// File extensions and their associated formats
// Some file exentions such as DOC or XLS have multiple accepted formats, in these cases we just use the extension
export const fileTypes = [
  {
    extension: 'JPG',
    format: 'image/jpeg'
  },
  {
    extension: 'PDF',
    format: 'application/pdf'
  },
  {
    extension: 'PNG',
    format: 'image/png'
  },
  {
    extension: 'ZIP',
    format: 'zip,application/zip,application/x-zip,application/x-zip-compressed'
  },
  {
    extension: 'DOC',
    format: '.doc'
  },
  {
    extension: 'DOCX',
    format: '.docx'
  },
  {
    extension: 'XLS',
    format: '.xls'
  },
  {
    extension: 'XLSX',
    format: '.xlsx'
  }
];

// Dataclips URL
export const dataClipsUrl = process.env.REACT_APP_DATACLIPS_URL;

const getMarketplaceHost = () => {
  if (!dataClipsUrl) return '.em.dev';

  if (dataClipsUrl.includes('eqm-int')) {
    return '.equitymultiple.com';
  }

  return dataClipsUrl?.split('dataclips')[1];
};

export const marketplaceRoot = `https://app${getMarketplaceHost()}`;

export const dwollaCustomerFundingSourcesLink = (customer: DwollaCustomer) => {
  let dwollaHost = 'https://dashboard-sandbox.dwolla.com';

  const appHost = getMarketplaceHost();
  if (appHost === '.equitymultiple.com') {
    dwollaHost = 'https://dashboard.dwolla.com';
  }

  return `${dwollaHost}/customers/${customer.customerId}/funding-sources`;
};
