import { Button, Card, Input, Table } from '@equitymultiple/react-eui';
import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useNavigate } from 'react-router-dom';

import { PostAuthor, useGetPostAuthorsQuery } from '../../../__generated__';
import DeletePostAuthorModal from './DeletePostAuthorModal';
import PostAuthorActions from './PostAuthorActions';
import * as styles from './postAuthors.module.scss';

const PostAuthors: React.FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedPostAuthor, setSelectedPostAuthor] =
    useState<null | PostAuthor>(null);
  const { data, loading } = useGetPostAuthorsQuery();

  const hasPostAuthors = data?.postAuthors.data;

  const navigate = useNavigate();

  const columnHeaders = [
    'ID',
    'First Name',
    'Last Name',
    'Title',
    'Email',
    'Actions'
  ];

  const setSelectedPostAuthorState = (postAuthor: null | PostAuthor) => {
    setSelectedPostAuthor(postAuthor);
  };

  const handleAddPostAuthor = () => {
    navigate('/post_authors/new');
  };

  const getRows = (postAuthors: Array<PostAuthor>) => {
    const filteredPostAuthors = searchValue
      ? postAuthors.filter(postAuthor => {
          const searchString =
            `${postAuthor.id} ${postAuthor.firstName} ${postAuthor.lastName} ${postAuthor.email}`.toLowerCase();
          return searchString?.includes(searchValue.toLowerCase());
        })
      : postAuthors;

    return filteredPostAuthors.map(postAuthor => {
      const { email, firstName, id, lastName, title } = postAuthor;

      return {
        cells: [
          id,
          firstName,
          lastName,
          title,
          email,
          <PostAuthorActions
            key={id}
            postAuthor={postAuthor as PostAuthor}
            setSelectedPostAuthor={setSelectedPostAuthorState}
          />
        ]
      };
    });
  };

  return (
    <>
      <DeletePostAuthorModal
        selectedPostAuthor={selectedPostAuthor}
        setSelectedPostAuthorState={setSelectedPostAuthorState}
      />
      <Card>
        <Row>
          <Col lg={4} md={6}>
            <Input
              id="postAuthorSearch"
              label="Search"
              onChange={event => setSearchValue(event.target.value)}
              placeholder="Author ID, Name or Email"
              value={searchValue}
            />
          </Col>
          <Col lg={8} md={6}>
            <Button
              className={`floatRight margin20 ${styles.addPostAuthorButton}`}
              data-testid="newPostAuthorButton"
              onClick={handleAddPostAuthor}
            >
              Add Author
            </Button>
          </Col>
        </Row>
        <Table
          columnHeaders={columnHeaders}
          loading={loading}
          loadingRows={10}
          rows={
            hasPostAuthors
              ? getRows(data?.postAuthors.data)
              : [{ cells: ['No post authors found'] }]
          }
        />
      </Card>
    </>
  );
};

export default PostAuthors;
