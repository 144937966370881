import { Button, Modal } from '@equitymultiple/react-eui';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  DwollaCustomer,
  useDeactivateDwollaCustomerMutation
} from '../../../__generated__';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';

export interface Props {
  isModalOpen: boolean;
  selectedDwollaCustomer: DwollaCustomer | null;
  setIsModalOpen: (modalOpen: boolean) => void;
}

const toastMessages = {
  error: 'An error occurred while attempting to deactivate the Dwolla customer',
  loading: 'Deactivating Dwolla customer',
  success: 'Dwolla customer deactivated'
};

const DeactivateAdminAccountModal: React.FC<Props> = ({
  isModalOpen,
  selectedDwollaCustomer,
  setIsModalOpen
}) => {
  const { id } = useParams();

  const [deactivateDwollaCustomer, deactivateDwollaCustomerState] =
    useDeactivateDwollaCustomerMutation({
      variables: {
        dwollaCustomerId: id as string
      }
    });

  const navigate = useNavigate();
  const hideDeactivateModal = () => setIsModalOpen(false);

  const handleDeactivateDwollaCustomer = async () => {
    hideDeactivateModal();

    await callMutationWithToastMessages(
      deactivateDwollaCustomer,
      toastMessages
    );

    navigate('/accounts');
  };

  return (
    <Modal
      closeButtonText="Cancel"
      data-testid="deactivateDwollaCustomerModal"
      loading={deactivateDwollaCustomerState.loading}
      onClose={hideDeactivateModal}
      open={isModalOpen}
      submitButton={
        <Button
          loading={deactivateDwollaCustomerState.loading}
          onClick={() => handleDeactivateDwollaCustomer()}
          variant="orange"
        >
          Yes, Deactivate It
        </Button>
      }
      title={<h6>Are you sure you want to deactivate this Dwolla customer?</h6>}
    >
      <p className="margin30">
        <strong>Business Name:</strong>{' '}
        {selectedDwollaCustomer &&
          (selectedDwollaCustomer as DwollaCustomer).businessName}
        <br />
        <strong>Email: </strong>{' '}
        {/* these spans are added for testing purposes */}
        <span>
          {selectedDwollaCustomer &&
            (selectedDwollaCustomer as DwollaCustomer).email}
        </span>
        <br />
        <strong>Status: </strong>{' '}
        {/* these spans are added for testing purposes */}
        <span>
          {selectedDwollaCustomer &&
            (selectedDwollaCustomer as DwollaCustomer).status}
        </span>
      </p>
    </Modal>
  );
};

export default DeactivateAdminAccountModal;
