import { ToggleableTooltip } from '@equitymultiple/react-eui';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import DeactivateInvestmentAccountModal from './DeactivateInvestmentAccountModal';

export interface Props {
  dwollaCustomerStatus: null | string | undefined;
  email: string;
  hasVerifiedBankAccount: boolean;
  id: string;
}

const showDwollaBalancesLink = (
  status: null | string | undefined,
  hasVerifiedBankAccount: boolean
) => {
  if (status !== 'verified') return false;
  return hasVerifiedBankAccount;
};

const InvestmentAccountActions: React.FC<Props> = ({
  dwollaCustomerStatus,
  email,
  hasVerifiedBankAccount,
  id
}) => {
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);

  const handleToggleDeactivateModal = () => {
    setDeactivateModalOpen(!deactivateModalOpen);
  };

  return (
    <div data-testid="investmentAccountActions">
      {deactivateModalOpen && (
        <DeactivateInvestmentAccountModal
          email={email}
          handleCloseModal={handleToggleDeactivateModal}
          id={id}
        />
      )}
      <ToggleableTooltip data-testid="toggleable-tooltip" placement="leftStart">
        <Link to={`/investment_accounts/${id}/edit`}>Edit</Link>
        <button
          className="textLink underline"
          onClick={handleToggleDeactivateModal}
          type="button"
        >
          Deactivate
        </button>
        {showDwollaBalancesLink(
          dwollaCustomerStatus,
          hasVerifiedBankAccount
        ) && (
          <Link to={`/investment_accounts/${id}/move_dwolla_balances`}>
            Move Dwolla Balances
          </Link>
        )}
      </ToggleableTooltip>
    </div>
  );
};

export default InvestmentAccountActions;
