import React from 'react';
import { Toaster } from 'react-hot-toast';

interface Props {
  children: React.ReactNode;
}

const ToasterWrap: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Toaster
        containerClassName="toasterContainer"
        containerStyle={{
          top: 77
        }}
        position="top-right"
        toastOptions={{
          error: {
            duration: 8000
          },
          style: {
            width: 350
          }
        }}
      />
      {children}
    </>
  );
};

export default ToasterWrap;
