import { Button, Modal } from '@equitymultiple/react-eui';
import React from 'react';
import { getAccountStatus } from 'views/Users/helpers';

import {
  InvestmentAccountStatus,
  useDeactivateInvestmentAccountMutation
} from '../../../../__generated__';
import * as modalStyles from '../../../../styles/components/Modal.module.scss';
import { deleteObjectFromCache } from '../../../../utils/apolloCacheHelpers';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';

const toastMessages = {
  error: 'An error occurred while attempting to deactivate the account',
  loading: 'Deactivating account',
  success: 'Account deactivated'
};

export interface Props {
  handleCloseModal: () => void;
  id: string;
  onDeactivate?: () => void;
  status: string;
}

const DeactivateInvestmentAccountModal: React.FC<Props> = ({
  handleCloseModal,
  id,
  onDeactivate,
  status
}) => {
  const [deactivateInvestmentAccount, deactivateInvestmentAccountState] =
    useDeactivateInvestmentAccountMutation({
      update(cache) {
        deleteObjectFromCache(cache, 'InvestmentAccount', id);
      },
      variables: {
        investmentAccountId: id
      }
    });

  const { loading } = deactivateInvestmentAccountState;

  const handleDeactivateInvestmentAccount = () => {
    callMutationWithToastMessages(
      deactivateInvestmentAccount,
      toastMessages
    ).then(() => {
      handleCloseModal();
      if (onDeactivate) onDeactivate();
    });
  };

  return (
    <Modal
      closeButtonText="Cancel"
      data-testid="deactivateInvestmentAccountModal"
      onClose={handleCloseModal}
      open
      submitButton={
        <Button
          loading={loading}
          onClick={handleDeactivateInvestmentAccount}
          variant="orange"
        >
          Yes, Deactivate It
        </Button>
      }
      title={
        <h6>Are you sure you want to deactivate this investment account?</h6>
      }
    >
      <div className={modalStyles.contentContainer}>
        <div className={modalStyles.contentItem}>
          <p data-testid="deactivateModalAccountId">
            <strong>ID:</strong> {id}
          </p>
          <p data-testid="deactivateModalUserEmail">
            <strong>Status:</strong>{' '}
            {getAccountStatus(status as InvestmentAccountStatus)}
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default DeactivateInvestmentAccountModal;
