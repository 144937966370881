import React from 'react';
import { Link } from 'react-router-dom';

import { PostStatus } from '../../../../__generated__';
import * as styles from '../../../Posts/posts.module.scss';

export interface Props {
  handleDeleteClick: (
    postId: string,
    postTitle: string,
    offeringTitle: string
  ) => void;
  handlePublishClick: (postId: string, postMembersCount: number) => void;
  offeringTitle?: null | string;
  postId: string;
  postMembersCount?: null | number;
  postStatus?: null | string;
  postTitle?: null | string;
}

const PostActions: React.FC<Props> = ({
  handleDeleteClick,
  handlePublishClick,
  offeringTitle,
  postId,
  postMembersCount,
  postStatus,
  postTitle
}) => {
  const publishClick = () => handlePublishClick(postId, postMembersCount ?? 0);
  const deleteClick = () =>
    handleDeleteClick(postId, postTitle ?? '', offeringTitle ?? '');

  return (
    <>
      <Link to={`/posts/${postId}/edit`}>
        <button
          className={`textLink underline ${styles.postAction}`}
          type="button"
          value={postId}
        >
          Edit
        </button>
      </Link>
      <button
        className={`textLink  underline ${styles.postAction} ${styles.deleteAction}`}
        data-testid={`delete-${postId}`}
        onClick={deleteClick}
        type="button"
        value={postId}
      >
        Delete
      </button>
      {postStatus === PostStatus.Draft && (
        <button
          className={`textLink underline ${styles.postAction}`}
          data-testid={`publish-${postId}`}
          onClick={publishClick}
          type="button"
          value={postId}
        >
          Publish
        </button>
      )}
    </>
  );
};

export default PostActions;
