import { Checkbox, DateSelect, Input, Select } from '@equitymultiple/react-eui';
import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormWatch
} from 'react-hook-form';

import {
  EditInvestmentAccountInput,
  InvestmentAccount
} from '../../../../__generated__';
import {
  setCheckboxFieldProps,
  setDateSelectFieldProps,
  setFieldProps
} from '../../../../utils/formHelpers';
import { ssn, ssnMaskOptions } from '../../../../utils/masks';
import { jointSubTypeOptions, stateOptions } from './constants';

interface Props {
  account: InvestmentAccount;
  control: Control<EditInvestmentAccountInput>;
  errors: FieldErrors;
  watch: UseFormWatch<EditInvestmentAccountInput>;
}

const FieldsJoint: React.FC<Props> = ({ account, control, errors, watch }) => {
  const accountHolder2HasSameAddress = watch('accountHolder2.addressIsSame');

  return (
    <div className="firstChildMarginTop0">
      <h3>Account Holder 1</h3>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="subType"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Type of Joint Account"
                onChange={e => field.onChange(e)}
                options={jointSubTypeOptions}
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Input
            disabled
            id="dateCompleted"
            label="Date Completed"
            value={
              account.completedAt
                ? moment(new Date(account.completedAt)).format('MM/DD/YYYY')
                : ''
            }
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="user.phone"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Phone"
                mask="(999) 999-9999 [ext 999999]"
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="user.dob"
            render={({ field }) => (
              <DateSelect
                {...setDateSelectFieldProps(field, errors)}
                label="Date of Birth"
              />
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="user.ssn"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                inputMaskOptions={ssnMaskOptions}
                label="SSN"
                mask={ssn}
              />
            )}
          />
        </Col>
      </Row>

      <h4>Address</h4>

      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="user.address"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Address" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="user.address2"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Address 2 (Optional)"
              />
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="user.city"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="City" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="user.state"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="State"
                onChange={e => {
                  field.onChange(e);
                }}
                options={stateOptions}
              />
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="user.postalCode"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Postal Code"
                mask="9{5}"
              />
            )}
          />
        </Col>
      </Row>

      <h3>Account Holder 2</h3>

      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="accountHolder2.firstName"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="First Name" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="accountHolder2.lastName"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Last Name" />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="accountHolder2.dob"
            render={({ field }) => (
              <DateSelect
                {...setDateSelectFieldProps(field, errors)}
                label="Date of Birth"
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="accountHolder2.email"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Email" />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="accountHolder2.ssn"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                inputMaskOptions={ssnMaskOptions}
                label="SSN"
                mask={ssn}
              />
            )}
          />
        </Col>
      </Row>
      <Controller
        control={control}
        name="accountHolder2.addressIsSame"
        render={({ field }) => (
          <Checkbox
            {...setCheckboxFieldProps(field, errors)}
            className="margin20"
            label="Account holder 2's address is the same"
          />
        )}
      />

      {!accountHolder2HasSameAddress && (
        <>
          <Row>
            <Col lg={4} md={6}>
              <Controller
                control={control}
                name="accountHolder2.address"
                render={({ field }) => (
                  <Input {...setFieldProps(field, errors)} label="Address" />
                )}
              />
            </Col>
            <Col lg={4} md={6}>
              <Controller
                control={control}
                name="accountHolder2.address2"
                render={({ field }) => (
                  <Input
                    {...setFieldProps(field, errors)}
                    label="Address 2 (Optional)"
                  />
                )}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={4} md={6}>
              <Controller
                control={control}
                name="accountHolder2.city"
                render={({ field }) => (
                  <Input {...setFieldProps(field, errors)} label="City" />
                )}
              />
            </Col>
            <Col lg={4} md={6}>
              <Controller
                control={control}
                name="accountHolder2.region"
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    label="State"
                    onChange={e => {
                      field.onChange(e);
                    }}
                    options={stateOptions}
                  />
                )}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={4} md={6}>
              <Controller
                control={control}
                name="accountHolder2.postalCode"
                render={({ field }) => (
                  <Input
                    {...setFieldProps(field, errors)}
                    label="Postal Code"
                    mask="9{5}"
                  />
                )}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default FieldsJoint;
