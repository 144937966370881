import './styles/main.scss';

import 'react-loading-skeleton/dist/skeleton.css';
import '@equitymultiple/react-eui/dist/index.css';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { setConfiguration } from 'react-grid-system';

import AppRoutes from './components/Layout/AppRoutes/AppRoutes';
import { AppProvider } from './providers/App';
import client from './utils/apolloClient';

/**
 * With the exception of the `lg` screen size (1300/1270),`breakpoints` and
 * `containerWidths` are using the RGS defaults.
 *
 * `maxScreenClass` is one level up from the default 'xxl' to ensure hi-res
 * screens take full advantage of any dense content in Admin.
 */
setConfiguration({
  breakpoints: [576, 768, 992, 1300, 1600, 1920],
  containerWidths: [540, 740, 960, 1270, 1540, 1810],
  maxScreenClass: 'xxxl'
});

// Sentry configuration
if (
  process.env.REACT_APP_ENV &&
  process.env.REACT_APP_ENV !== 'local' &&
  process.env.NODE_ENV !== 'test'
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    integrations: [Sentry.browserTracingIntegration()]
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AppProvider>
        <AppRoutes />
      </AppProvider>
    </ApolloProvider>
  </React.StrictMode>
);
