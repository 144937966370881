import { Table } from '@equitymultiple/react-eui';
import React from 'react';
import { Col, Row } from 'react-grid-system';

import {
  DwollaCustomer,
  DwollaFundingSource,
  DwollaFundingSourceStatus,
  useDeleteBankAccountMutation
} from '../../../../__generated__';
import { deleteObjectFromCache } from '../../../../utils/apolloCacheHelpers';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import { capitalize } from '../../../../utils/stringFormatting';
import NewBankAccount from './NewBankAccount';
import VerifyBankAccount from './VerifyBankAccount';

const columnHeaders = ['Name', 'Type', 'Account Number', 'Status', 'Actions'];

const deleteMessages = {
  error: 'An error occurred while deleting the bank account',
  loading: 'Deleting',
  success: 'Bank account deleted'
};

export interface Props {
  dwollaCustomer: DwollaCustomer;
  loading: boolean;
}

const getBankAccountStatusColor = (
  status: DwollaFundingSourceStatus | null | undefined
) => {
  if (status === 'verified') return 'textGreen';
  if (status === 'unverified') return 'textOrange';
  return 'textRed';
};

const BankAccounts: React.FC<Props> = ({ dwollaCustomer, loading }) => {
  const [deleteBankAccount] = useDeleteBankAccountMutation();

  const dwollaFundingSources =
    dwollaCustomer?.fundingSources as DwollaFundingSource[];

  const handleDeleteFundingSource = (
    dwollaCustomerId: string,
    dwollaFundingSourceId: string
  ) => {
    callMutationWithToastMessages(deleteBankAccount, deleteMessages, {
      update(cache) {
        deleteObjectFromCache(
          cache,
          'DwollaFundingSource',
          dwollaFundingSourceId
        );
      },
      variables: {
        bankAccountId: dwollaFundingSourceId,
        dwollaCustomerId: dwollaCustomerId
      }
    });
  };

  const getRows = () => {
    return dwollaFundingSources && dwollaFundingSources.length > 0
      ? dwollaFundingSources.map(dwollaFundingSource => {
          const {
            bankAccount,
            id: dwollaFundingSourceId,
            name,
            primary,
            status
          } = dwollaFundingSource;

          return {
            cells: [
              name || bankAccount?.name || '[Unnamed Bank Account]',
              capitalize(bankAccount?.type || ''),
              bankAccount?.accountNumber || 'XXXXXXXX',
              <div
                className={getBankAccountStatusColor(status)}
                key={dwollaFundingSourceId}
              >
                {capitalize(status || '')}
              </div>,
              primary ? (
                'Primary Account'
              ) : (
                <button
                  className="textLink underline"
                  data-testid={`bankAccountDelete-${dwollaFundingSourceId}`}
                  key={dwollaFundingSourceId}
                  onClick={() =>
                    handleDeleteFundingSource(
                      dwollaCustomer.id as string,
                      dwollaFundingSourceId as string
                    )
                  }
                  type="button"
                >
                  Delete
                </button>
              )
            ],
            collapsibleCell:
              status === DwollaFundingSourceStatus.Unverified ? (
                <VerifyBankAccount
                  bankAccountId={dwollaFundingSourceId as string}
                  dwollaCustomerId={dwollaCustomer?.id as string}
                />
              ) : undefined
          };
        })
      : [{ cells: ['No bank account found'] }];
  };

  return (
    <div data-testid="bankAccounts">
      <h3>Bank Accounts</h3>
      <Row>
        <Col lg={12} xl={8}>
          <Table
            columnHeaders={columnHeaders}
            loading={loading}
            loadingRows={2}
            rows={getRows()}
          />
        </Col>
      </Row>
      <NewBankAccount />
    </div>
  );
};

export default BankAccounts;
