import { buildOptions } from 'utils/formHelpers';
import { formatClosingName } from 'views/Offerings/helpers';

import {
  AccreditationStatus,
  Closing,
  InvestmentStatus
} from '../../../../__generated__';

export const getAccreditationStatusOptions = (): {
  label: string;
  value: string;
}[] => {
  return buildOptions(Object.entries(AccreditationStatus));
};

export const getInvestmentStatusOptions = (): {
  label: string;
  value: string;
}[] => {
  return buildOptions(Object.entries(InvestmentStatus));
};

export const getClosingOptions = (
  closings: Closing[]
): { label: string; value: number }[] => {
  const options: { label: string; uuid: string; value: number }[] = [];
  closings?.forEach(closing => {
    options.push({
      label: formatClosingName(closing),
      uuid: closing.id,
      value: parseInt(closing.id)
    });
  });
  return options;
};
