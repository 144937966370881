import {
  Card,
  FileIcon,
  FilterSelect,
  Input,
  Table
} from '@equitymultiple/react-eui';
import {
  DocumentCategory,
  GetUserDocumentsQueryVariables,
  SortOrder,
  useGetUserDocumentsQuery,
  UserDocument,
  UserDocumentSortField
} from '__generated__';
import { DocumentTypeDbValue } from 'constants/documents';
import useDebounce from 'hooks/useDebounce';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { snakeToTitleCase } from 'utils/stringFormatting';

import * as ovStyles from '../overrides.module.scss';
import DeleteDocumentModal from './DeleteDocumentModal';
import DocumentActions from './DocumentActions';
import * as styles from './Documents.module.scss';

export const columnHeaders = [
  'Label',
  'Category',
  'Type',
  'Sub Type',
  'Date Uploaded',
  ''
];

const columnHeaderToSortField = {
  'Date Uploaded': 'createdAt',
  Label: 'filename',
  'Sub Type': 'documentType',
  Type: 'type'
};

const categoryOptions = [
  { label: 'Account', value: 'account' },
  { label: 'Investment', value: 'investment' },
  { label: 'Tax', value: 'tax' }
];

const Documents = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortField, setSortField] = useState<UserDocumentSortField>(null);
  const [category, setCategory] = useState<DocumentCategory[]>([]);
  const [sortOrder, setSortOrder] = useState<SortOrder>(null);
  const debouncedSearchValue: string = useDebounce<string>(searchValue, 300);
  const [selectedDocument, setSelectedDocument] = useState<null | UserDocument>(
    null
  );

  const getUserDocumentsVariables: GetUserDocumentsQueryVariables = {
    category: category,
    filter: debouncedSearchValue,
    id,
    pagination: {
      page,
      pageSize: rowsPerPage
    }
  };

  if (sortField && sortOrder) {
    getUserDocumentsVariables.sort = {
      field: sortField,
      order: sortOrder
    };
  }

  const {
    data: documentsData,
    error: documentsError,
    fetchMore,
    loading: documentsLoading,
    refetch
  } = useGetUserDocumentsQuery({
    variables: getUserDocumentsVariables
  });

  useEffect(() => {
    if (debouncedSearchValue) refetch();
  }, [debouncedSearchValue, refetch]);

  useEffect(() => {
    refetch();
  }, [sortField, sortOrder, refetch]);

  const fetchMoreDocuments = (pageNumber: number, pageSize: number) => {
    fetchMore({
      variables: {
        pagination: {
          page: pageNumber,
          pageSize
        }
      }
    });
  };

  const documents = documentsData?.userDocuments?.documents;
  const hasDocuments = documents?.length > 0;
  const displayCount = documentsData?.userDocuments?.pageInfo?.count || 0;

  useEffect(() => {
    if (documentsError) {
      toast.error(
        'An error occurred while attempting to load the user documents'
      );
      navigate('/users');
    }
  }, [documentsError, navigate]);

  const handleSearchValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(1);
    setSearchValue(event.target.value);
  };

  const handlePageChange = (
    _: null | React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => {
    const pageNumber = newPage + 1;
    setPage(pageNumber);
    fetchMoreDocuments(pageNumber, rowsPerPage);
  };

  const handleRowsPerPageChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    const pageSize = parseInt(event.currentTarget.value);
    setRowsPerPage(pageSize);
    setPage(1);
    fetchMoreDocuments(1, pageSize);
  };

  const handleSortColumnChange = (
    columnIndex: number,
    newSortOrder: SortOrder
  ) => {
    setPage(1);
    setSortField(columnHeaderToSortField[columnHeaders[columnIndex]]);
    setSortOrder(newSortOrder);
  };

  const handleCategoryChange = categories => {
    setPage(1);
    setCategory(categories);
  };

  const setSelectedDocumentState = (document: null | UserDocument) => {
    setSelectedDocument(document);
  };

  const getRows = docs => {
    return docs.map(document => {
      const {
        category: docCategory,
        createdAt,
        documentType,
        filename,
        type
      } = document;
      const formattedSubType =
        documentType &&
        (DocumentTypeDbValue[documentType] || snakeToTitleCase(documentType));

      return {
        cells: [
          <div className={styles.documentLabel} key={document.id}>
            <FileIcon url={filename} />
            {filename}
          </div>,
          docCategory && snakeToTitleCase(docCategory),
          type && snakeToTitleCase(type),
          formattedSubType,
          {
            sortableValue: createdAt,
            value: moment.utc(new Date(createdAt)).format('M/D/YYYY')
          },
          <DocumentActions
            document={document as null | UserDocument}
            key={id}
            setSelectedDocument={setSelectedDocumentState}
          />
        ]
      };
    });
  };

  return (
    <>
      <DeleteDocumentModal
        selectedDocument={selectedDocument}
        setSelectedDocumentState={setSelectedDocumentState}
      />
      <Card
        className={ovStyles.cardContainer}
        data-testid="userDocuments"
        id="documents"
      >
        <div className={styles.top}>
          <div>
            <h6 data-testid="headingAccounts">Documents</h6>
            <span className="subText">Displaying: {displayCount}</span>
          </div>
          <div className={styles.filters}>
            <Input
              className={styles.input}
              compact
              id="accountsSearch"
              onChange={handleSearchValueChange}
              placeholder="Search..."
              trailingIcon="searchMd"
              value={searchValue}
            />
            <FilterSelect
              buttonText="Category"
              multi
              onChange={handleCategoryChange}
              options={categoryOptions}
              value={category}
            />
          </div>
        </div>
        <Table
          allowSorting={[0, 2, 3, 4]}
          columnHeaders={columnHeaders}
          data-testid="documentsTable"
          loading={documentsLoading}
          loadingRows={10}
          onSort={(columnIndex, newSortOrder) =>
            handleSortColumnChange(columnIndex, newSortOrder as SortOrder)
          }
          pagination={{
            count: documentsData?.userDocuments.pageInfo?.count || 0,
            onPageChange: handlePageChange,
            onRowsPerPageChange: handleRowsPerPageChange,
            page: hasDocuments ? page - 1 : 0,
            rowsPerPage
          }}
          rows={
            hasDocuments
              ? getRows(documents)
              : [{ cells: ['No documents found'] }]
          }
          sortRows={false}
        />
      </Card>
    </>
  );
};

export default Documents;
