import { Button, Input, TextArea } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';

import {
  CheckInfoInput,
  useEditOfferingBankAccountMutation,
  WireInfoInput
} from '../../../../__generated__';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import { setFieldProps } from '../../../../utils/formHelpers';
import { wireAndCheckInfoSchema } from '../validation';

interface FormValues extends CheckInfoInput, WireInfoInput {}

interface Props {
  checkInfo?: CheckInfoInput | null;
  id: string;
  wireInfo?: null | WireInfoInput;
}

const messages = {
  error: 'An error occurred while updating wire and check information',
  loading: 'Updating',
  success: 'Wire and check information updated'
};

const WireAndCheckForm: React.FC<Props> = ({ checkInfo, id, wireInfo }) => {
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit
  } = useForm<FormValues>({
    defaultValues: {
      account: wireInfo.account || '',
      accountName: wireInfo.accountName || '',
      address: checkInfo.address || '',
      bankAddress: wireInfo.bankAddress || '',
      bankName: wireInfo.bankName || '',
      payeeName: checkInfo.payeeName || '',
      wireRouting: wireInfo.wireRouting || ''
    },
    mode: 'onBlur',
    resolver: yupResolver(wireAndCheckInfoSchema)
  });
  const [editOfferingBankAccount, editOfferingBankAccountState] =
    useEditOfferingBankAccountMutation();
  const { loading } = editOfferingBankAccountState;

  const copyWireInfo = () => {
    const values = getValues();
    const wireText = `Account Name: ${values.accountName}
Account Number: ${values.account}
Routing Number: ${values.wireRouting}
Bank Name: ${values.bankName}
Branch: ${values.bankAddress}`;

    navigator.clipboard.writeText(wireText);

    toast.success('Wire info copied to clipboard');
  };

  const onSubmit = (values: FormValues) => {
    const {
      account,
      accountName,
      address,
      bankAddress,
      bankName,
      payeeName,
      wireRouting
    } = values;

    callMutationWithToastMessages(editOfferingBankAccount, messages, {
      variables: {
        offering: {
          checkInfo: {
            address,
            payeeName
          },
          id,
          wireInfo: {
            account: account?.toString(),
            accountName,
            bankAddress,
            bankName,
            wireRouting: wireRouting?.toString()
          }
        }
      }
    });
  };
  return (
    <form
      className="clearFix"
      data-testid="wireCheckForm"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h3>Wire Info</h3>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="bankName"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Bank Name" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="accountName"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Account Name" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="account"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Account Number" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="wireRouting"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Routing Number" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="bankAddress"
            render={({ field }) => (
              <TextArea
                {...setFieldProps(field, errors)}
                label="Bank Address"
              />
            )}
          />
        </Col>
      </Row>
      <button
        className="textLink underline"
        onClick={copyWireInfo}
        type="button"
      >
        <i className="fa fa-copy" /> Copy wire info
      </button>

      <h3>Check Info</h3>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="payeeName"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Payee Name" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="address"
            render={({ field }) => (
              <TextArea {...setFieldProps(field, errors)} label="Address" />
            )}
          />
        </Col>
      </Row>

      <Button className="floatRight" loading={loading} type="submit">
        Save Wire & Check Info
      </Button>
    </form>
  );
};

export default WireAndCheckForm;
