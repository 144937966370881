import {
  BankAccountType,
  BusinessType,
  InvestmentAccountType
} from '../../__generated__';
import dwollaBusinessClassifications from '../../constants/dwollaBusinessClassifications';
import states from '../../constants/states';
import { sortArrayofObjectsByProperty } from '../../utils/sorting';

export const investmentAccountTypeLabels: Record<
  InvestmentAccountType,
  string
> = {
  entity: 'Entity',
  individual: 'Individual',
  ira: 'IRA',
  joint_account: 'Joint Account',
  trust: 'Trust'
};

export const businessClassesAlphabetized = sortArrayofObjectsByProperty(
  dwollaBusinessClassifications._embedded['business-classifications'],
  'name'
);

export const businessTypeLabels: Record<BusinessType, string> = {
  corporation: 'Corporation',
  individual: 'Individual',
  joint_account: 'Joint Account',
  llc: 'LLC',
  other: 'Other',
  partnership: 'Partnership',
  roth: 'Roth',
  roth_ira: 'Roth IRA',
  sep: 'SEP',
  sep_ira: 'SEP IRA',
  simple: 'SIMPLE',
  single_member_llc: 'Single Member LLC',
  soleproprietorship: 'Sole Proprietorship',
  traditional: 'Traditional',
  traditional_ira: 'Traditional IRA',
  trust_irrevocable: 'Trust Irrevocable',
  trust_revocable: 'Trust Revocable'
};

interface BankAccountTypeOption {
  label: string;
  value: BankAccountType;
}

interface BusinessTypeOption {
  label: string;
  value: BusinessType;
}

export const entityBusinessTypeOptions: BusinessTypeOption[] = [
  {
    label: 'LLC',
    value: BusinessType.Llc
  },
  {
    label: 'Partnership',
    value: BusinessType.Partnership
  },
  {
    label: 'Sole Proprietorship',
    value: BusinessType.Soleproprietorship
  },
  {
    label: 'Corporation',
    value: BusinessType.Corporation
  },
  {
    label: 'Trust Irrevocable',
    value: BusinessType.TrustIrrevocable
  },
  {
    label: 'Trust Revocable',
    value: BusinessType.TrustRevocable
  }
];

export const businessClassOptions = businessClassesAlphabetized.map(
  businessClass => ({
    label: businessClass.name,
    value: businessClass.id
  })
);

export const bankAccountTypeOptions: BankAccountTypeOption[] = [
  {
    label: 'Checking',
    value: BankAccountType.Checking
  },
  {
    label: 'Savings',
    value: BankAccountType.Savings
  }
];

export const stateOptions = states.map(state => ({
  label: state.name,
  value: state.code
}));
